import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import auth from "../../../services/authService";
import ChooseUserCard from "../../../application/Card/ChooseUserCard/ChooseUserCard";
import BuyerPanel from "../../../application/MyProfile/BuyerPanel";
import SellerPanel from "../../../application/MyProfile/SellerPanel";
import getService from "../../../services/getService";
import { toast } from "react-toastify";
import Loader from "./../../../common/loader";

const UserDetails = () => {
  const params = useParams();
  const Id = params.id;
  const [buyerClass, setBuyerClass] = useState("");
  const [sellerClass, setSellerClass] = useState("");

  const [account, setAccount] = useState([]);
  const [userType, setUserType] = useState("");
  const [signupCard, setSignupCard] = useState(false);

  const validateNext = () => (userType === null ? true : false);

  const handleUserType = (userType) => {
    setAccount({ ...account, user_type: userType });
  };

  useEffect(() => {
    if (userType) handleUserType(userType);
  }, [userType]);

  const PopulateForm = async () => {
    const collection = await getService.handleUserList();
    const data = await getService.getData(collection, Id);
    const { id, ...updatedObject } = data;
    updatedObject.user_id = id;
    setAccount(updatedObject);
    setUserType(updatedObject.user_type);
    if (updatedObject.user_type === "Buyer") {
      setBuyerClass("active");
    } else if (updatedObject.user_type === "Seller") {
      setSellerClass("active");
    } else {
      console.log("null");
    }
  };

  useEffect(() => {
    PopulateForm();
  }, []);

  if (!account) return <Loader />;

  console.log("user account", account);

  return (
    <>
      <section className="d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3" style={{ height: "100vh" }}>
            <div className="app-login">
              <div className="app-form-logo">
                <div className="app-form-logo-content">
                  <h4>
                    <u>User Details</u>
                  </h4>
                </div>
              </div>

              <div class="steps-body">
                <ChooseUserCard
                  userType={userType}
                  validateNext={validateNext}
                  setUserType={setUserType}
                  signupCard={signupCard}
                  setSignupCard={setSignupCard}
                  buyerClass={buyerClass}
                  setBuyerClass={setBuyerClass}
                  sellerClass={sellerClass}
                  setSellerClass={setSellerClass}
                />
              </div>

              {signupCard && (
                <>
                  <h2 className="text-align-center">User Profile</h2>

                  {userType === "Buyer" && (
                    <BuyerPanel
                      account={account}
                      userType={userType}
                      setAccount={setAccount}
                    />
                  )}
                  {userType === "Seller" && (
                    <SellerPanel
                      account={account}
                      userType={userType}
                      setAccount={setAccount}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetails;
