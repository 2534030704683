import React, { useState, useEffect, useRef } from "react";
import postService from "../../../../services/postService";
import ChatInput from "./ChatInput";
import { BsArrowLeftCircle } from "react-icons/bs";
import { animateScroll } from "react-scroll";
const ChatPanel = ({
  currentChat,
  setCurrentChat,
  messages,
  handleSendMsg,
  chatRef,
  handleClick,
}) => {
  if (!messages) return null;

  return (
    <>
      {currentChat && (
        <div className="activitySection">
          <div className="heading flex" style={{ flexDirection: "row" }}>
            <h1>
              Order Id -{" "}
              {currentChat?.order_id ? currentChat?.order_id : currentChat?.id}
            </h1>
            <div className="back">
              <BsArrowLeftCircle
                size={25}
                onClick={() => setCurrentChat(undefined)}
              />
            </div>
          </div>
          <div class="chat">
            <div class="chat-box" id="chat-container" ref={chatRef}>
              {messages.map((message) => (
                <>
                  {message.fromSelf && (
                    <div class="chat-r">
                      <div class="sp"></div>
                      <div class="mess mess-r">
                        <p>{message.message}</p>
                      </div>
                    </div>
                  )}

                  {!message.fromSelf && (
                    <div class="chat-l">
                      <div class="mess">
                        <p>{message.message}</p>
                      </div>
                      <div class="sp"></div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <ChatInput handleSendMsg={handleSendMsg} />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPanel;
