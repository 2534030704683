import React from "react";
import BidRequirement from "../../../Card/Forms/BidRequirement";

const RequirementList = ({
  post_requirement_id,
  requirement,
  setRequirement,
  myAccount,
}) => {
  const formatter = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
  });

  function daysLeft(endDate) {
    const oneDay = 24 * 60 * 60 * 1000;
    const now = new Date();
    const end = new Date(endDate);
    const diffDays = Math.round((end - now) / oneDay);
    return diffDays;
  }

  return (
    <>
      <div className="RequirementList">
        <ul class="list-group">
          <li class="list-group-item">
            <div className="rqlView">
              <h1>{requirement.name}</h1>
              <p>{formatter.format(requirement.target_price)}</p>
            </div>
          </li>
          <li class="list-group-item" style={{ border: "#fff" }}>
            <div className="row">
              <div class="col-md-10 col-md-pull-2 mt-2">
                <p>{requirement.description}</p>
              </div>
            </div>
          </li>
          <li class="list-group-item" style={{ border: "#fff" }}>
            <div className="wrapper-skill-content">
              <ul style={{ border: "none" }}>
                <li>{requirement.cat_name}</li>
                <li>{requirement.subcat_name}</li>
                <li>{requirement.super_subcat_name}</li>
              </ul>
            </div>
          </li>
          <li class="list-group-item" style={{ border: "#fff" }}>
            <div className="row">
              <div class="col-md-10 col-md-pull-2 mt-2">
                <div
                  className="alert-box"
                  style={{ borderLeftColor: "#ffc24e" }}
                >
                  <h3>Beware of scams</h3>
                  <p>
                    If you are being asked to pay a security deposit, or if you
                    are being asked to chat on Telegram, WhatsApp, or another
                    messaging platform, it is likely a scam. Report these
                    projects or contact Support for assistance.
                  </p>
                  <button className="btn btn-alert">Discard</button>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item" style={{ border: "#fff" }}>
            <div className="rqlView">
              {daysLeft(requirement.bidding_date) <= 0 ? (
                <h1>
                  {" "}
                  Offer to work on this job now! The Bidding is already closed.{" "}
                </h1>
              ) : (
                <h1>
                  Offer to work on this job now! Bidding closes in{" "}
                  {daysLeft(requirement.bidding_date)} days
                </h1>
              )}
            </div>
            <div className="bidding">
              <BidRequirement
                post_requirement_id={post_requirement_id}
                requirement={requirement}
                setRequirement={setRequirement}
                myAccount={myAccount}
              />
            </div>
          </li>
          <li class="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <i class="fa fa-check text-success mx-2"></i>Set your budget and
                timeframe
              </div>
              <div className="col-md-6">
                <i class="fa fa-check text-success mx-2"></i>Get paid for your
                work
              </div>
              <div className="col-md-6">
                <i class="fa fa-check text-success mx-2"></i>Outline your
                proposal
              </div>
              <div className="col-md-6">
                <i class="fa fa-check text-success mx-2"></i>It's free to sign
                up and bid on jobs
              </div>
            </div>
          </li>
          <li class="list-group-item" style={{ border: "#fff" }}></li>
        </ul>
      </div>
    </>
  );
};

export default RequirementList;
