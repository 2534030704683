import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import { CSVLink, CSVDownload } from "react-csv";
import Tracker from "./../../../common/tracker";
import Payment from "../../../common/payment";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";
import NoCart from "../../../common/noCart";
import BuyerTable from "./BuyerTable";
import postService from "../../../services/postService";
import { toast } from "react-toastify";

const BuyerOrder = ({ myAccount }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    const data = list.order_list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.order_date);
      const date2 = new Date(b.order_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(filter => filter.status === "Active");
    setOrders(sortedDateArray);
    setLoading(true);
  };

  const fetchOrderList = async (myAccount) => {
    const list = await getService.handleSellerOrderList({
      user_id: myAccount.id,
      token: myAccount.token,
    });
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(myAccount);
  }, [myAccount]);

  const handlePayment = async (data) => {
    console.log("handle payment");
    try {
      await postService.PostPayment(data);
      setCurrentPage(1);
      fetchOrderList();
    } catch (error) {
      toast.error(error);
    }
  };

  const handleTracker = () => {
    console.log("handle Tracker");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    const order = paginate(orders, currentPage, pageSize);
    return { totalCount: orders.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  if (loading === false) return <Loader />;
  console.log("orders", order);

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (order) => <p>{orders.indexOf(order) + 1}</p>,
    },
    // {path: "customer", label: "Customer"},
    { path: "post_requirement_id", label: "Order Id" },
    { path: "order_date", label: "Date" },
    { path: "amount", label: "Amount" },

    {
      key: "Tracker",
      label: "Tracker",
      content: (order) => (
        <>
          {" "}
          <Tracker
            userType={myAccount.user_type}
            tracker={order.order_tracker}
            handleTracker={handleTracker}
          />
        </>
      ),
    },
    {
      key: "Payment",
      label: "Payment",
      content: (order) => (
        <>
          {" "}
          <Payment
            userType={myAccount.user_type}
            pay={order.payment_tracker}
            payment_status={order.payment_status}
            handlePayment={handlePayment}
          />
        </>
      ),
    },
    {
      path: "orderStatus",
      label: "Order Status",
      content: (order) => <p>{order.order_tracker_status}</p>,
    },
    {
      key: "Invoice",
      label: "Invoice",
      content: (order) => (
        <Link
          style={{ textAlign: "center" }}
          class="btn btn-primary btn-sm"
          to={`/buyerdashboard/Orders/Invoices/${order.post_requirement_id}`}
          state={{ data: order.invoice_list, order: order }}
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </Link>
      ),
    },
    // {
    //   key: "PO",
    //   label: "PO",
    //   content: (order) => (
    //     <>
    //       {order.invoice_list && (
    //         <Link
    //           style={{ textAlign: "center" }}
    //           class="btn btn-warning btn-sm"
    //           to={`/buyerdashboard/Orders/Invoice/${order.invoice_list[0].id}`}
    //           state={{ data: order.invoice_list[0], order: order }}
    //         >
    //           <i class="fa fa-first-order" aria-hidden="true"></i>
    //         </Link>
    //       )}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      {totalCount === 0 && <NoCart />}

      {totalCount !== 0 && (
        <div className="mainContent">
          <Top />
          <div>
            <CSVLink data={orders} className="btn btn-outline-success">
              Download Report
            </CSVLink>
          </div>
          <BuyerTable columns={columns} data={order} />

          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default BuyerOrder;
