import React, { useState } from "react";
import { paginate } from "../../../utils/paginate";
import Select from "./../../../common/select";
import { MyRequirementData } from "../../../data/MyRequirementData";
import NoCart from "./../../../common/noCart";
import Pagination from "../../../common/pagination";
import RequirementCard from "../../../application/Card/Requirement/RequirementCard";

const BidTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedGenre, setSelectedGenre] = useState("Active");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = props.bids;
    if (selectedGenre)
      filtered = props.bids.filter((m) => m.status === selectedGenre);

    const required = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: required };
  };

  const { totalCount, data: required } = getPagedData();

  console.log("req bid", required);

  return (
    <>
      <div className="row">
        <div className="col-md-12 p-4">
          <div className="bRequirementList">
            <ul class="list-group">
              {props.bids.length !== 0 && (
                <>
                  <li class="list-group-item">
                    <div className="bRequirementList">
                      <h3>Showing {totalCount} entries in the Database</h3>
                      <div className="select">
                        <Select
                          name="req_name"
                          value={selectedGenre}
                          options={MyRequirementData}
                          onChange={(e) =>
                            setSelectedGenre(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </li>
                </>
              )}

              <li class="list-group-item">
                <RequirementCard requirement={required} />
              </li>

              {totalCount === 0 && <NoCart />}

              {totalCount !== 0 && (
                <li class="list-group-item">
                  <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidTable;
