import React, { useState } from 'react';
import './style.css';
import './custom.min.css';
import './css/pe-icon-7-stroke.css';
import { Link, useNavigate } from 'react-router-dom';
import Input from './../common/input';
import Joi from 'joi-browser';
import auth from '../services/authService';
import axios from 'axios';
import { toast } from 'react-toastify';

const AdminLogin = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();
  
  const [account, setAccount] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const schema = {
    username : Joi.string().required().label("Username"),
    password : Joi.string().required().label("Password"),
  }

  const validate = () => {
   const options = { abortEarly: false };
   const { error } = Joi.validate(account, schema, options);
   if(!error) return null;

   const errors = {};
   for (let item of error.details) errors[item.path[0]] = item.message;
   return errors;
  };  

  const validateProperty = ({name, value}) => {
    const obj = { [name] : value};
    const schemas = { [name] : schema[name]};
    const {error} = Joi.validate(obj, schemas);
    return error ? error.details[0].message :  null;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      await auth.adminLogin(account);
    } catch (error) {
      toast.error(error)
    }
  };  

  const handleChange= ({ currentTarget : input}) => {

    const error = {...errors};
    const errorMessage = validateProperty(input);
    if(errorMessage) error[input.name]= errorMessage;
    else delete error[input.name];

    const accounts = {...account};
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
	};
  const { username, password } = account

  return (
    <>
   <section className="admin">
    <div class="container-center">
      <div class="panel panel-bd">
        <div class="panel-heading">
          <div class="view-header">
            <div class="header-icon">
              <i class="pe-7s-unlock"></i>
            </div>
            <div class="header-title">
              <h3></h3><br />
              <small><h3>Login</h3></small>
            </div>
          </div>
        <div class="row"></div></div>
        <div className="panel-body">
          <form onSubmit={handleSubmit}>
            <div class="form-group">
            <Input type="text" placeholder="Username" name="username" label="Username" error={errors.username} value={username} onChange={handleChange} />
            <span class="help-block small">Your unique name</span>
            </div>
            <div class="form-group">
            <Input type="password" placeholder="Password" name="password" label="Password" error={errors.password} value={password} onChange={handleChange} />
            <span style={{fontSize: "14px"}}>Your strong password <a href="#" data-toggle="modal" data-target="#passwordrecoverymodal"><b class="text-right">Forgot Password ??</b></a></span>
            </div>
            <div>
              <button class="btn btn-success" disabled={validate()}>Login</button>
            </div>
          </form>
         </div>
        </div>
       </div>
    </section>
    </>
  )
}

export default AdminLogin