import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

const PublicRoute = props => {
const { Component } = props;


  return (
    <Component {...props} />
  )
}

export default PublicRoute