import React, { useState, useEffect } from "react";
import Input from "../../../common/input";
import Top from "./Top Section/Top";
import { useParams, useNavigate } from "react-router-dom";
import { ViewRequirementsData } from "./../../../data/ViewRequirementsData";
import BidRequirement from "./../../Card/Forms/BidRequirement";
import RequirementList from "./components/RequirementList";
import getService from "../../../services/getService";
import { toast } from "react-toastify";
import Loader from "./../../../common/loader";

const ViewRequirementsForm = ({ myAccount }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [requirement, setRequirement] = useState([]);
  const post_requirement_id = params.id;
  const [loading, setLoading] = useState(false);

  const PopulateInfo = async () => {
    const list = await getService.handleRequirement({
      user_id: "",
      token: "",
    });
    const collection = list.post_requirement_list;
    const data = await getService.getData(collection, post_requirement_id);
    setRequirement(data);
    setLoading(true);
  };

  useEffect(() => {
    PopulateInfo();
  }, []);

  if (loading === false) return <Loader />;

  return (
    <>
      <div className="mainContent">
        <Top />
        <RequirementList
          post_requirement_id={post_requirement_id}
          requirement={requirement}
          setRequirement={setRequirement}
          myAccount={myAccount}
        />
      </div>
    </>
  );
};

export default ViewRequirementsForm;
