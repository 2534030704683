import Joi from "joi-browser";
export const ViewRequirementsSchema = {
  bid_id: Joi.optional().allow(""),
  post_requirement_id: Joi.optional().allow(""),
  user_id: Joi.optional().allow(""),
  token: Joi.optional().allow(""),
  seller_name: Joi.optional().allow(""),
  buyer_name: Joi.optional().allow(""),
  location: Joi.optional().allow(""),
  bid_price: Joi.string().required().label("Bid Price"),
  bid_specification: Joi.optional().allow(""),
  credit_facility: Joi.optional().allow(""),
  credit_no_of_days: Joi.optional().allow(""),
  delivery_timeline: Joi.optional().allow(""),
};
