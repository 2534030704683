import React, { useState } from 'react';

const UserCreatForm = () => {
  const [fields, setFields] = useState([
    {
      name: '',
      type: 'text',
    },
  ]);
  const [files, setFiles] = useState([]);

  const handleAddField = () => {
    setFields([...fields, { name: '', type: 'text' }]);
  };

  const handleRemoveField = (index) => {
    setFields(fields.filter((field, i) => i !== index));
  };

  const handleFieldChange = (index, event) => {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  console.log("fields", fields);

  return (
    <div>
      <h2>Form Builder</h2>
      <form>
        {fields.map((field, index) => (
          <div key={index}>
            <input
              type="text"
              name="name"
              value={field.name}
              onChange={(event) => handleFieldChange(index, event)}
            />
            <select
              name="type"
              value={field.type}
              onChange={(event) => handleFieldChange(index, event)}
            >
              <option value="text">Text</option>
              <option value="email">Email</option>
              <option value="password">Password</option>
              <option value="file">File</option>
            </select>
            {field.type === 'file' ? (
              <input type="file" onChange={handleFileChange} />
            ) : null}
            <button type="button" onClick={() => handleRemoveField(index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddField}>
          Add Field
        </button>
        <button type="submit">Submit</button>
      </form>
      <h3>Files:</h3>
      <ul>
        {Array.from(files).map((file, index) => (
          <li key={index}>{file.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default UserCreatForm;