import React, { useState } from "react";
import { RequirementData } from "./../../../data/requirementData";
import { useNavigate } from "react-router-dom";
import Requirement from "./../../Requirement/RequirementList";
import UpdateReq from "./../../../common/updateReq";
import AwardReq from "./../../../common/awardReq";

const RequirementCard = ({ onNavigate, requirement, myAccount, status }) => {
  const formatter = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
  });

  console.log("requirement", requirement);

  const userType = localStorage.getItem("userType");
  return (
    <>
      <div className="clientreqCard">
        <ul class="list-group">
          {requirement.map((item, index) => (
            <>
              <li class="list-group-item">
                <div className="row">
                  <div className="col-9">
                    <h1>{item.name}</h1>
                    <p>{item.description}</p>
                    <div className="categorylist">
                      <p>Location : {item.location}</p>
                      <ul>
                        <>
                          <li>{item.cat_name}</li>
                          <li>{item.subcat_name}</li>
                          <li>{item.super_subcat_name}</li>
                        </>
                      </ul>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="cardButtons">
                      <h6>
                        {item.bid_id ? "BP: " : "TP: "}
                        {formatter.format(item.price)}
                      </h6>
                      {item.quantity && <p>Quantity : {item.quantity}</p>}
                      {/* <p><i class="fa fa-calendar" aria-hidden="true"></i>{item.active_till_date} </p> */}
                      {
                        <>
                          {userType === "Buyer" &&
                            item.no_of_bid !== undefined && (
                              <p>No. of Bids {item.no_of_bid}</p>
                            )}
                          <div className="flex">
                            {userType === "Buyer" &&
                              item.no_of_bid !== undefined && (
                                <button
                                  onClick={() => onNavigate(item)}
                                  className="btn btn-success btn-sm"
                                >
                                  <i class="fa fa-eye" />
                                </button>
                              )}
                            {userType === "Buyer" &&
                              item.no_of_bid === undefined && (
                                <AwardReq
                                  myAccount={myAccount}
                                  userType={userType}
                                  item={item}
                                />
                              )}
                            {userType === "Buyer" &&
                              item.no_of_bid !== undefined && (
                                <UpdateReq
                                  myAccount={myAccount}
                                  userType={userType}
                                  item={item}
                                />
                              )}
                            {userType === "Seller" && (
                              <button
                                onClick={() => onNavigate(item)}
                                className="btn btn-success btn-sm"
                              >
                                <i class="fa fa-eye" />
                              </button>
                            )}
                            {userType === "Seller" && item.bid_id && (
                              <UpdateReq
                                myAccount={myAccount}
                                userType={userType}
                                item={item}
                              />
                            )}
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default RequirementCard;
