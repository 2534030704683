import React, { useRef } from 'react'
import Invoice from './../../../common/invoice';
import { useReactToPrint } from 'react-to-print';

const AdminInvoice = () => {
  const invoiceDocument = useRef();
  const handlePrint = useReactToPrint({
    content: () => invoiceDocument.current,
  })
  return (
    <>
        <div class="row">
            <div class="col-sm-12">
                <div class="panel panel-bd lobidrag">
                     <div class="panel-heading">
                          <div class="panel-title">
                             <h4>Invoice</h4>
                          </div>
                     </div>
                     <div class="panel-body">
                          <Invoice invoiceDocument={invoiceDocument}  />
                          <div style={{float: "right", marginRight: "10px"}}><button  className="btn btn-danger" onClick={handlePrint}>Print</button></div>
                     </div>
                </div>
            </div>
        </div>        
    </>
  )
}

export default AdminInvoice