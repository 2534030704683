import React from 'react';
import logo from '../../assets/navigation-menu/BlackLogo.png';
import darkWeb from '../../assets/images/banking.jpg';
import Input from './../../common/input';
import { Link, useParams } from 'react-router-dom';

const Payment = (props) => {
    props.funcNav(false);
    props.funcFooter(false);
    const params = useParams();

    const formatter = new Intl.NumberFormat('hi', {
        style: 'currency',
        currency: 'INR',
    });
    
  
  return (
    <section className="payment">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Link to="/">
                    <img class="logo" src={logo} alt="" />
                    </Link>
                </div>
                <div className="col-md-6">
                    <div className="message">
                    <img class="darkWeb" src={darkWeb} alt="" /> 
                    <h3>Onclq</h3>
                    <p>
                       Thanks you for choosing Onclq!  <br />
                       All we need is your information and we can finalize your payment.
                    </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <form>
                    <div className="details">
                    <h3>Banking Details</h3>
                    <Input name="bankName" label="Bank Name" disabled="true" value="RBL BANK LTD" />
                    <Input name="accountNumber" label="Account Number" disabled="true" value="409999103265" />
                    <Input name="IFSCCode" label="IFSC Code" disabled="true" value="RATN0000191" />
                    <Input name="branch" label="Brach" disabled="true" value="FARIDABAD" />
                    <button className="btn btn-submit">Pay {formatter.format(params.price)}</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Payment