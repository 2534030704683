import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({ columns, data, ...rest }) => {
  return (

     <div className="table-responsive">
      <table className="table table-striped" width="100%">

      <TableHeader columns={columns} {...rest} />
      <TableBody columns={columns} data={data} />
    </table>
    </div>
  );
};

export default Table;
