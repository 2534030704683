import Joi from "joi-browser";

export const SubAdminSchema = {
  id: Joi.optional().allow(""),
  username: Joi.string().required().label("Username"),
  password: Joi.string().required().label("Password"),
  full_name: Joi.optional().allow(""),
  email: Joi.optional().allow(""),
  mobile: Joi.optional().allow(""),
  address: Joi.optional().allow(""),
  img1: Joi.optional().allow(""),
  type: Joi.optional().allow(""),
  fb_url: Joi.optional().allow(""),
  twitter_url: Joi.optional().allow(""),
  instagram_url: Joi.optional().allow(""),
  google_url: Joi.optional().allow(""),
  youtube_url: Joi.optional().allow(""),
  linkedin_url: Joi.optional().allow(""),
  status: Joi.optional().allow(""),
  created_date: Joi.optional().allow(""),
  last_login_date: Joi.optional().allow(""),
  req_module: Joi.array().optional(""),
};
