import React, { useState, useEffect } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ChooseUserCard from "./../Card/ChooseUserCard/ChooseUserCard";
import auth from "../../services/authService";
import SellerPanel from "./SellerPanel";
import BuyerPanel from "./BuyerPanel";

const MyProfile = (props) => {
  props.funcNav(false);
  props.funcFooter(false);
  const [buyerClass, setBuyerClass] = useState("");
  const [sellerClass, setSellerClass] = useState("");

  let navigate = useNavigate();

  const [account, setAccount] = useState([]);
  const [userType, setUserType] = useState("");
  const [signupCard, setSignupCard] = useState(false);

  const validateNext = () => (userType === "" ? true : false);

  const handleUserType = (userType) => {
    setAccount({ ...account, user_type: userType });
  };

  useEffect(() => {
    if (userType) handleUserType(userType);
  }, [userType]);

  useEffect(() => {
    if (auth.getCurrentUser()) {
      let data = {
        ...auth.getCurrentUser(),
        user_id: auth.getCurrentUser().id,
      };
      setAccount(data);
      auth.getCurrentUser().user_type === null
        ? setSignupCard(false)
        : setSignupCard(true);
      setUserType(auth.getCurrentUser().user_type);
    }
  }, []);

  if (
    (auth.getCredUser().credEmailKey && auth.getCredUser().credStepKey) === null
  )
    window.location = "/signup";

  return (
    <>
      <section className="new_login_panel d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="login_right_sec" style={{ height: "auto" }}>
                  <div className="app-form-logo">
                    <Link to="/">
                      <img
                        alt="Onclq.com"
                        class="LogoImg"
                        src={appLogo}
                        data-size="mid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-login myProfileCard">
              <div class="steps-body">
                <ChooseUserCard
                  userType={userType}
                  validateNext={validateNext}
                  setUserType={setUserType}
                  signupCard={signupCard}
                  setSignupCard={setSignupCard}
                  buyerClass={buyerClass}
                  setBuyerClass={setBuyerClass}
                  sellerClass={sellerClass}
                  setSellerClass={setSellerClass}
                />
              </div>

              {signupCard && (
                <>
                  <h2>My Profile</h2>

                  {userType === "Buyer" && (
                    <BuyerPanel
                      account={account}
                      userType={userType}
                      setAccount={setAccount}
                    />
                  )}
                  {userType === "Seller" && (
                    <SellerPanel
                      account={account}
                      userType={userType}
                      setAccount={setAccount}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfile;
