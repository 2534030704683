export const NavigationMenu = [
  { id: 0, title: "Dashboard", to: "/admin", icon: "fa fa-dashboard" },
  {
    id: 4,
    title: "Manage Control",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Sub Admin", to: "allsubadmins" },
      { id: 1, title: "All Modules", to: "allmodules" },
    ],
  },
  {
    id: 4,
    title: "Manage Audience",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Contacts", to: "contactstable" },
      { id: 1, title: "All Leads", to: "leadstable" },
    ],
  },
  {
    id: 1,
    title: "Manage Users",
    to: "#",
    icon: "metismenu-icon pe-7s-user",
    options: [
      { id: 0, title: "All Users", to: "usertable" },
      { id: 0, title: "Seller List", to: "sellertable" },
      { id: 0, title: "Buyer List", to: "buyertable" },
      { id: 2, title: "Add User", to: "userform/new" },
    ],
  },
  {
    id: 5,
    title: "Manage Business",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Business", to: "businesstable" },
      { id: 1, title: "Add Business", to: "businessform/new" },
    ],
  },
  {
    id: 5,
    title: "Manage Categories",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Categories", to: "categoriestable" },
      { id: 1, title: "Add Category", to: "categoriesform/new" },
      { id: 0, title: "All Sub Category", to: "subcategoriestable" },
      { id: 1, title: "Add Sub Category", to: "subcategoriesform/new" },
      { id: 0, title: "All Sup Sub Category", to: "supsubcategoriestable" },
      { id: 1, title: "Add Sup Sub Category", to: "supsubcategoriesform/new" },
    ],
  },
  {
    id: 7,
    title: "Manage Clients",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Clients", to: "clienttable" },
      { id: 1, title: "Add Client", to: "clientform/new" },
    ],
  },
  {
    id: 6,
    title: "Manage Heading",
    to: "#",
    icon: "metismenu-icon fa fa-cubes",
    options: [
      { id: 0, title: "All Haeding", to: "headingtable" },
      { id: 1, title: "Add Heading", to: "headingform/new" },
    ],
  },
  {
    id: 6,
    title: "Manage Helpdesk",
    to: "#",
    icon: "metismenu-icon fa fa-cubes",
    options: [{ id: 0, title: "All Queries", to: "allqueries" }],
  },
  {
    id: 6,
    title: "Manage Home Banner",
    to: "#",
    icon: "metismenu-icon fa fa-cubes",
    options: [
      { id: 0, title: "All Banner", to: "bannertable" },
      { id: 1, title: "Add Banner", to: "bannerform/new" },
    ],
  },
  {
    id: 6,
    title: "Manage Hot Products",
    to: "#",
    icon: "metismenu-icon fa fa-cubes",
    options: [
      { id: 0, title: "All Products", to: "hotproductstable" },
      { id: 1, title: "Add Product", to: "hotproductsform/new" },
    ],
  },
  // {
  //   id: 1,
  //   title: "Manage Bids",
  //   to: "#",
  //   icon: "metismenu-icon pe-7s-user",
  //   options: [
  //     { id: 0, title: "All Bids", to: "bidtable" },
  //   ],
  // },
  {
    id: 2,
    title: "Manage Requirements",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Requirements", to: "requirementstable" },
      { id: 1, title: "Add Requirements", to: "requirementsform/new" },
    ],
  },
  {
    id: 3,
    title: "Manage Subscriptions",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Subscription", to: "subscriptiontable" },
      { id: 1, title: "Add Subscription", to: "subscriptionform/new" },
    ],
  },
  {
    id: 4,
    title: "Manage Services",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Services", to: "servicetable" },
      { id: 1, title: "Add Service", to: "serviceform/new" },
    ],
  },
  {
    id: 4,
    title: "Manage Support",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Support", to: "supporttable" },
      { id: 1, title: "Add Support", to: "supportform/new" },
    ],
  },
  {
    id: 8,
    title: "Manage Testimonials",
    to: "#",
    icon: "metismenu-icon fa fa-user-secret",
    options: [
      { id: 0, title: "All Testimonials", to: "testimonialtable" },
      { id: 1, title: "Add Testimonial", to: "testimonialform/new" },
    ],
  },
  { id: 9, title: "Add Ons", to: "#", icon: "fa fa-adn" },
];
