import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import appLogo from "../assets/navigation-menu/BlackLogo.png";
import { scroller } from "react-scroll";
import { MdAccountCircle, MdManageAccounts } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { HiHome } from "react-icons/hi";
import { BsInfoCircleFill } from "react-icons/bs";
import { RiServiceFill } from "react-icons/ri";
import { MdContacts } from "react-icons/md";

const Navbar = ({ userType, user, data }) => {
  const scrollToElement = (item) => {
    scroller.scrollTo(item, {
      duration: 100,
      delay: 0,
      smooth: "smooth",
    });
  };

  const [hover, setHover] = useState(null);

  const [dropdown, setDropdown] = useState(false);

  const navigate = useNavigate();

  const handleDashboard = () => {
    if (user && userType) {
      if (userType === "Buyer") window.location = "/buyerdashboard";
      if (userType === "Seller") window.location = "/sellerdashboard";
      if (userType === "Admin") window.location = "/admin";
    }
  };

  const handleSignout = () => {
    navigate("/signout");
  };

  if (!data) return null;

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light light-t">
        <div className="container-fluid ps-4">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                {user ? <h1>{user.name}</h1> : <h1>Welcome</h1>}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <Link to="/membership" className="nav-link">
                  <span>
                    <BiCategory size={20} className="icon" />
                  </span>
                  Membership
                </Link> */}
                {!user ? (
                  <Link to="/signin" className="nav-link">
                    Login
                  </Link>
                ) : (
                  <>
                    {userType !== "Admin" && (
                      <Link to="/myProfile" className="nav-link">
                        <span>
                          <MdManageAccounts size={20} className="icon" />
                        </span>
                        My Profile
                      </Link>
                    )}
                    {user.profile_complete === "Yes" && (
                      <Link className="nav-link" onClick={handleDashboard}>
                        <span>
                          <RiDashboardFill size={20} className="icon" />
                        </span>
                        Dashboard
                      </Link>
                    )}
                    {userType === "Admin" && (
                      <Link className="mav-link" onClick={handleDashboard}>
                        <span>
                          <RiDashboardFill size={20} className="icon" />
                        </span>
                        Dashboard
                      </Link>
                    )}
                    <Link to="/signout" className="nav-link">
                      <span>
                        <RiLogoutBoxRFill size={20} className="icon" />
                      </span>
                      Sign out
                    </Link>
                  </>
                )}
              </ul>
            </div>
          </div>
          <Link className="navbar-brand" to="/">
            <img
              onClick={() => scrollToElement("my-home")}
              src={data.img1}
              alt="app-Logo"
              style={{ width: "100%", height: "40px" }}
            />
          </Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => scrollToElement("my-home")}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => scrollToElement("my-about")}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => scrollToElement("my-service")}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => scrollToElement("my-categories")}
                >
                  Categories
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => scrollToElement("my-contact-us")}
                >
                  Contact Us
                </Link>
              </li>
              {/* <li className="t-1">
                <span>24</span>
                <Link to="#"> <i className="fa fa-bell"></i></Link>
          </li> */}
            </ul>
            <form className="d-flex">
              {!user ? (
                <Link to="/signin" className="btun">
                  Login
                </Link>
              ) : (
                <>
                  <div className="NavDropdown">
                    <div className="adminDiv flex">
                      <div className={`dropdown ${dropdown ? "show" : ""}`}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">My Account</Tooltip>
                          }
                        >
                          <button
                            className="starRating"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => setDropdown(!dropdown)}
                          >
                            <MdAccountCircle
                              size={25}
                              className="icon"
                              onMouseOver={() => setHover(12)}
                              onMouseOut={() => setHover(null)}
                            />
                          </button>
                        </OverlayTrigger>

                        <ul
                          style={{
                            display: dropdown ? "flex" : "none",
                          }}
                          aria-labelledby="dropdownMenuButton"
                          className={`list-group dropdown-menu ${
                            dropdown ? "show" : ""
                          }`}
                        >
                          <li className="list-group-item">
                            <h3>{user.name || user.username}</h3>
                          </li>
                          {userType !== "Admin" && (
                            <Link
                              to="/myProfile"
                              className="list-group-item list-group-item-action"
                            >
                              <span>
                                <MdManageAccounts size={20} className="icon" />
                              </span>
                              My Profile
                            </Link>
                          )}
                          {user.profile_complete === "Yes" && (
                            <Link
                              className="list-group-item"
                              onClick={handleDashboard}
                            >
                              <span>
                                <RiDashboardFill size={20} className="icon" />
                              </span>
                              Dashboard
                            </Link>
                          )}
                          {userType === "Admin" && (
                            <Link
                              className="list-group-item"
                              onClick={handleDashboard}
                            >
                              <span>
                                <RiDashboardFill size={20} className="icon" />
                              </span>
                              Dashboard
                            </Link>
                          )}
                          <Link
                            to="/signout"
                            className="list-group-item list-group-item-action"
                          >
                            <span>
                              <RiLogoutBoxRFill size={20} className="icon" />
                            </span>
                            Sign out
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </nav>

      <nav className="navbar fixed-bottom navbar-bot">
        <div className="container-fluid">
          <div className="bottom-link">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <Link
                className="nav-link"
                to="/"
                onClick={() => scrollToElement("my-home")}
              >
                <span>
                  <HiHome size={20} className="icon" />
                </span>
                Home
              </Link>
              <Link
                className="nav-link"
                to="/"
                onClick={() => scrollToElement("my-about")}
              >
                <span>
                  <BsInfoCircleFill size={20} className="icon" />
                </span>
                About
              </Link>
              <Link
                className="nav-link"
                to="/"
                onClick={() => scrollToElement("my-service")}
              >
                <span>
                  <RiServiceFill size={20} className="icon" />
                </span>
                Services
              </Link>
              <Link
                className="nav-link"
                to="/"
                onClick={() => scrollToElement("my-categories")}
              >
                <span>
                  <BiCategory size={20} className="icon" />
                </span>
                Categories
              </Link>
              <Link
                className="nav-link"
                to="/"
                onClick={() => scrollToElement("my-contact-us")}
              >
                <span>
                  <MdContacts size={20} className="icon" />
                </span>
                Contact
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
