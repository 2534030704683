import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../../utils/paginate";
import BuyerTable from "./../BuyerTable";
import { getBuyingData } from "./../../../../data/SampleBuyingData";

const TopBuyingSKUData = () => {
  const [orders, setOrders] = useState(getBuyingData());

  const navigate = useNavigate();

  const getPagedData = () => {
    const sortedDateArray = orders.sort((a, b) => {
      const date1 = new Date(a.date);
      const date2 = new Date(b.date);
      return date2 - date1;
    });
    const order = paginate(sortedDateArray, 1, 5);
    return { totalCount: sortedDateArray.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  if (totalCount === 0) return <p>There are no orders in the database.</p>;

  const columns = [
    { path: "buyingCode", label: "Buying Code" },
    { path: "skuCode", label: "SKU Code" },
    { path: "quantity", label: "Quantity" },
    { path: "Status", label: "Status" },
  ];

  return (
    <>
      <BuyerTable columns={columns} data={order} />
    </>
  );
};

export default TopBuyingSKUData;
