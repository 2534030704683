import React, { useState } from "react";

const ChatInput = ({ handleSendMsg }) => {
  const [msg, setMsg] = useState("");
  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  return (
    <>
      <form onSubmit={(event) => sendChat(event)}>
        <div className="chat-footer">
          <input
            placeholder="Type a message"
            name="Message"
            class="form-control"
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
          />
          <button className="btn btn-send">
            {" "}
            <i className="fa fa-chevron-circle-right "></i>{" "}
          </button>
        </div>
      </form>
    </>
  );
};

export default ChatInput;
