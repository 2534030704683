import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const TextArea = ({ setTextArea, textInput }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (textInput) {
      const blocksFromHtml = htmlToDraft(textInput);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
      setTextArea(textInput);
    }
  }, [textInput]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setTextArea(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <div>
        <div className="editor">
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "embedded",
                "emoji",
                "image",
                "remove",
                "history",
              ],
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TextArea;
