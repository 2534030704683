import React, { useState } from "react";
import Input from "./input";
import Checkbox from "./checkbox";
import postService from "../services/postService";
import { toast } from "react-toastify";
import { ViewRequirementsData } from "../data/ViewRequirementsData";

const UpdateReq = ({ userType, myAccount, item }) => {
  const [open, setOpen] = useState(false);

  let FaClasses = "fa ";
  if (userType === "Buyer") {
    FaClasses += "fa-times-circle-o";
  } else {
    FaClasses += "fa-edit";
  }

  let ButtonClasses = "btn-sm btn ";
  if (userType === "Buyer") {
    ButtonClasses += "btn-danger";
  } else {
    ButtonClasses += "btn-primary";
  }

  const BuyerClose = async () => {
    try {
      const res = await postService.PostRequirementClose({
        user_id: myAccount.id,
        token: myAccount.token,
        post_requirement_id: item.post_requirement_id,
        status: "Close",
      });
      if (res === "Your posted requirements have been closed") {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error);
    }
    setOpen(!open);
  };

  const BuyerModel = () => {
    return (
      <>
        <div class="modal-body">
          <h5>Are you sure to close this requirement ??</h5>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => setOpen(!open)}
          >
            Close
          </button>
          <button type="button" class="btn btn-danger" onClick={BuyerClose}>
            Yes, close
          </button>
        </div>
      </>
    );
  };

  const SellerModel = () => {
    const [bid, setBid] = useState({
      updatedPrice: "",
    });

    const handleChange = ({ currentTarget: input }) => {
      const bids = { ...bid };
      bids[input.name] = input.value;
      setBid(bids);
    };

    const SellerClose = async (e) => {
      e.preventDefault();
      try {
        await postService.PostBid({
          ...ViewRequirementsData,
          bid_id: item.id,
          bid_price:
            item.price === updatedPrice || updatedPrice === ""
              ? item.price
              : updatedPrice,
          bid_specification: item.description,
          buyer_name: item.name,
          seller_name: item.seller_name,
          user_id: myAccount.id,
          post_requirement_id: item.post_requirement_id,
          token: myAccount.token,
          location: myAccount.address,
          delivery_timeline: item.delivery_timeline,
        });
        setOpen(!open);
      } catch (error) {
        toast.error(error);
      }
    };

    const { updatedPrice } = bid;

    return (
      <>
        <form
          onSubmit={SellerClose}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div class="modal-body">
            <h5>Update Bid Price</h5>
            <Input
              name="updatedPrice"
              value={updatedPrice}
              label="Updated Bid Price"
              placeholder="Updated Bid Price"
              onChange={handleChange}
              type="number"
            />
            <Input
              name="price"
              value={item.price}
              label="Current Bid Price"
              placeholder="Current Bid Price"
              disabled="true"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => setOpen(!open)}
            >
              Close
            </button>
            <button type="submit" class="btn btn-success">
              Yes, update
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <button
        type="button"
        class={ButtonClasses}
        onClick={() => setOpen(!open)}
      >
        {" "}
        <i className={FaClasses} />
      </button>
      <div
        class={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Status
              </h1>
              <button
                type="button"
                class="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            {userType === "Buyer" ? <BuyerModel /> : <SellerModel />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateReq;
