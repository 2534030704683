import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "./../../../common/input";
import getService from "../../../services/getService";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import TextArea from "./../../../common/TextArea";
import upload from "../../../../components/assets/images/upload.jpg";
import { MdCancel } from "react-icons/md";

const CMSForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [Img, setImg] = useState("");
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [account, setAccount] = useState({
    id: "",
    title: "",
    description: "",
    img1: "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [textArea, setTextArea] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postService.PostCMS({ ...account, description: textArea });
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateForm = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleCMSList();
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
      setImageUrl(data.img1);
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ ...rest }) => ({ ...rest });

  useEffect(() => {
    PopulateForm();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const setImgPic = () => {
    setImageUrl(fileUrl);
    setAccount({ ...account, img1: url });
    setUrl("");
  };

  console.log("Img", Img);

  const ImageUpload = () => {
    Img === "img1" ? setImgPic() : console.log("account");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    setFileUrl(null);
    setAccount({ ...account, img1: null });
    setImageUrl(null);
  };

  const { title } = account;

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Edit CMS </h4>
              </div>
            </div>
            <div class="panel-body">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div className="col-md-6">
                    <div className="col-sm-12 mt-2">
                      <Input
                        name="title"
                        label="Title"
                        value={title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-sm-12">
                      <label for="exampleFormControlInput1" class="form-label">
                        Upload Image
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        name="img1"
                        label="Img1"
                        accept="image/png, image/jpeg, image/gif"
                        onChange={onImageChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-sm-12 mt-3 d-flex flex-column">
                      <label for="exampleFormControlInput1" class="form-label">
                        Image
                      </label>

                      {imageUrl && (
                        <div className="img-admin-upload">
                          <MdCancel
                            onClick={removeImg}
                            color="red"
                            size={25}
                            className="icon"
                          />
                          <img
                            src={imageUrl}
                            style={{ width: "100px", height: "100px" }}
                            alt="Preview"
                          />
                        </div>
                      )}
                      {!imageUrl && (
                        <img
                          src={upload}
                          style={{ width: "100px", height: "100px" }}
                          alt="Preview"
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div class="col-md-12 mt-2">
                      <label for="description" class="form-label">
                        Description.
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <TextArea
                      setTextArea={setTextArea}
                      textInput={account.description}
                    />
                    <p className="td-words-linit">4000 character left.</p>
                  </div>

                  <div class="col-md-1 offset-md-11">
                    <button type="submit" className="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CMSForm;
