import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import Recent from "./Recent";
import getService from "../../../../services/getService";
import { ViewRequirementsData } from "./../../../../data/ViewRequirementsData";
import Loader from "../../../../common/loader";
import noCart from "../../../../assets/images/empty_cart.gif";
import { paginate } from "../../../../utils/paginate";
import Pagination from "../../../../common/pagination";
import { MyBidsData } from "../../../../data/MyBidsData";
import Select from "../../../../common/select";
import { MyRequirementData } from "./../../../../data/MyRequirementData";
import NoCart from "../../../../common/noCart";

const BidList = ({ myAccount, post_requirement_id, status }) => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedGenre, setSelectedGenre] = useState("Active");

  const ZeroBids = () => {
    setBids([]);
    setLoading(true);
  };

  const AllBids = (list) => {
    console.log(
      "list.post_requirement_bid_list",
      list.post_requirement_bid_list
    );
    const data = list.post_requirement_bid_list.map(
      ({
        seller_name: name,
        bid_specification: description,
        bid_price: price,
        ...rest
      }) => ({ name, description, price, ...rest })
    );
    const filteredArray = data.sort((a, b) => {
      const date1 = a.price;
      const date2 = b.price;
      return date1 - date2;
    });
    setBids(filteredArray);
    setLoading(true);
  };

  const fetchBids = async (myAccount, post_requirement_id) => {
    const list = await getService.handleBids({
      user_id: myAccount.id,
      token: myAccount.token,
      post_requirement_id: post_requirement_id,
    });
    console.log("list", list);

    list.msg === "No record found" ? ZeroBids() : AllBids(list);
  };

  useEffect(() => {
    if (myAccount) fetchBids(myAccount, post_requirement_id);
  }, [myAccount, post_requirement_id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = bids;
    if (selectedGenre)
      filtered = bids.filter((m) => m.status === selectedGenre);

    const required = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: required };
  };

  const { totalCount, data: required } = getPagedData();
  if (loading === false) return <Loader />;

  return (
    <>
      {/* {totalCount === 0 && <NoCart />}

      {totalCount !== 0 && ( */}
      <div className="row">
        <div className="col-md-12 p-4">
          <div className="bRequirementList">
            <ul class="list-group">
              {bids.length !== 0 && (
                <>
                  <li class="list-group-item">
                    <div className="bRequirementList">
                      <h3>Showing {totalCount} entries in the Database</h3>
                      <div className="select">
                        <Select
                          name="req_name"
                          value={selectedGenre}
                          options={MyRequirementData}
                          onChange={(e) =>
                            setSelectedGenre(e.currentTarget.value)
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item" style={{ padding: 0 }}>
                    <Recent
                      status={status}
                      myAccount={myAccount}
                      requirement={required}
                    />
                  </li>
                </>
              )}

              {totalCount === 0 && (
                <li class="list-group-item">
                  <NoCart />
                </li>
              )}

              {totalCount !== 0 && (
                <li class="list-group-item">
                  <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default BidList;
