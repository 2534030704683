import React from 'react'

const Checkbox = ({name, label, error, ...rest }) => {

    return (
    <>
    <div className="form-check form-check-inline">
        <input 
        className="form-check-input" 
        {...rest}
        id={name}
        name={name} 
        />
        <label className="form-check-label" htmlFor={name}>{label}</label>
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
    </>
  )
}

export default Checkbox