import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import { CSVLink } from "react-csv";

const Social = () => {
  const [services, setService] = useState([]);

  const fetchService = async () => {
    const data = await getService.handleSocialList();
    setService(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    const service = paginate(services, currentPage, pageSize);
    return { totalCount: services.length, data: service };
  };

  const { length: count } = services;

  const { totalCount, data: service } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (service) => <p>{services.indexOf(service) + 1}</p>,
    },
    { path: "title", label: "Title" },
    { path: "url", label: "URL" },
    {
      key: "Action",
      label: "Action",
      content: (service) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/socialform/${service.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchService();
  }, []);

  if (count === 0) return <p>There are no item in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Social Links </h4>
              </div>
              <CSVLink data={service} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={service} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Social;
