import React, { useState } from "react";
import appLogo from "../assets/navigation-menu/WhiteLogo.png";
import profile from "../assets/profile/profile.jpg";
import "./style.css";
import "./custom.min.css";
import "./css/pe-icon-7-stroke.css";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { NavigationMenu } from "./../data/navigationMenu";

const Admin = (props) => {
  const [collapseClass, setCollapseClass] = useState(
    "admin sidebar-mini pace-done"
  );
  const [navigationMenuIndex, setNavigationMenuIndex] = useState(0);
  const [menuDisplay, setMenuDisplay] = useState("none");

  props.funcNav(false);
  props.funcFooter(false);

  const handleCollapse = () => {
    window.innerWidth >= 768
      ? collapseClass === "admin sidebar-mini pace-done"
        ? setCollapseClass("admin sidebar-mini pace-done sidebar-collapse")
        : setCollapseClass("admin sidebar-mini pace-done")
      : collapseClass === "admin sidebar-mini pace-done"
      ? setCollapseClass("admin sidebar-mini pace-done sidebar-open")
      : setCollapseClass("admin sidebar-mini pace-done");
  };

  const handleMenuItemClick = (idx) => {
    setNavigationMenuIndex(idx);
  };

  const handleMenuDisplayClick = () => {
    menuDisplay === "none" ? setMenuDisplay("block") : setMenuDisplay("none");
  };
  const location = useLocation();
  const params = useParams();

  return (
    <>
      <section className={collapseClass}>
        <div class="wrapper">
          <header class="main-header">
            <Link to="/" class="logo">
              <span class="logo-lg">
                <img src={appLogo} alt="" />
              </span>
              <span class="logo-mini">
                <img src={appLogo} alt="" />
              </span>
            </Link>
            <div class="se-pre-con"></div>
            <nav class="navbar navbar-static-top">
              <Link
                to="#"
                class="sidebar-toggle"
                data-toggle="offcanvas"
                role="button"
                onClick={handleCollapse}
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="pe-7s-keypad"></span>
              </Link>
              <span class="top-fixed-link">
                <Link to="/admin/content" class="btn btn-success btn-outline">
                  <i class="fa fa-sticky-note"></i> Content
                </Link>
                <Link to="/admin/social" class="btn btn-success btn-outline">
                  <i class="fa fa-connectdevelop"></i> Social List
                </Link>
                <Link to="/admin/contact" class="btn btn-success btn-outline">
                  <i class="fa fa-address-card " aria-hidden="true"></i> Contact
                </Link>
                <Link to="#" class="btn btn-success btn-outline">
                  <i class="ti-shopping-cart"></i> Purchase
                </Link>
              </span>

              <div class="navbar-custom-menu">
                <ul class="nav navbar-nav">
                  <li>
                    <Link
                      to="/admin/cmstable"
                      class="text-white  btn-success pos-btn"
                    >
                      {" "}
                      Manange CMS
                    </Link>
                  </li>
                  <li class="dropdown notifications-menu">
                    <Link to="#">
                      <i class="pe-7s-attention" title="Out Of Stock"></i>
                      <span class="label label-danger">11</span>
                    </Link>
                  </li>

                  <li class="dropdown dropdown-user">
                    <Link
                      to="#"
                      onClick={handleMenuDisplayClick}
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded={true}
                    >
                      {" "}
                      <i class="pe-7s-settings"></i>
                    </Link>
                    <ul class="dropdown-menu" style={{ display: menuDisplay }}>
                      <li>
                        <Link to="/signout">
                          <i class="pe-7s-key"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </header>

          <aside class="main-sidebar">
            <div class="sidebar">
              <div class="user-panel text-center">
                <div class="image">
                  <img src={profile} class="img-circle" alt="User-Img" />
                </div>
                <div class="info">
                  <p>Admin User</p>
                  <Link to="#">
                    <i class="fa fa-circle text-success"></i>Admin
                  </Link>
                </div>
              </div>
              <ul class="sidebar-menu">
                {NavigationMenu.map((item, idx) => (
                  <>
                    {/* <li class="treeview" key ={item.id}> */}

                    <li
                      className={
                        navigationMenuIndex === idx
                          ? `treeview active`
                          : `treeview`
                      }
                      key={item.id}
                    >
                      <Link
                        to={item.to}
                        onClick={() => handleMenuItemClick(idx)}
                      >
                        <i class={item.icon}></i>
                        <span>{item.title}</span>
                        <span class="pull-right-container">
                          {item.options === undefined ? null : (
                            <i class="fa fa-angle-left pull-right"></i>
                          )}
                        </span>
                      </Link>

                      {item.options === undefined ? null : (
                        <ul class="treeview-menu">
                          {item.options.map((option) => (
                            <>
                              <li class="treeview">
                                <Link to={option.to}>{option.title}</Link>
                              </li>
                            </>
                          ))}
                        </ul>
                      )}
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </aside>

          <div
            class="content-wrapper"
            style={{ minHeight: window.innerHeight }}
          >
            <div class="content">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Admin;
