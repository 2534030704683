import React, { useState, useEffect } from "react";

// Imported Icons ===========>
import { BiSearchAlt } from "react-icons/bi";
import { TbSettings } from "react-icons/tb";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";

// Imported Image =========>
import img from "../../../../assets/images/user (3).png";
import img2 from "../../../../assets/images/Untitled design.png";
import video from "../../../../assets/images/video-2.mp4";
import { useLocation, useNavigate, Link } from "react-router-dom";
import auth from "../../../../services/authService";
import BuyerDashboardContent from "./../../../../common/BuyerDashboardContent";
import getService from "../../../../services/getService";
import Loader from "../../../../common/loader";

const Top = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [myAccount, setMyAccount] = useState([]);
  const [services, setService] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    const data = list.order_list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.order_date);
      const date2 = new Date(b.order_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(filter => filter.status === "Active");
    setOrders(sortedDateArray);
    setLoading(true);
  };

  const fetchOrderList = async (myAccount) => {
    const list = await getService.handleSellerOrderList({
      user_id: myAccount.id,
      token: myAccount.token,
    });
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(myAccount);
  }, [myAccount]);

  const fetchService = async () => {
    const data = await getService.handleContentList();
    setService(data);
  };

  useEffect(() => {
    fetchService();
  }, []);

  useEffect(() => {
    const user = auth.getCurrentUser();
    setMyAccount(user);
  }, []);

  const [dropdown, setDropdown] = useState(false);
  if (loading === false) return <Loader />;

  return (
    <div className="topSection">
      <div className="headerSection flex">
        <div className="title">
          <h1>Welcome to Onclq.</h1>
          <p>Hello {myAccount.name} !</p>
        </div>

        {/* <div className="searchBar flex">
           <input type="text"  placeholder='Search Dashboard'/>
           <BiSearchAlt className="icon"/>
        </div> */}

        <div className="adminDiv flex">
          <MdOutlineNotificationsNone className="icon" />
          <div class={`dropdown ${dropdown ? "show" : ""}`}>
            <div
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => setDropdown(!dropdown)}
            >
              <TbSettings className="icon" />
            </div>
            <div
              class={`dropdown-menu ${dropdown ? "show" : ""}`}
              aria-labelledby="dropdownMenuButton"
            >
              <ul className="list-group">
                <Link className="list-group-item" to="/myProfile">
                  My Profile
                </Link>
                <Link className="list-group-item" to="/signout">
                  Sign Out
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="cardSection flex"
        style={{
          display:
            location.pathname === "/buyerdashboard" ||
            location.pathname === "/buyerdashboard/"
              ? "flex"
              : "none",
        }}
      >
        <div className="rightCard flex">
          <BuyerDashboardContent data={services[1]} />

          <div className="buttons flex">
            <button
              className="btn"
              style={{ backgroundColor: "#fff" }}
              onClick={() => navigate("/buyerdashboard/PostRequirement")}
            >
              Post Requirement
            </button>
            <button
              className="btn transparent"
              onClick={() => navigate("/buyerdashboard/MyRequirement")}
            >
              My Requirement
            </button>
          </div>

          <div className="videoDiv">
            <video src={video} autoPlay loop muted></video>
          </div>
        </div>

        <div className="leftCard flex">
          <div className="main flex">
            <div className="textDiv">
              <h1>My Stat</h1>

              <div className="flex">
                <span>
                  Today <br /> <small>{orders?.length} Orders</small>
                </span>
                {/* <span>
                  This Month <br /> <small>0 Orders</small>
                </span> */}
              </div>

              <Link to="/buyerdashboard/Orders">
                <span className="flex link">
                  Go to my orders <BsArrowRightShort className="icon" />
                </span>
              </Link>
            </div>

            <div className="imgDiv">
              <img src={img2} alt="Image Name" />
            </div>
          </div>

          {/* We shall use this card later .... */}
          <div className="sideBarCard">
            <BsQuestionCircle className="icon" />
            <div className="cardContent">
              <div className="circle1"></div>
              <div className="circle2"></div>

              <h3>Help Center</h3>
              <p>
                Having trouble in Onclq, please contact us from for more
                questions.
              </p>

              <button className="btn">Go to help center</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
