import Joi from "joi-browser";

export const PostRequirementSchema = {
  bidding_date: Joi.string().required().label("Bidding date"),
  cat_id: Joi.optional().allow(""),
  cat_name: Joi.optional().allow(""),
  created_date: Joi.optional().allow(""),
  delivery_date: Joi.string().required().label("Delivery Date"),
  description: Joi.string().required().label("Description"),
  flap: Joi.optional().allow(""),
  gazette: Joi.optional().allow(""),
  height: Joi.optional().allow(""),
  id: Joi.optional().allow(""),
  img1: Joi.optional().allow(""),
  length: Joi.optional().allow(""),
  location: Joi.string().required().label("Location"),
  material_type_name: Joi.optional().allow(""),
  micron_type_name: Joi.optional().allow(""),
  name: Joi.string().required().label("Campany Name"),
  no_of_color: Joi.optional().allow(""),
  ply: Joi.optional().allow(""),
  postid: Joi.optional().allow(""),
  pod: Joi.optional().allow(""),
  printing: Joi.optional().allow(""),
  pincode: Joi.string().required().label("PIN Code"),
  quantity: Joi.string().required().label("Quantity"),
  req_gsm: Joi.array().optional(""),
  req_img: Joi.array().optional(""),
  size: Joi.optional().allow(""),
  status: Joi.optional().allow(""),
  gsm: Joi.optional().allow(""),
  no_of_bid: Joi.optional().allow(""),
  requirement_close_date: Joi.optional().allow(""),
  updated_date: Joi.optional().allow(""),
  style_name: Joi.optional().allow(""),
  subcat_id: Joi.optional().allow(""),
  subcat_name: Joi.optional().allow(""),
  super_subcat_id: Joi.optional().allow(""),
  super_subcat_name: Joi.optional().allow(""),
  target_price: Joi.optional().allow(""),
  user_id: Joi.optional().allow(""),
  token: Joi.optional().allow(""),
  width: Joi.optional().allow(""),
};
