export const BuyData = [
    // {
    //   id: 1,
    //   year: 2016,
    //   sale: 600000,
    // },
    // {
    //   id: 2,
    //   year: 2017,
    //   sale: 400000,
    // },
    // {
    //   id: 3,
    //   year: 2018,
    //   sale: 700000,
    // },
    // {
    //   id: 4,
    //   year: 2019,
    //   sale: 800000,
    // },
    // {
    //   id: 5,
    //   year: 2020,
    //   sale: 900000,
    // },
    // {
    //   id: 3,
    //   year: 2021,
    //   sale: 100000,
    // },
    // {
    //   id: 4,
    //   year: 2022,
    //   sale: 200000,
    // },
    // {
    //   id: 4,
    //   year: 2023,
    //   sale: 600000,
    // },
];