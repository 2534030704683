import React from "react";

const Input = ({ name, label, error, ...rest }) => {
  return (
    <>
      <div className="form-group">
        <div>
          <label
            htmlFor={name}
            className={`${error ? "text-danger" : "text-duccess"}`}
          >
            {label}
            {error && <span className="text-danger"> * </span>}
          </label>
        </div>
        <div>
          <input {...rest} id={name} name={name} className="form-control" />
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default Input;
