import React from 'react'
import Table from './../../../common/table';

const BuyerTable = (props) => {

    const { data, columns, ...rest } = props;

    return (
    <>

    <Table
      columns={columns}
      data={data}
      {...rest}
     />

    </>
  )
}

export default BuyerTable