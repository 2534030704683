import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { SignUpData } from "../../../data/signUpData";
import Input from "../../../common/input";
import auth from "../../../services/authService";
import getService from "../../../services/getService";

const UserForm = () => {
  const params = useParams();

  let navigate = useNavigate();

  const [account, setAccount] = useState({
    user_id: "",
    email: "",
    password: "",
    token: "new",
  });

  const [errors, setErrors] = useState({});
  const credStep = "New User";

  const schema = {
    user_id: Joi.optional().allow(""),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string().required().label("Password"),
    token: Joi.optional().allow(""),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const PopulateForm = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleUserList();
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    PopulateForm();
  }, []);

  const mapToViewModel = (data) => {
    console.log("mao data", data);
    return {
      user_id: data.id,
      email: data.email,
      password: data.password,
      token: data.token,
    };
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    const data = {
      ...SignUpData,
      email: account.email,
      password: account.password,
      signup_step: "admin",
      user_id: account.user_id,
      token: account.token,
    };
    try {
      await auth.signup(data, credStep);
    } catch (error) {
      toast.error(error);
    }
  };

  console.log("account", account);

  const { email, password } = account;

  return (
    <>
      <section className="d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="app-login">
              <div className="app-form-logo">
                <div className="app-form-logo-content"></div>
              </div>

              <form className="app-form" onSubmit={handleSubmit}>
                <h2 className="text-align-center">Create/Update User</h2>
                <Input
                  type="email"
                  placeholder="Email or Username"
                  name="email"
                  label="Email"
                  error={errors.email}
                  value={email}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  placeholder="Password"
                  name="password"
                  label="Password"
                  error={errors.password}
                  value={password}
                  onChange={handleChange}
                />
                <button
                  type="submit"
                  className="btn app-login-button"
                  disabled={validate()}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserForm;
