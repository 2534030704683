import React, { useState } from "react";
import buyer from "../../../assets/images/buyer.png";
import seller from "../../../assets/images/seller.png";

const ChooseUserCard = ({
  setUserType,
  validateNext,
  buyerClass,
  setBuyerClass,
  sellerClass,
  setSellerClass,
  setSignupCard,
  signupCard,
}) => {
  const handleBuyer = () => {
    buyerClass === ""
      ? setBuyerClass("active")
        ? setUserType("")
        : setUserType("Buyer")
      : setBuyerClass("")
      ? setUserType("Buyer")
      : setUserType("");

    if (sellerClass === "active") {
      setSellerClass("");
    }
  };

  const handleSeller = () => {
    sellerClass === ""
      ? setSellerClass("active")
        ? setUserType("")
        : setUserType("Seller")
      : setSellerClass("")
      ? setUserType("Seller")
      : setUserType("");

    if (buyerClass === "active") {
      setBuyerClass("");
    }
  };

  //   const validateNext = () =>  (userType === "")  ? true : false;

  return (
    <>
      <section
        className="user-section"
        style={{ display: signupCard ? "none" : "block" }}
      >
        <div className="container">
          <h3 style={{ textAlign: "center" }}>Select Role </h3>
          <div className="row">
            <div className="col-md-6">
              <div className="user-profile" onClick={handleBuyer}>
                <img className={buyerClass} src={buyer} alt="buyer" />
                <h3>Buyer</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-profile" onClick={handleSeller}>
                <img className={sellerClass} src={seller} alt="seller" />
                <h3>Seller</h3>
              </div>
            </div>

            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="btn btn-continue"
                disabled={validateNext()}
                onClick={() => setSignupCard(true)}
              >
                Next Step
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseUserCard;
