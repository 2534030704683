import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import logo from "../assets/navigation-menu/BlackLogo.png";
import getService from "../services/getService";
import Top from "./../application/BuyerDashboard/BuyerBody Section/Top Section/Top";
import Loader from "./loader";

const Invoice = (props) => {
  const [data, setData] = useState([]);
  const [getBuyer, setGetBuyer] = useState(undefined);
  const [getSeller, setGetSeller] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const invoiceDocument = useRef();
  const location = useLocation();

  const formatter = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
  });

  const fetchBuyer = async (buyer_id) => {
    const collection = await getService.handleUserList();
    const data = await getService.getData(collection, buyer_id);
    setGetBuyer(data);
    setLoading(true);
  };

  const fetchSeller = async (seller_id) => {
    const collection = await getService.handleUserList();
    const data = await getService.getData(collection, seller_id);
    setGetSeller(data);
    setLoading(true);
  };

  const handlePrint = useReactToPrint({
    content: () => invoiceDocument.current,
  });

  useEffect(() => {
    setData(location.state.data);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (data) fetchBuyer(data.buyer_id);
  }, [data]);

  useEffect(() => {
    if (data) fetchSeller(data.seller_id);
  }, [data]);

  console.log("data", data);

  if (!getBuyer || !getSeller || !data) return <Loader />;

  return (
    <>
      <div className="mainContent">
        <Top />
        <div className="container" ref={invoiceDocument}>
          <div className="row">
            <div className="col-xs-12">
              <div className="grid invoice">
                <div className="grid-body">
                  <div className="invoice-title">
                    <div className="row">
                      <div className="col-xs-12">
                        <img
                          src={logo}
                          alt=""
                          height="35"
                          style={{ width: "20%" }}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-xs-12">
                        <h2>
                          <br />
                          <span style={{ float: "left" }} className="small">
                            Invoice #{data.id}
                          </span>
                          <span style={{ float: "right" }} className="small">
                            Order #{data.order_id}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <address>
                        <strong>Billed To:</strong>
                        <br />
                        {getBuyer.name}
                        <br />
                        {getBuyer.address}
                        <br />
                        <abbr title="Phone">P:</abbr> {getBuyer.mobile}
                      </address>
                    </div>
                    <div className="col-md-6 text-right">
                      <address>
                        <strong>Shipped To:</strong>
                        <br />
                        {getSeller.name}
                        <br />
                        {getSeller.address}
                        <br />
                        <abbr title="Phone">P:</abbr> {getSeller.mobile}
                      </address>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <address>
                        <strong>Payment Method:</strong>
                        {/* <br />
                        Visa ending **** 1234
                        <br />
                        h.elaine@gmail.com
                        <br /> */}
                      </address>
                    </div>
                    <div className="col-md-6 text-right">
                      <address>
                        <strong>Order Date:</strong>
                        <br />
                        {data.invoice_date}
                      </address>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <h3>ORDER SUMMARY</h3>
                      <table className="table table-striped">
                        <thead>
                          <tr className="line">
                            <td>
                              <strong>#</strong>
                            </td>
                            <td className="text-center">
                              <strong>PROJECT</strong>
                            </td>
                            <td className="text-center">
                              <strong>Quantity</strong>
                            </td>
                            {/* <td className="text-center">
                              <strong>Facilitation Fee</strong>
                            </td> */}
                            <td className="text-center">
                              <strong>GST</strong>
                            </td>
                            <td className="text-center">
                              <strong>Price</strong>
                            </td>
                            <td className="text-right">
                              <strong>SUBTOTAL</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <strong>{data.invoice_title}</strong>
                              <br />
                              {data.invoice_description}
                            </td>
                            <td className="text-center">
                              {Number(data.quantity)}
                            </td>
                            {/* <td className="text-center">
                              {formatter.format(
                                0.05 *
                                  (Number(data.gst_amount) +
                                    Number(data.amount))
                              )}
                            </td> */}
                            <td className="text-center">
                              {formatter.format(Number(data.gst_amount))}
                            </td>
                            <td className="text-center">
                              {formatter.format(Number(data.amount))}
                            </td>
                            <td className="text-right">
                              {formatter.format(
                                // 0.05 *
                                //   (Number(data.gst_amount) +
                                //     Number(data.amount)) +
                                Number(data.gst_amount) + Number(data.amount)
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-right identity">
                      <p>
                        Sold By
                        <br />
                        <strong>{getSeller.name}</strong>
                        <br />
                        <strong>{getSeller.mobile}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ float: "right", marginRight: "10px" }}>
          <button className="btn btn-danger" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </>
  );
};

export default Invoice;
