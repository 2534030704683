import React, { useState, useEffect } from "react";
import Input from "./../../../common/input";
import { useLocation, useParams } from "react-router-dom";
import Top from "./Top Section/Top";
import { toast } from "react-toastify";
import postService from "../../../services/postService";
import getService from "../../../services/getService";

const SellerInvoiceForm = () => {
  const [account, setAccount] = useState({
    id: "",
    amount: "",
    gst_amount: "",
    quantity: "",
    invoice_title: "",
    invoice_description: "",
  });
  const location = useLocation();

  const [odr, setOdr] = useState([]);
  const fetchOdr = async () => {
    const order = await location.state.order;
    setOdr(order);
  };
  const params = useParams();

  useEffect(() => {
    fetchOdr();
  }, []);

  const PopulateForm = async (odr) => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await odr;
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ ...rest }) => ({ ...rest });
  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postService.PostInvoice({
        ...account,
        seller_id: odr.seller_id,
        order_id: odr.id,
        buyer_id: odr.buyer_id,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (odr.invoice_list) PopulateForm(odr.invoice_list);
  }, [odr.invoice_list]);

  const { amount, gst_amount, invoice_description, invoice_title, quantity } =
    account;
  return (
    <>
      <div className="mainContent">
        <Top />
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-10 col-md-pull-2 mt-2">
              <Input
                label="Invoice Title"
                type="text"
                name="invoice_title"
                value={invoice_title}
                placeholder="Enter your Invoice Title"
                onChange={handleChange}
              />
            </div>

            <div className="col-md-10 col-md-pull-2 mt-2">
              <label for={invoice_description} className="form-label">
                Invoice Description
              </label>
              <textarea
                className="form-control"
                rows="5"
                name="invoice_description"
                value={invoice_description}
                placeholder="Describe your Invoice Description here...."
                onChange={handleChange}
              />
              <p className="td-words-linit">4000 character left.</p>
            </div>

            <div className="col-md-10 col-md-pull-2 mt-2">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label="Amount"
                    type="text"
                    name="amount"
                    value={amount}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="GST Price"
                    type="text"
                    name="gst_amount"
                    value={Math.round(0.18 * amount)}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Quantity"
                    type="text"
                    name="quantity"
                    value={quantity}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ textAlign: "right" }}
              className="col-md-10 col-md-pull-2 mt-2"
            >
              <button className="btn btn-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SellerInvoiceForm;
