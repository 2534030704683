import React from "react";

const FootOne = ({ data }) => {
  if (!data) return null;
  return (
    <>
      <img src={data.img1} alt="applogo" />
      <div dangerouslySetInnerHTML={{ __html: data.description }} />
    </>
  );
};

export default FootOne;
