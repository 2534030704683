import React, { useState } from "react";
import Input from "./input";

const ViewGsm = ({ gsm }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        style={{ width: "100%", textAlign: "inherit" }}
        className="btn btn-outline-dark"
        onClick={() => setOpen(!open)}
      >
        View GSM for Paper
      </button>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                GSM
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered createfield"
                  id="supplierList"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>SL.</th>
                      <th style={{ textAlign: "center" }}>GSM Value</th>
                    </tr>
                  </thead>
                  <tbody id="supplier_tablebody">
                    {gsm.map((item, index) => (
                      <>
                        <tr role="row" className="odd">
                          <td style={{ textAlign: "center" }} tabindex="0">
                            {gsm.indexOf(item) + 1}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            tabindex="1"
                          >
                            <Input
                              type="number"
                              name="gsmValue"
                              value={item.req_gsm}
                              disabled={true}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpen(!open)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => setOpen(!open)}
              >
                Yes, close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewGsm;
