export const PostRequirementData = {
  bidding_date: "",
  cat_id: "",
  cat_name: "",
  created_date: "",
  delivery_date: "",
  description: "",
  flap: "",
  gazette: "",
  height: "",
  id: "",
  img1: "",
  length: "",
  location: "",
  material_type_name: "",
  micron_type_name: "",
  name: "",
  no_of_color: "",
  pincode: "",
  ply: "",
  postid: "",
  pod: "",
  printing: "",
  quantity: "",
  req_gsm: [],
  req_img: [],
  size: "",
  status: "",
  style_name: "",
  subcat_id: "",
  subcat_name: "",
  super_subcat_id: "",
  super_subcat_name: "",
  target_price: "",
  user_id: "",
  token: "",
  width: "",
};
