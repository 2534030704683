import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Input from './../../../../common/input';
import Checkbox from './../../../../common/checkbox';
import Select from './../../../../common/select';
import getService from '../../../../services/getService';

const Filter = ({location, quantity, material, product, timeline, maxValue, minValue, handleMin, handleMax,
                 handleLocation, handleQuantity, handleMaterial, handleProduct, handleTimeline}) => {

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubcategoryList] = useState([]);

  const fetchCategoryList = async() => {
    const category =await getService.handleCategoryList();
    const newCategory = category.map(({title: name, ...rest}) => ({name, ...rest}));
    setCategoryList(newCategory);
  };



  const fetchSubCategoryList = async(post_category) => {
    const subcategory = await getService.handleSubCategoryList();
    const newSubCategory = subcategory.map(({title: name, ...rest}) => ({name, ...rest}));
    const filtered = newSubCategory.filter(subCat => subCat.cat_name === post_category);
    setSubcategoryList(filtered);
  }


  useEffect(() => {
    fetchCategoryList();
  },[]);

  useEffect(() => {
    if(material) fetchSubCategoryList(material);
  },[material]);



  
  return (
    <>
    <div className="filter">
     <ul class="list-group">
        <li class="list-group-item">
            <h1>Filter</h1>
        </li>

        <li class="list-group-item">
            <div className="filterCard">
                <div className="row">
                    <div className="col-md-12">
                    <Input label="Location" name="location" placeholder="Location" value={location} onChange={e => handleLocation(e.currentTarget.value) } />
                    </div>
                </div>


            </div>
        </li>

        <li class="list-group-item">
            <div className="filterCard">
                <div className="row">
                    <div className="col-md-12">
                    <Input type="number" label="Quantity" name="quantity" placeholder="Quantity" value={quantity} onChange={e => handleQuantity(e.currentTarget.value)} />
                    </div>
                </div>


            </div>
        </li>
        <li class="list-group-item">
            <div className="filterCard">
                <div className="title">
                    <h3>Order Value</h3>
                    <Link>Clear</Link>
                </div>
                <div className="row">
                    <div className="col-md-5">
                    <Input type="number" label="Min" name="minValue" placeholder="₹0+" value={minValue} onChange={e => handleMin(e.currentTarget.value)}  />
                    </div>
                    <div className="col-md-2">
                    <p className='p'>to</p>
                    </div>
                    <div className="col-md-5">
                    <Input type="number" label="Max" name="maxValue" placeholder="₹100+" value={maxValue} onChange={e => handleMax(e.currentTarget.value)} />
                    </div>
                </div>


            </div>
        </li>
        <li class="list-group-item">
            <div className="filterCard">
                <div className="row">
                    <div className="col-md-12">
                    <Select label="Material" name="material" placeholder="Material" options={categoryList} value={material} onChange={e => handleMaterial(e.currentTarget.value)} />
                    </div>
                </div>


            </div>
        </li>

        <li class="list-group-item">
            <div className="filterCard">
                <div className="row">
                    <div className="col-md-12">
                    <Select label="Product" name="product" placeholder="Product" options={subCategoryList} value={product} onChange={e => handleProduct(e.currentTarget.value)} />
                    </div>
                </div>


            </div>
        </li>


        <li class="list-group-item">
            <div className="filterCard">
                <div className="row">
                    <div className="col-md-12">
                    <Input label="Delivery Timeline" name="timeline" placeholder="Delivery Timeline" value={timeline} onChange={e => handleTimeline(e.currentTarget.value)} type="date" />
                    </div>
                </div>


            </div>
        </li>



     </ul>
    </div>
    </>
  )
}

export default Filter