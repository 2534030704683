export const SubAdminData = {
  id: "",
  username: "",
  password: "",
  full_name: "",
  email: "",
  mobile: "",
  address: "",
  img1: "",
  type: "",
  fb_url: "",
  twitter_url: "",
  instagram_url: "",
  google_url: "",
  youtube_url: "",
  linkedin_url: "",
  status: "",
  created_date: "",
  last_login_date: "",
  req_module: [],
};
