import React from "react";
import logo from "../../../../assets/navigation-menu/BlackLogo.png";

const ChatWelcome = () => {
  return (
    <div className="banner" style={{ height: "85vh" }}>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <h1>Onclq Support</h1>
      <p>Send and Receive message directly to your clients. </p>
    </div>
  );
};

export default ChatWelcome;
