import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import Input from "../../../common/input";
import { ComapnySchema } from "./../../../data/CompanySchema";
import Select from "./../../../common/select";
import { TypeofCompany } from "./../../../data/TypeofCompany";
import { TypeofBusiness } from "./../../../data/TypeofBusiness";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import getService from "../../../services/getService";
import upload from "../../../../components/assets/images/upload.jpg";
import pdfupload from "../../../../components/assets/images/pdf-upload.png";
import { getFileTypeFromBase64 } from "./../../../utils/getFileTypeFromBase64";
import { openPdfInNewTab } from "../../../utils/openPdfInNewTab";

const CompanyDetails = ({
  account,
  setAccount,
  currentStep,
  myAccount,
  updateStep,
}) => {
  console.log("account", account);
  const [Img, setImg] = useState("");
  const [businessList, setBusinessList] = useState([]);
  const [gstUrl, setGstUrl] = useState(account.gst_pic);
  const [cinUrl, setCinUrl] = useState(account.cin_pic);
  const [panUrl, setPanUrl] = useState(account.pan_pic);

  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, ComapnySchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: ComapnySchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "company",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateCompany = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchBusinessList = async () => {
    const data = await getService.handleBusinessTypeList();
    const filter = data.filter((f) => f.user_type === account.user_type);
    const newData = filter.map(({ title: name, ...rest }) => ({
      name,
      ...rest,
    }));
    setBusinessList(newData);
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    fetchBusinessList();
  }, []);

  useEffect(() => {
    PopulateCompany();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const handleGST = (item) => {
    gstRef.current.click();
  };

  const handleCIN = (item) => {
    cinRef.current.click();
  };

  const handlePAN = (item) => {
    panRef.current.click();
  };

  const setGstPic = () => {
    setGstUrl(fileUrl);
    setAccount({ ...account, gst_pic: url });
    setUrl("");
  };

  const setCinPic = () => {
    setCinUrl(fileUrl);
    setAccount({ ...account, cin_pic: url });
    setUrl("");
  };

  const setPanPic = () => {
    setPanUrl(fileUrl);
    setAccount({ ...account, pan_pic: url });
    setUrl("");
  };

  const ImageUpload = () => {
    Img === "gst_pic" ? setGstPic() : console.log("account");
    Img === "cin_pic" ? setCinPic() : console.log("account cin");
    Img === "pan_pic" ? setPanPic() : console.log("account pan");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const {
    company_name,
    company_type,
    business_type,
    address,
    company_registration_date,
    gst_no,
    cin_no,
    pan_no,
    signup_step,
  } = account;

  const gstRef = useRef();
  const cinRef = useRef();
  const panRef = useRef();

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  return (
    <>
      <section className="personaldetails">
        <div className="container">
          <h3>Company Details</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Company Name"
                  name="company_name"
                  type="text"
                  error={errors.company_name}
                  value={company_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Type of Company"
                  name="company_type"
                  value={company_type}
                  error={errors.company_type}
                  options={TypeofCompany}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Type of Business"
                  name="business_type"
                  value={business_type}
                  error={errors.business_type}
                  options={businessList}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Address"
                  name="address"
                  error={errors.address}
                  value={address}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-md-6">
                  <Input
                    label="Date of Registration"
                    name="company_registration_date"
                    type="date"
                    error={errors.company_registration_date}
                    value={company_registration_date}
                    onChange={handleChange}
                  />
                </div> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      label="GST No"
                      name="gst_no"
                      error={errors.gst_no}
                      value={gst_no}
                      onChange={handleChange}
                    />
                    {/* <div className="row">
                      <div className="col-md-6"> GST Cerificate</div>
                      <div className="col-md-6">
                        <div className="img-upload">
                          <div className="img">
                            {gstUrl &&
                              (getFileTypeFromBase64(gstUrl) === "Image" ? (
                                <img src={gstUrl} alt="gstPic" />
                              ) : getFileTypeFromBase64(gstUrl) === "Pdf" ? (
                                <a
                                  href="#"
                                  onClick={() => openPdfInNewTab(gstUrl)}
                                >
                                  <img src={pdfupload} alt="gstPic" />
                                </a>
                              ) : (
                                <img src={upload} alt="gstPic" />
                              ))}

                            {!gstUrl && <img src={upload} alt="gstPic" />}
                          </div>
                          <div className="upload-button">
                            <input
                              ref={gstRef}
                              style={{ display: "none" }}
                              accept=".jpg,.jpeg,.png,.pdf"
                              name="gst_pic"
                              label="Upload Image"
                              type="file"
                              error={errors.gst_pic}
                              onChange={onImageChange}
                            />
                            <button
                              type="button"
                              className="btn btn-attach"
                              onClick={handleGST}
                            >
                              <i className="fa fa-cloud-upload"></i> <br />
                              Upload Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {/* <div className="col-md-6">
                    <Input
                      label="CIN Number"
                      name="cin_no"
                      error={errors.cin_no}
                      value={cin_no}
                      onChange={handleChange}
                    />
                    <div className="row">
                      <div className="col-md-6">CIN Certificate</div>
                      <div className="col-md-6">
                        <div className="img-upload">
                          <div className="img">
                            {cinUrl &&
                              (getFileTypeFromBase64(cinUrl) === "Image" ? (
                                <img src={cinUrl} alt="gstPic" />
                              ) : getFileTypeFromBase64(cinUrl) === "Pdf" ? (
                                <a
                                  href="#"
                                  onClick={() => openPdfInNewTab(cinUrl)}
                                >
                                  <img src={pdfupload} alt="cinpic" />
                                </a>
                              ) : (
                                <img src={upload} alt="cinpic" />
                              ))}

                            {!cinUrl && <img src={upload} alt="cinPic" />}
                          </div>
                          <div className="upload-button">
                            <input
                              ref={cinRef}
                              style={{ display: "none" }}
                              accept=".jpg,.jpeg,.png,.pdf"
                              name="cin_pic"
                              label="Upload Image"
                              type="file"
                              error={errors.cin_pic}
                              onChange={onImageChange}
                            />
                            <button
                              type="button"
                              className="btn btn-attach"
                              onClick={handleCIN}
                            >
                              <i className="fa fa-cloud-upload"></i> <br />
                              Upload Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <Input
                      label="PAN Number"
                      name="pan_no"
                      value={pan_no}
                      error={errors.pan_no}
                      onChange={handleChange}
                    />
                    {/* <div className="row">
                      <div className="col-md-6">PAN Card</div>
                      <div className="col-md-6">
                        <div className="img-upload">
                          <div className="img">
                            {panUrl &&
                              (getFileTypeFromBase64(panUrl) === "Image" ? (
                                <img src={panUrl} alt="gstPic" />
                              ) : getFileTypeFromBase64(panUrl) === "Pdf" ? (
                                <a
                                  href="#"
                                  onClick={() => openPdfInNewTab(panUrl)}
                                >
                                  <img src={pdfupload} alt="panpic" />
                                </a>
                              ) : (
                                <img src={upload} alt="panpic" />
                              ))}

                            {!panUrl && <img src={upload} alt="PanCard" />}
                          </div>
                          <div className="upload-button">
                            <input
                              ref={panRef}
                              style={{ display: "none" }}
                              accept=".jpg,.jpeg,.png,.pdf"
                              name="pan_pic"
                              label="Upload Image"
                              type="file"
                              error={errors.pan_pic}
                              onChange={onImageChange}
                            />
                            <button
                              type="button"
                              className="btn btn-attach"
                              onClick={handlePAN}
                            >
                              <i className="fa fa-cloud-upload"></i> <br />
                              Upload Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Input
                  name="signup_step"
                  label="Signup_step"
                  value={signup_step}
                  onChange={handleChange}
                  disabled="true"
                />
              </div>
            </div>

            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    type="button"
                    className="primaryButton"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    // disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>

                  <button
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    onClick={() => navigate("/membership")}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default CompanyDetails;
