import React, { useState } from "react";

const UpdateUserStatus = (props) => {
  const [open, setOpen] = useState(false);

  const handleId = () => {
    props.setId(props.ItemId.id);
    setOpen(!open);
  };

  const handleClose = () => {
    props.setId(null);
    setOpen(!open);
  };

  const handleUpdate = () => {
    props.onClick({
      user_id: props.ItemId.id,
      token: props.ItemId.token,
      status: props.ItemId.status === "Approved" ? "Inactive" : "Approved",
    });
    setOpen(!open);
  };

  return (
    <>
      <span
        type="button"
        // className="btn btn-danger btn-xs m-b-5 custom_btn"
        className={`badge rounded-circle ${
          props.ItemId.status === "Approved"
            ? "text-bg-success"
            : "text-bg-danger"
        }`}
        onClick={handleId}
      >
        <i
          className={`fa fs-5 ${
            props.ItemId.status === "Approved" ? "fa-check-circle " : "fa-ban"
          }`}
          aria-hidden="true"
        ></i>
      </span>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                User Status
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              Are You Sure to{" "}
              {props.ItemId.status === "Approved" ? "Inactive" : "Approved"}{" "}
              this user. ?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleUpdate}
              >
                Yes, Sure
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserStatus;
