import CompanyDetails from "../application/Card/Forms/CompanyDetails";
import PersonalDetails from "../application/Card/Forms/PersonalDetails";
import BankDetails from './../application/Card/Forms/BankDetails';
import ImagesBuyer from './../application/Card/Forms/ImagesBuyer';

import { BsFilePersonFill} from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import { RiBankCardFill } from 'react-icons/ri';
import { FaImages } from 'react-icons/fa';


export const BuyerFormData = [
    {id: 0, name: "Personal Details", form: <PersonalDetails />, ReactIcons: <BsFilePersonFill className="icon" /> },
    {id: 1, name: "Company Details",  form: <CompanyDetails />, ReactIcons: <HiUserGroup className="icon" /> },
    {id: 3, name: "Bank details",  form: <BankDetails />, ReactIcons: <RiBankCardFill className="icon" />},
    {id: 4, name: "Images",  form: <ImagesBuyer />, ReactIcons: <FaImages className="icon" />},
]