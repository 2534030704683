import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import SellerTable from "./SellerTable";
import Pagination from "../../../common/pagination";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import { CSVLink, CSVDownload } from "react-csv";
import Tracker from "./../../../common/tracker";
import Payment from "../../../common/payment";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";
import NoCart from "../../../common/noCart";

const Sellerledger = ({ myAccount }) => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    // const data = list.leader_list.map(({ ...rest }) => ({
    //   ...rest,
    // }));
    // const sortedDateArray = data.sort((a, b) => {
    //   const date1 = new Date(a.order_date);
    //   const date2 = new Date(b.order_date);
    //   return date2 - date1;
    // });
    // const filteredArray = sortedDateArray.filter(filter => filter.status === "Active");
    setOrders(list.leader_list);
    setLoading(true);
  };

  const fetchOrderList = async (myAccount) => {
    const list = await getService.handleLeadgerList({
      user_id: myAccount.id,
      user_type: myAccount.user_type,
    });
    list?.leader_list === undefined ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    if (myAccount) fetchOrderList(myAccount);
  }, [myAccount]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log("my order", orders);

  const getPagedData = () => {
    const order = paginate(orders, currentPage, pageSize);
    return { totalCount: orders.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (order) => <p>{orders.indexOf(order) + 1}</p>,
    },
    { path: "order_id", label: "Order_ID" },
    { path: "total_order_amount", label: "Outstanding" },
    { path: "total_payment_amount", label: "Payement" },
    { path: "total_payment_amount", label: "Received" },
    { path: "remaning_amount", label: "Pending" },
    // { path: "due", label: "Due" },
    // { path: "remarks", label: "Remarks" },
  ];

  if (loading === false) return <Loader />;

  return (
    <>
      {totalCount === 0 && <NoCart />}

      {totalCount !== 0 && (
        <div className="mainContent">
          <Top />
          <div>
            <CSVLink data={orders} className="btn btn-outline-success">
              Download Report
            </CSVLink>
          </div>
          <SellerTable columns={columns} data={order} />

          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default Sellerledger;
