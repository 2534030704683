import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import { CSVLink, CSVDownload } from "react-csv";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";
import NoCart from "../../../common/noCart";
import BuyerTable from "./BuyerTable";
import Rate from "./../../../common/rate";
import postService from "../../../services/postService";
import { toast } from "react-toastify";

const BuyerRating = ({ myAccount }) => {
  const [orders, setOrders] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    const data = list.order_list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.order_date);
      const date2 = new Date(b.order_date);
      return date2 - date1;
    });
    const filteredArray = sortedDateArray.filter(
      (filter) => filter.order_tracker_status === "Delivered"
    );
    setOrders(filteredArray);
    setLoading(true);
  };

  const fetchOrderList = async (myAccount) => {
    const list = await getService.handleSellerOrderList({
      user_id: myAccount.id,
      token: myAccount.token,
    });
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(myAccount);
  }, [myAccount]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRating = async (data) => {
    try {
      await postService.PostRating(data);
      setCurrentPage(1);
      fetchOrderList(myAccount);
    } catch (error) {
      toast.error(error);
    }
  };

  const getPagedData = () => {
    const order = paginate(orders, currentPage, pageSize);
    return { totalCount: orders.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (order) => <p>{orders.indexOf(order) + 1}</p>,
    },
    { path: "post_requirement_id", label: "Order Id" },
    { path: "seller_id", label: "Seller Id" },

    {
      path: "orderStatus",
      label: "Order Status",
      content: (order) => <p>{order.order_tracker_status}</p>,
    },
    {
      key: "Rate",
      label: "Rate",
      content: (order) => (
        <>
          {" "}
          <Rate
            onClick={handleRating}
            rating={order.rating}
            userType={myAccount.user_type}
            token={myAccount.token}
          />
        </>
      ),
    },
  ];

  if (loading === false) return <Loader />;

  return (
    <>
      {totalCount === 0 && <NoCart />}

      {totalCount !== 0 && (
        <div className="mainContent">
          <Top />
          <div>
            <CSVLink data={order} className="btn btn-outline-success">
              Download Report
            </CSVLink>
          </div>
          <BuyerTable columns={columns} data={order} />

          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default BuyerRating;
