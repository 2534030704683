import React, { useState } from "react";
import Input from "./input";
import Checkbox from "./checkbox";

const PostReq = ({ open, setOpen, onChange, value, errors, onValidate }) => {
  const [targetprice, setTargetPrice] = useState("");

  return (
    <>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Post Requirement
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                    }}
                  >
                    <label htmlFor="targetprice"> Target Price</label>
                    <diuv>
                      <Checkbox
                        label="Yes"
                        type="radio"
                        name="targetprice"
                        value="Yes"
                        onChange={() => setTargetPrice("Yes")}
                      />
                      <Checkbox
                        label="No"
                        type="radio"
                        name="targetprice"
                        value="No"
                        onChange={() => setTargetPrice("No")}
                      />
                    </diuv>
                  </div>
                </div>
              </div>
              {targetprice === "Yes" && (
                <div style={{ padding: "1rem 0px" }}>
                  <Input
                    style={{ marginTop: "10px" }}
                    name="target_price"
                    value={value}
                    label="Enter the Target Price for your requirement"
                    placeholder="Target Price"
                    onChange={onChange}
                    type="number"
                    error={errors.target_price}
                  />
                </div>
              )}
              <p>
                By clicking 'Yes, post my requirement' , you agree to the Terms
                & Conditions and Privacy Policy <br />
                Copyright © 2023 Onclq Pty Limited
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpen(!open)}
              >
                Close
              </button>
              <button
                className="btn btn-success"
                // disabled={onValidate()}
                onClick={() => setOpen(!open)}
              >
                Yes, post
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostReq;
