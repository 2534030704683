import React, { useState, useRef, useEffect } from "react";
import Input from "./../../../common/input";
import { Link, useParams } from "react-router-dom";
import PackagingandDesign from "../../../assets/images/PackagingandDesign.webp";
import Select from "./../../../common/select";
import { BoxStyle } from "./../../../data/boxStyle";
import { PolyMaterialType } from "./../../../data/polyMaterialType";
import { PolyMicronType } from "./../../../data/polyMicronType";
import { PolyStyle } from "./../../../data/polyStyle";
import Checkbox from "./../../../common/checkbox";
import postService from "../../../services/postService";
import Categories from "./../../../common/categories";
import { Badge } from "react-bootstrap";
import PostReq from "./../../../common/PostReq";
import { toast } from "react-toastify";
import { PostRequirementData } from "./../../../data/postRequirementData";
import { Units } from "../../../data/Units";
import Joi from "joi-browser";
import { PostRequirementSchema } from "./../../../data/postRequirementSchema";
import Gsm from "./../../../common/gsm";
import getService from "../../../services/getService";
import PostReqContent from "./../../../common/postReqContent";
import upload from "../../../../components/assets/images/upload.jpg";
import { MdCancel } from "react-icons/md";

const MAX_COUNT = 5;

const AdminRequirementForm = ({ myAccount }) => {
  const [post, setPost] = useState(PostRequirementData);
  const [services, setService] = useState([]);
  const [pod, setPod] = useState("");
  const [print, setPrint] = useState("");
  const [categoryShow, setCategoryShow] = useState(false);
  const [checkoutShow, setCheckoutShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState(Array(post.ply).fill());
  const [users, setUsers] = useState([]);
  const [account, setAccount] = useState(undefined);
  const [Img, setImg] = useState("");
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const fetchService = async () => {
    const data = await getService.handleContentList();
    setService(data);
  };

  useEffect(() => {
    fetchService();
  }, []);

  const fetchUsers = async () => {
    const user = await getService.handleUserList();
    const filteredArray = user.filter((filter) => filter.user_type === "Buyer");

    const campanyArray = filteredArray.map(
      ({ name: title, company_name: name, ...rest }) => ({
        title,
        name,
        ...rest,
      })
    );
    const data = campanyArray.filter((c) => c.name !== null);
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const inputRef = useRef();
  const params = useParams();
  const Id = params.id;

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const formatDuration = (item) => {
    const sizeinKB = item / 1024;
    if (sizeinKB >= 1024) {
      const sizeLeft = Math.round(sizeinKB);
      const sizeinMb = sizeLeft / 1024;
      return `${sizeinMb.toFixed(2)} MB`;
    } else {
      return `${sizeinKB.toFixed(2)} KB`;
    }
  };

  const removeFile = (index) => {
    const data = uploadedFiles.filter((m, i) => i !== index);
    setUploadedFiles(data);
  };

  let reqimg1 = [];
  const getBase64 = (item) => {
    if (!(item instanceof Blob)) {
      reqimg1.push({ req_img: item.req_image });
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onloadend = () => {
        let string = reader.result;
        let spilitFile = string.split(",");
        let resultedString = spilitFile[1];
        reqimg1.push({ req_img: resultedString });
      };
    }

    setPost({ ...post, req_img: reqimg1 });
  };

  let temp = [];
  const Upload = () => {
    for (let i = 0; i < uploadedFiles.length; i++) {
      temp.push(getBase64(uploadedFiles[i]));
    }
    setOpen(!open);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(post, PostRequirementSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: PostRequirementSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const posts = { ...post };
    posts[input.name] = input.value;
    setPost(posts);
    setErrors(error);
  };

  const tempGSM = [];
  for (let index = 0; index < inputValues.length; index++) {
    tempGSM.push({
      req_gsm: inputValues[index],
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      const data = {
        ...post,
        user_id: account.user_id,
        token: account.token,
        usertype: account.user_type,
        pod: pod,
        postid: post.id !== "" ? post.id : "",
        printing: print,
        req_gsm: post.cat_name === "Paper" ? inputValues : [],
      };

      await postService.PostRequirement(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBases64(file);
  };

  const setImgPic = () => {
    setImageUrl(fileUrl);
    setPost({ ...post, img1: url });
    setUrl("");
  };

  const ImageUpload = () => {
    Img === "img1" ? setImgPic() : console.log("account");
  };

  const getBases64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    setFileUrl(null);
    setPost({ ...post, img1: null });
    setImageUrl(null);
  };

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  const PopulateForm = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleRequirement({
        user_id: "",
        token: "",
      });

      const array = collection.post_requirement_list;
      const data = await getService.getData(array, Id);
      setPost(mapToViewModel(data));
      setAccount({
        user_id: data.user_id,
        token: data.token,
        usertype: "Buyer",
      });
      setInputValues(data.req_gsm);
      setUploadedFiles(data.req_img);
      setCategoryShow(!categoryShow);
      setPrint(data.printing);
      setImageUrl(data.img1);
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ ...rest }) => ({ ...rest });

  useEffect(() => {
    PopulateForm();
  }, []);

  useEffect(() => {
    if (post.name !== "") {
      let data = users.find((u) => u.name === post.name);
      data === undefined
        ? setAccount({
            user_id: "",
            token: "",
            usertype: "",
          })
        : setAccount({
            user_id: data.id,
            token: data.token,
            usertype: data.user_type,
          });
    }
  }, [post.name]);

  const {
    name,
    description,
    cat_name,
    subcat_name,
    super_subcat_name,
    length,
    location,
    quantity,
    width,
    height,
    gazette,
    ply,
    flap,
    req_gsm,
    size,
    user_id,
    pincode,
    target_price,
    delivery_date,
    material_type_name,
    micron_type_name,
    style_name,
    no_of_color,
    bidding_date,
  } = post;

  return (
    <>
      <div className="mainContent" style={{ width: "100%" }}>
        <div className="topSection">
          <div className="headerSection flex">
            <div className="title">
              <PostReqContent data={services[2]} />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <Select
                label="Company Name"
                type="text"
                name="name"
                value={name}
                placeholder="Enter your company name"
                error={errors.name}
                onChange={handleChange}
                options={users}
              />
            </div>
            <div className="col-md-10 col-md-pull-2 mt-2">
              <Input
                label="Company Name"
                type="text"
                name="name"
                value={name}
                placeholder="Enter your company name"
                error={errors.name}
                onChange={handleChange}
                disabled
              />
            </div>

            <div className="col-md-10 col-md-pull-2 mt-2">
              <label for={description} className="form-label">
                Tell us more about your requirement. What product you will pack
                in this and what would be the weight of your product?
              </label>
              <textarea
                className="form-control"
                rows="5"
                name="description"
                value={description}
                placeholder="Describe your requirement here...."
                onChange={handleChange}
                error={errors.description}
              />
              <p className="td-words-linit">4000 character left.</p>
              {errors.description && (
                <p className="text-danger">{errors.description}</p>
              )}
            </div>

            <div className="col-md-10 col-md-pull-2 mt-2">
              <div className="row">
                <div className="col-md-6 mt-3">
                  <Input
                    label="Delivery Location"
                    type="text"
                    name="location"
                    value={location}
                    placeholder="Enter your location"
                    error={errors.location}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <Input
                    label="PIN Code"
                    type="number"
                    name="pincode"
                    value={pincode}
                    placeholder="000000"
                    error={errors.pincode}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-10 col-md-pull-2 mt-2">
              <button
                type="button"
                className="btn btn-next"
                style={{ display: categoryShow ? "none" : "block" }}
                onClick={() => setCategoryShow(!categoryShow)}
              >
                Next
              </button>
            </div>

            {categoryShow && (
              <>
                <div className="col-md-10 col-md-pull-2 mt-2">
                  <Categories
                    error={errors}
                    post={post}
                    setPost={setPost}
                    cat_name={cat_name}
                    subcat_name={subcat_name}
                    super_subcat_name={super_subcat_name}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}

            <div
              className="col-md-10 col-md-pull-2 mt-2"
              style={{ display: cat_name === "Paper" ? "block" : "none" }}
            >
              <div className="row">
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Length"
                    placeholder="Length"
                    name="length"
                    error={errors.length}
                    value={length}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Width"
                    placeholder="Width"
                    name="width"
                    value={width}
                    error={errors.width}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Height"
                    placeholder="Height"
                    name="height"
                    value={height}
                    error={errors.height}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Size"
                    name="size"
                    value={size}
                    options={Units}
                    error={errors.size}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Ply."
                    placeholder="Ply."
                    name="ply"
                    error={errors.ply}
                    value={ply}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <label
                    for="gsmPaper"
                    className="form-label"
                    style={{ margin: 0 }}
                  >
                    Choose GSM
                  </label>
                  <Gsm
                    ply={ply}
                    gsm={req_gsm}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Style"
                    name="style_name"
                    value={style_name}
                    error={errors.style_name}
                    options={BoxStyle}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="col-md-4 mt-3"><Select label="GSM" name="gsm" value={gsm} error={errors.gsm} options={GSMList} onChange={handleChange} /></div> */}
              </div>
            </div>

            <div
              className="col-md-10 col-md-pull-2 mt-2"
              style={{ display: cat_name === "Plastic" ? "block" : "none" }}
            >
              <div className="row">
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Length"
                    placeholder="Length"
                    name="length"
                    value={length}
                    error={errors.length}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Width"
                    placeholder="Width"
                    name="width"
                    value={width}
                    error={errors.width}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Gazette"
                    placeholder="Gazette"
                    name="gazette"
                    error={errors.gazette}
                    value={gazette}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Size"
                    name="size"
                    value={size}
                    options={Units}
                    error={errors.size}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Input
                    type="number"
                    label="Flap"
                    placeholder="Flap"
                    name="flap"
                    value={flap}
                    error={errors.flap}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Style"
                    name="style_name"
                    value={style_name}
                    options={PolyStyle}
                    error={errors.style_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Micron Type"
                    name="micron_type_name"
                    value={micron_type_name}
                    options={PolyMicronType}
                    error={errors.micron_type_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <Select
                    label="Material Type"
                    name="material_type_name"
                    value={material_type_name}
                    options={PolyMaterialType}
                    error={errors.material_type_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-md-4 mt-4">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="pod"> POD</label>
                      <diuv>
                        <Checkbox
                          label="Yes"
                          type="radio"
                          name="pod"
                          value="Yes"
                          onChange={() => setPod("Yes")}
                        />
                        <Checkbox
                          label="No"
                          type="radio"
                          name="pod"
                          value="No"
                          onChange={() => setPod("No")}
                        />
                      </diuv>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-10 col-md-pull-2 mt-2">
            <div className="row">
              <div className="col-md-3 mt-3">
                <Input
                  label="Quantity"
                  type="number"
                  name="quantity"
                  value={quantity}
                  placeholder="Quantity"
                  error={errors.quantity}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {categoryShow && (
            <>
              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <Input
                      type="date"
                      label="Bidding Date"
                      name="bidding_date"
                      error={errors.bidding_date}
                      value={bidding_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <Input
                      type="date"
                      label="Delivery Date"
                      name="delivery_date"
                      error={errors.delivery_date}
                      value={delivery_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-5 mt-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Upload Image
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      name="img1"
                      label="Img1"
                      accept="image/png, image/jpeg"
                      onChange={onImageChange}
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="col-sm-12 mt-3 d-flex flex-column">
                      <label for="exampleFormControlInput1" class="form-label">
                        Image
                      </label>
                      {imageUrl && (
                        <div className="img-admin-upload">
                          <MdCancel
                            onClick={removeImg}
                            color="red"
                            size={25}
                            className="icon"
                          />
                          <img
                            src={imageUrl}
                            style={{ width: "100px", height: "100px" }}
                            alt="Preview"
                          />
                        </div>
                      )}
                      {!imageUrl && (
                        <img
                          src={upload}
                          style={{ width: "100px", height: "100px" }}
                          alt="Preview"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <label htmlFor="printing"> PRINT</label>
                      <div>
                        <Checkbox
                          label="Yes"
                          type="radio"
                          name="printing"
                          value="Yes"
                          onChange={() => setPrint("Yes")}
                        />
                        <Checkbox
                          label="No"
                          type="radio"
                          name="printing"
                          value="No"
                          onChange={() => setPrint("No")}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 mt-3"
                    style={{ display: print === "Yes" ? "block" : "none" }}
                  >
                    <Input
                      type="number"
                      label="No Of Colours"
                      placeholder="No Of Colours"
                      name="no_of_color"
                      error={errors.no_of_color}
                      value={no_of_color}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="col-md-10 col-md-pull-2 mt-2">
            <div
              className="attach-box"
              style={{ display: print === "Yes" ? "block" : "none" }}
            >
              <input
                ref={inputRef}
                type="file"
                accept="image/png, image/jpeg"
                multiple
                style={{ display: "none" }}
                onChange={handleFileEvent}
                disabled={fileLimit}
              />
              <button
                type="button"
                className="btn btn-attach"
                onClick={() => inputRef.current.click()}
              >
                <i className="fa fa-cloud-upload"></i> Attach Files
              </button>
              <p>
                Drag & drop any images or documents that might be helpful in
                explaining your brief here (Max file size: 25 MB).
              </p>
            </div>

            <div className="table-responsive">
              <table className="table table-attach" width="100%">
                <tbody>
                  {uploadedFiles.map((item, index) => (
                    <tr>
                      <td>
                        <i className="fa fa-file" />
                      </td>
                      <td>
                        {item.req_image === undefined
                          ? "dff"
                          : item.req_image.split("/").pop()}
                      </td>
                      <td>
                        {" "}
                        {formatDuration(item.size) === undefined
                          ? formatDuration(item.size)
                          : ""}{" "}
                        {}
                      </td>
                      <td>
                        <span onClick={() => removeFile(index)}>
                          <i className="fa fa-times" />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <input
              ref={inputRef}
              type="file"
              accept="image/png, image/jpeg"
              multiple
              style={{ display: "none" }}
              onChange={handleFileEvent}
              disabled={fileLimit}
            />
          </div>

          {categoryShow && (
            <>
              <div className="col-md-10 col-md-pull-2 mt-2">
                <button
                  className="btn btn-next"
                  type="button"
                  style={{ display: checkoutShow ? "none" : "flex" }}
                  onClick={() => setCheckoutShow(!checkoutShow)}
                >
                  {" "}
                  Next{" "}
                </button>
              </div>
            </>
          )}

          {checkoutShow && (
            <>
              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="topSection">
                  <div className="headerSection flex">
                    <div className="title">
                      <h1>What type of requirement are you posting?</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="row">
                  <div className="col-md-4 mt-3">
                    <img
                      src={PackagingandDesign}
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <h5>{name}</h5>
                    <p>{description}.</p>
                    <h6>Categories : </h6>
                    <Badge bg="warning" text="dark" style={{ margin: "5px" }}>
                      {cat_name}
                    </Badge>
                    <Badge bg="success" style={{ margin: "5px" }}>
                      {subcat_name}
                    </Badge>
                    <Badge bg="info" style={{ margin: "5px" }}>
                      {super_subcat_name}
                    </Badge>
                  </div>

                  <div className="col-md-4 mt-3">
                    <h5>
                      <u>Measurements</u>
                    </h5>
                    <div className="row">
                      <div className="col-md-6">
                        <p>Length - {length} </p>
                      </div>
                      <div className="col-md-6">
                        <p>Width - {width} </p>
                      </div>

                      {cat_name === "Paper" && (
                        <>
                          <div className="col-md-6">
                            <p>Height - {height} </p>
                          </div>
                          <div className="col-md-6">
                            <p>Ply - {ply} </p>
                          </div>
                        </>
                      )}

                      {cat_name === "Plastic" && (
                        <>
                          <div className="col-md-6">
                            <p>Gazette - {gazette} </p>
                          </div>
                          <div className="col-md-6">
                            <p>Flap - {flap} </p>
                          </div>
                        </>
                      )}

                      <div className="col-md-6">
                        <p>Style - {style_name} </p>
                      </div>

                      {cat_name === "Plastic" && (
                        <>
                          <div className="col-md-12">
                            <p>Material Type - {material_type_name} </p>
                          </div>
                          <div className="col-md-12">
                            <p>Micron Type - {micron_type_name} </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="alert-box">
                  <p>
                    Your requirement will be sent live immediately, but you will
                    not be charged yet.
                  </p>
                </div>
              </div>

              <div className="col-md-10 offset-md-7 mt-2">
                <div className="buttons flex">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={Upload}
                  >
                    Yes, post my requirement
                  </button>
                  <PostReq
                    open={open}
                    setOpen={setOpen}
                    value={target_price}
                    onChange={handleChange}
                    onValidate={validate}
                    errors={errors}
                  />
                </div>
              </div>

              <hr />

              <div className="col-md-10 col-md-pull-2 mt-2">
                <div className="topSection">
                  <div className="headerSection flex">
                    <div className="title">
                      <p>
                        By clicking 'Yes, post my requirement' , you agree to
                        the
                        <Link> Terms & Conditions </Link> and
                        <Link> Privacy Policy </Link>
                        <br />
                        Copyright © 2023 Onclq Pty Limited
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div></div>
        </form>
      </div>
    </>
  );
};

export default AdminRequirementForm;
