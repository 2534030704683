import React, { useState } from "react";

// imported icons ===========>
import { AiFillHeart } from "react-icons/ai";
import { BsArrowRightShort } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";

// imported Images ===========>
import BarChart from "../../../Chart/BarChart";
import LineChart from "./../../../Chart/LineChart";
import PieChart from "./../../../Chart/PieChart";
import { UserData } from "./../../../../data/Userdata";
import OutofStockData from "./OutofStockData";
import TopSellingSKUData from "./TopSellingSKUData";
import { RatingData } from "./../../../../data/RatingData";
import { SaleData } from "./../../../../data/SaleData";
import { OrderData } from "./../../../../data/OrderData";
import NewOrderData from "./NewOrderData";

const Graphs = ({ myAccount }) => {
  const [orderData, setOrderData] = useState({
    labels: OrderData.map((data) => data.year),
    datasets: [
      {
        label: "Order Chart",
        data: OrderData.map((data) => data.order),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [saleData, setSaleData] = useState({
    labels: SaleData.map((data) => data.year),
    datasets: [
      {
        label: "Sale Chart",
        data: SaleData.map((data) => data.sale),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [ratingData, setRatingData] = useState({
    labels: RatingData.map((data) => data.title),
    datasets: [
      {
        label: "Rating",
        data: RatingData.map((data) => data.rating),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  });
  return (
    <>
      <div className="lisitingSection">
        <div className="heading flex">
          <h1
            style={{ width: "100%", textAlign: "center", marginTop: "0.5rem" }}
          >
            My Progress
          </h1>
          {/* <button className='btn flex'>
          See All <BsArrowRightShort className="icon"/>
        </button> */}
        </div>

        <div className="body flex">
          <div className="row w-100">
            {/* <div className="col-md-6">
              <div className="chart pb-3">
                <h5>Sale Chart</h5>
                <div style={{ width: 450 }}>
                  <LineChart chartData={saleData} />
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="chart pb-3">
                <h5>Order Chart</h5>
                <div style={{ width: 450 }}>
                  <LineChart chartData={orderData} />
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="chart pb-3">
                <h5>Ratings</h5>
                <div style={{ width: 450 }}>
                  <BarChart chartData={ratingData} />
                </div>
              </div>
            </div> */}

            <div className="col-md-6">
              <div className="chart pb-3">
                <h5>New Order</h5>
                <NewOrderData myAccount={myAccount} />
              </div>
            </div>

            {/* <div className="col-md-6">
              <div className="chart pb-3">
                <h5>Out of Stock</h5>
                <OutofStockData />
              </div>
            </div>

            <div className="col-md-6">
              <div className="chart pb-3">
                <h5>Top Selling SKU</h5>
                <TopSellingSKUData />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Graphs;
