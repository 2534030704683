import React from "react";
import { Link } from "react-router-dom";

const Section = ({ users, categories }) => {
  if (!users || !categories) return null;

  return (
    <>
      <div class="se-pre-con"></div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="small-box bg-green whitecolor">
            <div class="inner">
              <h4>
                <span class="count-number">{categories.length}</span>
              </h4>
              <p>All Products</p>
            </div>
            <div class="icon">
              <i class="fa fa-users"></i>
            </div>
            <Link to="#" class="small-box-footer">
              Total Products
            </Link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="small-box bg-pase whitecolor">
            <div class="inner">
              <h4>
                <span class="count-number">
                  {users.filter((u) => u.user_type === "Buyer").length}
                </span>
              </h4>
              <p>All Buyer</p>
            </div>
            <div class="icon">
              <i class="fa fa-shopping-bag"></i>
            </div>
            <Link to="#" class="small-box-footer">
              Total Buyer
            </Link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="small-box bg-bringal whitecolor">
            <div class="inner">
              <h4>
                <span class="count-number">
                  {users.filter((u) => u.user_type === "Seller").length}
                </span>
              </h4>
              <p>All Seller</p>
            </div>
            <div class="icon">
              <i class="fa fa-user"></i>
            </div>
            <Link to="#" class="small-box-footer">
              Total Seller{" "}
            </Link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div class="small-box bg-darkgreen whitecolor">
            <div class="inner">
              <h4>
                <span class="count-number">{users.length}</span>{" "}
              </h4>
              <p>All Users</p>
            </div>
            <div class="icon">
              <i class="fa fa-money"></i>
            </div>
            <Link to="#" class="small-box-footer">
              Total Users{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
