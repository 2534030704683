import React, { useState, useEffect } from "react";
import Section from "./Section";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [categories, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    const user = await getService.handleUserList();
    setUsers(user);
    setLoading(true);
  };

  const fetchCategory = async () => {
    const category = await getService.handleCategoryList();
    const filter = category.filter((f) => f.title !== null);
    setCategory(filter);
    setLoading(true);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  if (loading === false) return <Loader />;

  return <Section users={users} categories={categories} />;
};

export default Dashboard;
