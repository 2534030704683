import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "./../../../common/input";
import getService from "../../../services/getService";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import Select from "./../../../common/select";

const SubCategoriesForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [account, setAccount] = useState({
    id: "",
    cat_id: "",
    cat_name: "",
    title: "",
  });

  const [categoryList, setCategoryList] = useState([]);
  const fetchCategoryList = async () => {
    const category = await getService.handleCategoryList();
    const newCategory = category.map(({ title: name, ...rest }) => ({
      name,
      ...rest,
    }));
    const filter = newCategory.filter((f) => f.name !== null || "");
    setCategoryList(filter);
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await postService.PostSubCategory(account);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateCategories = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleSubCategoryList();
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };
  const mapToViewModel = ({ ...rest }) => ({ ...rest });

  useEffect(() => {
    PopulateCategories();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
  };

  const { title, cat_name } = account;

  useEffect(() => {
    if (cat_name) {
      const copiedList = [...categoryList];
      const data = copiedList.find((c) => c.name === cat_name);
      setAccount({ ...account, cat_id: data.id });
    }
  }, [cat_name, categoryList]);

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Add/Update Sub Category </h4>
              </div>
            </div>
            <div class="panel-body">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div className="col-md-6">
                    <Select
                      label="Category"
                      name="cat_name"
                      value={cat_name}
                      options={categoryList}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="title"
                      label="Title"
                      value={title}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-1 offset-md-11">
                    <button type="submit" className="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategoriesForm;
