import React, { useState, useEffect } from "react";
import Input from "./input";

const Gsm = ({ ply, inputValues, setInputValues, ...props }) => {
  const [open, setOpen] = useState(false);
  const [gsmlist, setGsmList] = useState(undefined);
  const [buttonText, setButtonText] = useState("Select GSM");

  const handleGSMOpen = () => {
    setOpen(!open);
  };

  const handleGSMClose = () => {
    setOpen(!open);
    setButtonText("View GSM");
  };

  const handleChange = (e, i) => {
    let newValues = [...inputValues];
    newValues[i] = e.target.value;
    setInputValues(newValues);

    if (gsmlist) {
      let newGsmList = [...gsmlist];
      newGsmList[i] = { ...newGsmList[i], req_gsm: e.target.value };
      setGsmList(newGsmList);
      setInputValues(newGsmList);
    }
  };
  useEffect(() => {
    if (props.gsm !== undefined) {
      setGsmList(props.gsm);
    }
  }, [props.gsm, ply]);

  return (
    <>
      <button
        type="button"
        style={{ width: "100%", textAlign: "inherit" }}
        className="btn btn-outline-dark"
        onClick={handleGSMOpen}
      >
        {buttonText}
      </button>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                GSM
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div className="modal-body">
              {(Number(ply) === 0 || ply === "") && (
                <>
                  <h5>Select the Ply First</h5>
                </>
              )}
              {Number(ply) !== 0 && ply !== "" && (
                <>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered createfield"
                      id="supplierList"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>SL.</th>
                          <th style={{ textAlign: "center" }}>GSM Value</th>
                        </tr>
                      </thead>
                      <tbody id="supplier_tablebody">
                        {Array.from({ length: ply }, (_, i) => (
                          <>
                            <tr role="row" className="odd">
                              <td style={{ textAlign: "center" }} tabindex="0">
                                {i + 1}
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                tabindex="1"
                              >
                                <Input
                                  type="number"
                                  placeholder="Gsm Value"
                                  name="gsmValue"
                                  value={
                                    props.gsm === undefined
                                      ? inputValues[i]
                                      : gsmlist === undefined
                                      ? ""
                                      : gsmlist[i]?.req_gsm === undefined
                                      ? ""
                                      : gsmlist[i].req_gsm
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpen(!open)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleGSMClose}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gsm;
