import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "./../../../common/input";
import getService from "../../../services/getService";
import { toast } from "react-toastify";
import upload from "../../../../components/assets/images/upload.jpg";
import { MdCancel } from "react-icons/md";
import { SubAdminData } from "./../../../data/SubAdminData";
import Joi from "joi-browser";
import { SubAdminSchema } from "./../../../data/SubAdminSchema";
import auth from "../../../services/authService";

const SubAdminForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [Img, setImg] = useState("");
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [account, setAccount] = useState(SubAdminData);
  const [imageUrl, setImageUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const [modules, setModules] = useState([]);
  const Id = params.id;

  const fetchModules = async (account) => {
    const data = await getService.handleModules();
    console.log("account", account.req_module);

    if (account.req_module.length > 0) {
      const updatedArray1 = data.map((item1) => {
        const matchedItem2 = account.req_module.find(
          (item2) => item2.title === item1.title
        );
        if (matchedItem2) {
          return { ...item1, check: true };
        }
        return { ...item1, check: false };
      });

      setModules(updatedArray1);
    } else {
      const newArray = data.map((item) => {
        return { ...item, check: false };
      });

      setModules(newArray);
    }
  };

  const PopulateBanner = async () => {
    try {
      if (Id === "new") return;

      const collection = await getService.handleAdmin();
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
      setImageUrl(data.img1);
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ ...rest }) => ({ ...rest });

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, SubAdminSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: SubAdminSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    console.log("error", errors);
    if (errors) return;

    const filteredArray = modules
      .filter((item) => item.check === true)
      .map(({ id }) => ({ req_module: id }));

    const data = { ...account, module: filteredArray };

    try {
      await auth.PostSubAdmin(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const setImgPic = () => {
    setImageUrl(fileUrl);
    setAccount({ ...account, img1: url });
    setUrl("");
  };

  const ImageUpload = () => {
    Img === "img1" ? setImgPic() : console.log("account");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    setFileUrl(null);
    setAccount({ ...account, img1: null });
    setImageUrl(null);
  };

  function handleClick(index) {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules[index] = {
        ...updatedModules[index],
        check: !updatedModules[index].check,
      };
      return updatedModules;
    });
  }

  const {
    username,
    password,
    full_name,
    email,
    mobile,
    address,
    type,
    fb_url,
    twitter_url,
    instagram_url,
    google_url,
    youtube_url,
    linkedin_url,
  } = account;

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  useEffect(() => {
    if (account) fetchModules(account);
  }, [account]);

  useEffect(() => {
    PopulateBanner();
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Add/Update Form </h4>
              </div>
            </div>
            <div class="panel-body">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div className="col-md-6">
                    <Input
                      name="username"
                      label="Username"
                      value={username}
                      error={errors.username}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      name="password"
                      label="Password"
                      value={password}
                      type="password"
                      error={errors.password}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      name="full_name"
                      label="Full Name"
                      value={full_name}
                      error={errors.full_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="type"
                      label="Type"
                      value={type}
                      error={errors.type}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      name="email"
                      label="Email"
                      value={email}
                      error={errors.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      name="mobile"
                      label="Mobile"
                      value={mobile}
                      error={errors.mobile}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <label for="address" className="form-label">
                      Address{" "}
                    </label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="address"
                      value={address}
                      placeholder="Enter Address here...."
                      onChange={handleChange}
                    />
                    <p className="td-words-linit">4000 character left.</p>
                    {errors.address && (
                      <p className="text-danger">{errors.address}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Input
                      name="fb_url"
                      label="Facebook"
                      value={fb_url}
                      error={errors.fb_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      name="twitter_url"
                      label="Twitter"
                      value={twitter_url}
                      error={errors.twitter_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      name="instagram_url"
                      label="Instagram"
                      value={instagram_url}
                      error={errors.instagram_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      name="google_url"
                      label="Google"
                      value={google_url}
                      error={errors.google_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      name="youtube_url"
                      label="Youtube"
                      value={youtube_url}
                      error={errors.youtube_url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      name="linkedin_url"
                      label="LinkedIn"
                      value={linkedin_url}
                      error={errors.linkedin_url}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Upload Profile Image
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      name="img1"
                      label="Img1"
                      accept="image/png, image/jpeg"
                      onChange={onImageChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="col-sm-12 mt-3 d-flex flex-column">
                      <label for="exampleFormControlInput1" class="form-label">
                        Image
                      </label>

                      {imageUrl && (
                        <div className="img-admin-upload">
                          <MdCancel
                            onClick={removeImg}
                            color="red"
                            size={25}
                            className="icon"
                          />
                          <img
                            src={imageUrl}
                            style={{ width: "100px", height: "100px" }}
                            alt="Preview"
                          />
                        </div>
                      )}
                      {!imageUrl && (
                        <img
                          src={upload}
                          style={{ width: "100px", height: "100px" }}
                          alt="Preview"
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Assigned Modules
                        </label>
                      </div>
                      {modules.map((module, index) => (
                        <>
                          <div className="col-md-4" key={module.id}>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={module.check}
                                onChange={() => handleClick(index)}
                              />

                              <label
                                for="flexCheckDefault"
                                class="form-check-label"
                              >
                                {module.title}
                              </label>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>

                  <div class="col-md-1 offset-md-11">
                    <button type="submit" className="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubAdminForm;
