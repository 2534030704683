import React, { useState } from "react";
import Input from "./input";
import postService from "../services/postService";
import { toast } from "react-toastify";
import { ViewRequirementsData } from "../data/ViewRequirementsData";

const AwardReq = ({ userType, myAccount, item }) => {
  const [open, setOpen] = useState(false);
  const BuyerModel = () => {
    const [award, setAward] = useState({
      remarks: "",
    });

    const handleChange = ({ currentTarget: input }) => {
      const awards = { ...award };
      awards[input.name] = input.value;
      setAward(awards);
    };

    const AwardBid = async (e) => {
      e.preventDefault();
      try {
        const res = await postService.PostAward({
          bid_id: item.id,
          bid_amount: item.price,
          user_id: myAccount.id,
          token: myAccount.token,
          post_requirement_id: item.post_requirement_id,
          seller_id: item.user_id,
          remarks: remarks,
        });
        if (res === "Bid awarded Successfully") {
          window.location.reload();
        }
      } catch (error) {
        toast.error(error);
      }
    };

    console.log("item", item);

    const { remarks } = award;

    return (
      <>
        <form onSubmit={AwardBid}>
          <div className="modal-body">
            <h5>Are you sure to award this bid ??</h5>
            <div className="row">
              <div className="col-md-12 mt-2">
                <label for="bid_specification" className="form-label">
                  Any Remarks you want to add.
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="remarks"
                  value={remarks}
                  placeholder="Your Remarks here...."
                  onChange={handleChange}
                />
                <p className="td-words-linit">4000 character left.</p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setOpen(!open)}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Yes, award
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      {item.status === "Active" && (
        <>
          <button
            type="button"
            className="btn btn-warning btn-sm"
            onClick={() => setOpen(!open)}
          >
            {" "}
            <i className="fa fa-trophy" />
          </button>
          <div
            className={open ? "modal fade show" : "modal fade"}
            style={{ display: open ? "block" : "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Status
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setOpen(!open)}
                  ></button>
                </div>
                <BuyerModel />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AwardReq;
