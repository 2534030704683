import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const StarRating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <div>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;

        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {i === 0 && "Very Bad"}
                  {i === 1 && "Bad"}
                  {i === 2 && "Good"}
                  {i === 3 && "Very Good"}
                  {i === 4 && "Excellent"}
                </Tooltip>
              }
            >
              <button
                className="starRating"
                onClick={() => setRating(ratingValue)}
              >
                <input type="radio" name="rating" value={ratingValue} />
                <FaStar
                  className="star"
                  color={
                    ratingValue <= (hover || rating) ? "#ffc107" : "e4e5e9"
                  }
                  size={20}
                  onMouseOver={() => setHover(ratingValue)}
                  onMouseOut={() => setHover(null)}
                />
              </button>
            </OverlayTrigger>
          </>
        );
      })}
    </div>
  );
};

export default StarRating;
