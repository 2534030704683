import React, { useState } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Input from "./../../common/input";
import Joi from "joi-browser";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import thinking from "../../assets/images/thinking.gif";

const ForgotPassword = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();

  const [account, setAccount] = useState({
    email: "",
  });
  const [isVerified, setVerified] = useState(true);

  const [errors, setErrors] = useState({});
  const credStep = "Forgot Password";

  const schema = {
    email: Joi.string().required().label("Email"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      await auth.forgot_password(account, credStep);
    } catch (error) {
      toast.error(error);
    }
  };

  const onCaptcha = (value) => {
    console.log("Captcha value:", value);
    value !== null ? setVerified(false) : setVerified(true);
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const { email } = account;

  return (
    <>
      <section className="new_login_panel d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-sm-12">
                <form className="app-form" onSubmit={handleSubmit}>
                  <h4>Umm, Forgot your Password.</h4>
                  <p>Don't Worry</p>

                  <Input
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    label="Email"
                    error={errors.email}
                    value={email}
                    onChange={handleChange}
                  />
                  <ReCAPTCHA
                    sitekey="6Lf2lfckAAAAADpbqtaeW9YDWj9yO-5kF8p6_Jsz"
                    onChange={onCaptcha}
                  />

                  <button
                    type="submit"
                    className="btn app-login-button"
                    disabled={isVerified}
                  >
                    Send a link
                  </button>
                </form>
              </div>
              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="login_right_sec">
                  <div className="app-form-logo">
                    <Link to="/">
                      <img
                        alt="Onclq logo"
                        class="LogoImg"
                        src={appLogo}
                        data-size="mid"
                      />
                    </Link>
                  </div>
                  <div className="app-packaging-logo">
                    <img
                      alt="Onclq.com"
                      class="PackagingImg"
                      src={thinking}
                      data-size="mid"
                    />
                  </div>
                  <div className="right_login_con">
                    <p>Your presence from all across the Internet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
