import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Delete from "./../../../common/delete";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import BidList from "./BidList";
import postService from "../../../services/postService";
import SearchBox from "./../../../common/searchBox";

const AdminRequirementTable = () => {
  const [requirements, setRequirements] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);

  const fetchRequirement = async () => {
    const list = await getService.handleRequirement({ user_id: "", token: "" });
    const data = list.post_requirement_list.map(
      ({ target_price: price, ...rest }) => ({
        price,
        ...rest,
      })
    );
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.created_date);
      const date2 = new Date(b.created_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(
    //   (filter) => filter.status === "Active"
    // );
    setRequirements(sortedDateArray);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeletePostRequirements({ id });
      setCurrentPage(1);
      fetchRequirement();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const handleBid = async (data) => {
    try {
      await postService.PostBid(data);
      setCurrentPage(1);
      fetchRequirement();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    let filtered = requirements;
    if (searchQuery)
      filtered = requirements.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else filtered = requirements;

    const requirement = paginate(filtered, currentPage, pageSize);
    return { totalCount: requirements.length, data: requirement };
  };

  const { length: count } = requirements;

  const { totalCount, data: requirement } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (requirement) => <p>{requirements.indexOf(requirement) + 1}</p>,
    },
    { path: "name", label: "Name" },
    { path: "description", label: "Description" },
    {
      path: "orderstatus",
      label: "Status",
      content: (user) => (
        <span className="badge text-bg-secondary">{user.status}</span>
      ),
    },
    {
      key: "Action",
      label: "Action",
      content: (requirement) => (
        <>
          <BidList
            id={id}
            item={requirement}
            setId={setId}
            onClick={handleBid}
          />
          <button
            class="btn btn-success btn-xs m-b-5 custom_btn"
            data-toggle="tooltip"
            data-placement="left"
            title="Update"
            onClick={() => {
              navigate(`/admin/requirementsform/${requirement.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={requirement.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchRequirement();
  }, []);

  if (count === 0) return <p>There are no orders in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Requirement List </h4>
              </div>
              <CSVLink data={requirements} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <SearchBox value={searchQuery} onChange={handleSearch} />
              </div>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={requirement} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default AdminRequirementTable;
