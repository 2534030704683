import React from "react";

const Address = ({ data }) => {
  if (!data) return null;
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: data.address }} />
      <p>
        Whatsapp on this number :
        <a href={`https://wa.me/${data.mobile}/?text=Hi Onclq`}>
          {data.mobile}
        </a>
      </p>
    </>
  );
};

export default Address;
