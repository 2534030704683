import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Delete from "./../../../common/delete";
import NoImage from "../../../assets/images/NoImage.png";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const AdminSubscriptionTable = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [id, setId] = useState(null);

  const fetchSubscriptions = async () => {
    const category = await getService.handleCategory();
    setSubscriptions(category);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteSubscription({ id });
      setCurrentPage(1);
      fetchSubscriptions();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    const subscription = paginate(subscriptions, currentPage, pageSize);
    return { totalCount: subscriptions.length, data: subscription };
  };

  const { length: count } = subscriptions;

  const { totalCount, data: subscription } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (subscription) => (
        <p>{subscriptions.indexOf(subscription) + 1}</p>
      ),
    },
    { path: "title", label: "Title" },
    { path: "business_bandwidth_title", label: "Business Bandwidth Title" },
    { path: "business_bandwidth_value", label: "Business Bandwidth Value" },
    { path: "price_per_month", label: "Your Per Month Spent" },
    { path: "price_three_month", label: "3 Months Validity" },
    {
      path: "img1",
      label: "Logo",
      content: (c) => (
        <>
          {c.img1 !== null ? (
            <img
              style={{ width: "100px", height: "100px" }}
              src={c.img1}
              alt="Onclq.com"
            />
          ) : (
            <img
              style={{ width: "100px", height: "100px" }}
              src={NoImage}
              alt="Onclq.com"
            />
          )}
        </>
      ),
    },

    {
      key: "Action",
      label: "Action",
      content: (subscription) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/subscriptionform/${subscription.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={subscription.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  if (count === 0) return <p>There are no orders in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Subscriptions List </h4>
              </div>
              <CSVLink data={subscription} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={subscription} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSubscriptionTable;
