import React, {useState} from 'react'
import { SignUpData } from '../../../data/signUpData';
import ImagesSeller from '../../Card/Forms/ImagesSeller'
import Top from './Top Section/Top'

const SellerImages = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <>
      <div className='mainContent'>
      <Top/>
      <div className='bottom flex'>
      <ImagesSeller account={account} setAccount={setAccount} myAccount={myAccount} />
      </div>
    </div>
    </>
  )
}

export default SellerImages