import React, { useState, useEffect } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import googlePlayStore from "../../assets/form/google-play-store-badge.webp";
import appleAppStore from "../../assets/form/apple-app-store-badge.webp";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Input from "./../../common/input";
import Joi from "joi-browser";
import axios from "axios";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import otpgif from "../../assets/images/otp.gif";
import ReCAPTCHA from "react-google-recaptcha";

const Authorization = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();

  const [account, setAccount] = useState({
    email: "",
    otp: "",
  });

  const [isVerified, setVerified] = useState(true);

  const [errors, setErrors] = useState({});
  let credStep = "";

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    otp: Joi.number().required().min(0).max(9999).label("One Time Password"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      await auth.verify_otp(account, credStep);
    } catch (error) {
      toast.error(error);
    }
  };

  const onCaptcha = (value) => {
    console.log("Captcha value:", value);
    value !== null ? setVerified(false) : setVerified(true);
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const { otp } = account;

  useEffect(() => {
    account.email = auth.getCredUser().credEmailKey;
    credStep = auth.getCredUser().credStepKey;
  });

  if (
    (auth.getCredUser().credEmailKey && auth.getCredUser().credStepKey) === null
  )
    window.location = "/signup";

  return (
    <>
      <section className="new_login_panel d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-sm-12">
                <form className="app-form" onSubmit={handleSubmit}>
                  <h4>Verify Your Email.</h4>
                  <p>We have sent OTP on your given email.</p>
                  <Input
                    type="text"
                    placeholder="One-Time-Password"
                    name="otp"
                    label="Please Enter OTP"
                    error={errors.otp}
                    value={otp}
                    onChange={handleChange}
                  />
                  <ReCAPTCHA
                    sitekey="6Lf2lfckAAAAADpbqtaeW9YDWj9yO-5kF8p6_Jsz"
                    onChange={onCaptcha}
                  />

                  <button
                    type="submit"
                    className="btn app-login-button"
                    disabled={isVerified}
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="login_right_sec">
                  <div className="app-form-logo">
                    <Link to="/">
                      <img
                        alt="Onclq logo"
                        class="LogoImg"
                        src={appLogo}
                        data-size="mid"
                      />
                    </Link>
                  </div>
                  <div className="app-packaging-logo">
                    <img
                      alt="Onclq.com"
                      class="PackagingImg"
                      src={otpgif}
                      data-size="mid"
                    />
                  </div>
                  <div className="right_login_con">
                    <p>Your presence from all across the Internet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Authorization;
