import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import Input from "../../../common/input";
import Select from "./../../../common/select";
import { TypeofPlant } from "./../../../data/TypeofPlant";
import { PlantSchema } from "./../../../data/PlantSchema";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import upload from "../../../../components/assets/images/upload.jpg";

const PlantDetails = ({
  account,
  setAccount,
  currentStep,
  myAccount,
  updateStep,
}) => {
  const [Img, setImg] = useState("");
  const [plantUrl, setPlantUrl] = useState(account.plant_pic);
  const [machineUrl, setMachineUrl] = useState(account.machine_pic);
  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState({});
  const params = useParams();
  const [fileUrl, setFileUrl] = useState(null);

  const navigate = useNavigate();

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, PlantSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: PlantSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "plant",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      console.log("data", data);
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulatePlant = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    PopulatePlant();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const handlePlant = (item) => {
    plantRef.current.click();
  };

  const handleMachine = (item) => {
    machineRef.current.click();
  };

  const setPlantPic = () => {
    setPlantUrl(fileUrl);
    setAccount({ ...account, plant_pic: url });
    setUrl("");
  };

  const setMachinePic = () => {
    setMachineUrl(fileUrl);
    setAccount({ ...account, machine_pic: url });
    setUrl("");
  };

  const ImageUpload = (url) => {
    Img === "plant_pic" ? setPlantPic() : console.log("account plant_pic");
    Img === "machine_pic" ? setMachinePic() : console.log("account plant_pic");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const {
    plant_type,
    plant_capacity,
    available_capacity,
    machinary_type,
    no_of_machine,
    printing_facility,
    lamination_facility,
    uv_coating,
    other_facility,
    signup_step,
  } = account;

  const plantRef = useRef();
  const machineRef = useRef();

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  return (
    <>
      <section className="personaldetails">
        <div className="container">
          <h3>Plant Details</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Select
                  name="plant_type"
                  label="Type of Plant"
                  options={TypeofPlant}
                  value={plant_type}
                  error={errors.plant_type}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="machinary_type"
                  label="Type of Machinery"
                  value={machinary_type}
                  error={errors.machinary_type}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6">
                <Input
                  name="plant_capacity"
                  label="Capacity of Plant"
                  value={plant_capacity}
                  error={errors.plant_capacity}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="available_capacity"
                  label="Available Capacity"
                  value={available_capacity}
                  error={errors.available_capacity}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="no_of_machine"
                  value={no_of_machine}
                  label="No of Machines"
                  error={errors.no_of_machine}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="printing_facility"
                  value={printing_facility}
                  label="Printing Facility"
                  error={errors.printing_facility}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="lamination_facility"
                  label="Lamintaion Facility"
                  value={lamination_facility}
                  error={errors.lamination_facility}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="uv_coating"
                  label="UV Coating"
                  value={uv_coating}
                  error={errors.uv_coating}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="other_facility"
                  label="Other Facility"
                  value={other_facility}
                  error={errors.other_facility}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Input
                  name="signup_step"
                  label="Signup_step"
                  value={signup_step}
                  onChange={handleChange}
                  disabled="true"
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6"> Plant Image</div>
                    <div className="col-md-6">
                      <div className="img-upload">
                        <div className="img">
                          {plantUrl && <img src={plantUrl} alt="plantPic" />}
                          {!plantUrl && <img src={upload} alt="plantPic" />}
                        </div>
                        <div className="upload-button">
                          <input
                            ref={plantRef}
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg"
                            name="plant_pic"
                            label="Upload Image"
                            type="file"
                            error={errors.plant_pic}
                            onChange={onImageChange}
                          />
                          <button
                            type="button"
                            className="btn btn-attach"
                            onClick={handlePlant}
                          >
                            <i className="fa fa-cloud-upload"></i> <br />
                            Upload Image
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6"> Machine Image</div>
                    <div className="col-md-6">
                      <div className="img-upload">
                        <div className="img">
                          {machineUrl && (
                            <img src={machineUrl} alt="machinePic" />
                          )}
                          {!machineUrl && <img src={upload} alt="machinePic" />}
                        </div>
                        <div className="upload-button">
                          <input
                            ref={machineRef}
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg"
                            name="machine_pic"
                            label="Upload Image"
                            type="file"
                            error={errors.machine_pic}
                            onChange={onImageChange}
                          />
                          <button
                            type="button"
                            className="btn btn-attach"
                            onClick={handleMachine}
                          >
                            <i className="fa fa-cloud-upload"></i> <br />
                            Upload Image
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    type="button"
                    className="primaryButton"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    // disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>

                  <button
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    onClick={() => navigate("/membership")}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default PlantDetails;
