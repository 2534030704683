import axios from "axios";
import { toast } from "react-toastify";

async function PostSubscription(account) {
  console.log("account subscription", account);
  await axios
    .post("/api/insert_update_subscription", account)
    .then((res) => {
      console.log("account rea", res);
      if (res.data.msg === "No Record Found") {
        toast.info("No Record Found");
      } else if (
        res.data.subscription_data[0].msg ===
        "successfully updated subscription"
      ) {
        toast.success("Successfully Updated Subscription");
      } else if (
        res.data.subscription_data[0].msg ===
        "successfully inserted subscription"
      ) {
        toast.success("Successfully Inserted Subscription");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostRequirement(post) {
  console.log("post", post);
  await axios
    .post("/api/post_requirements", post)
    .then((res) => {
      console.log("post req api", res);
      if (res.data.msg === "successful inserterd requirements") {
        toast.success("Successful Inserterd Requirements");
        window.location = "/buyerdashboard/";
      } else if (res.data.msg === "successful updated requirements") {
        toast.success("Successful Updated Requirements");
        window.location = "/admin/requirementstable";
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostTracker(post) {
  console.log("post tracker", post);
  await axios
    .post("/api/createOrderTracker", post)
    .then((res) => {
      console.log("res", res);
      if (res.data.msg === "Order traker updated successfully") {
        toast.success("Order traker updated successfully");
        window.location = "/sellerdashboard/orders";
      } else if (res.data.msg === "Order id does not belongs to this seller") {
        toast.info("Order id does not belongs to this seller");
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function PostPayment(post) {
  console.log("payment post", post);
  await axios
    .post("/api/createPaymentTracker", post)
    .then((res) => {
      console.log("post req api", res);
      if (res.data.msg === "Payment traker updated successfully") {
        toast.success("Payment traker updated successfully");
        window.location = "/buyerdashboard/orders";
      } else if (res.data.msg === "Order id does not belongs to this seller") {
        toast.info("Order id does not belongs to this seller");
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostRequirementClose(post) {
  let msg = "";

  await axios
    .post("/api/close_requirement", post)
    .then((res) => {
      console.log("post req api", res);
      if (res.data.msg === "Your posted requirements have been closed") {
        toast.success("Your posted requirements have been closed");
        msg = "Your posted requirements have been closed";
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else if (
        res.data.msg === "User id and Post requirement is should be valid"
      ) {
        toast.info("User id and Post requirement is should be valid");
      } else if (res.data.msg === "Post requirement id should not be blank") {
        toast.info("Post requirement id should not be blank");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
  return msg;
}

async function PostAward(post) {
  let msg = "";

  await axios
    .post("/api/bid_award", post)
    .then((res) => {
      console.log("res", res);
      if (res.data.msg === "Bid awarded Successfully") {
        toast.success("Bid awarded Successfully");
        msg = "Bid awarded Successfully";
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else if (
        res.data.msg === "Bid Id, Requirement Id, Seller id should not be blank"
      ) {
        toast.info("Bid Id, Requirement Id, Seller id should not be blank");
      } else if (
        res.data.msg === "Requirement Id is not associate with Bid ID"
      ) {
        toast.info("Requirement Id is not associate with Bid ID");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
  return msg;
}

async function PostBid(bid) {
  console.log("bid", bid);
  await axios
    .post("/api/create_bid", bid)
    .then((res) => {
      console.log("res", res);
      if (res.data.msg === "successful updated bid") {
        toast.success("Successful updated bid");
        window.location = "/sellerdashboard/";
      } else if (res.data.msg === "successful submitted bid") {
        toast.success("Successful submitted Bid");
        window.location = "/sellerdashboard/";
      } else if (
        res.data.msg === "User already submitted bid for this requirements"
      ) {
        toast.error("User already submitted bid for this requirements");
      } else if (res.data.msg === "Token mismatch") {
        toast.info("Token mismatch");
      } else {
        console.log("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostCMS(account) {
  console.log("account", account);
  await axios
    .post("/api/update_cms", account)
    .then((res) => {
      if (res.data.msg === "ID or Description is missing") {
        toast.info("ID or Description is missing");
      } else if (res.data.msg === "cms updated successfully") {
        toast.success("Successfully Updated");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostServices(account) {
  await axios
    .post("/api/insert_update_services", account)
    .then((res) => {
      if (res.data.msg === "Title or Description is missing") {
        toast.info("Title or Description is missing");
      } else if (res.data.msg === "services updated successfully") {
        toast.success("Successfully Updated");
      } else if (res.data.msg === "services inserted successfully") {
        toast.success("Successfully Insertedt");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostBanner(account) {
  await axios
    .post("/api/create_home_banner", account)
    .then((res) => {
      if (res.data.msg === "Title or Description is missing") {
        toast.info("Title or Description is missing");
      } else if (res.data.msg === "Banner updated successfully") {
        toast.success("Banner Updated Successfully");
      } else if (res.data.msg === "Banner saved successfully") {
        toast.success("Banner Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostHeading(account) {
  await axios
    .post("/api/create_heading", account)
    .then((res) => {
      if (res.data.msg === "Title is missing") {
        toast.info("Title is missing");
      } else if (res.data.msg === "Headling updated successfully") {
        toast.success("Headling Updated Successfully");
      } else if (res.data.msg === "Headling saved successfully") {
        toast.success("Headling Saved successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostBusinessType(account) {
  await axios
    .post("/api/create_business_type", account)
    .then((res) => {
      if (res.data.msg === "Title is missing") {
        toast.info("Title is missing");
      } else if (res.data.msg === "Business Type updated successfully") {
        toast.success("Business Type Updated successfully");
      } else if (res.data.msg === "Business Type saved successfully") {
        toast.success("Business Type Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostSocial(account) {
  await axios
    .post("/api/update_social", account)
    .then((res) => {
      if (res.data.msg === "ID is missing") {
        toast.info("ID is missing");
      } else if (res.data.msg === "social link updated successfully") {
        toast.success("Social Link Updated Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostContent(account) {
  await axios
    .post("/api/create_update_content", account)
    .then((res) => {
      if (res.data.msg === "content updated successfully") {
        toast.info("Content Updated Successfully");
      } else if (res.data.msg === "content saved successfully") {
        toast.success("Content Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostContact(account) {
  console.log("conbtact account", account);
  await axios
    .post("/api/update_contact", account)
    .then((res) => {
      console.log("res", res);
      if (res.data.msg === "ID is missing") {
        toast.info("ID is missing");
      } else if (res.data.msg === "contacts updated successfully") {
        toast.success("Contacts Updated Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostClient(account) {
  await axios
    .post("/api/insert_update_client", account)
    .then((res) => {
      if (res.data.msg === "Title or Description is missing") {
        toast.info("Title or Description is missing");
      } else if (res.data.msg === "client updated successfully") {
        toast.success("Client Updated successfully");
      } else if (res.data.msg === "client inserted successfully") {
        toast.success("client Inserted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostCategory(account) {
  await axios
    .post("/api/update_category", account)
    .then((res) => {
      if (res.data.msg === "Title is Missing") {
        toast.info("Title is Missing");
      } else if (res.data.msg === "category updated successfully") {
        toast.success("Successfully Updated");
      } else if (res.data.msg === "category saved successfully") {
        toast.success("Category Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostSubCategory(account) {
  console.log("account", account);
  await axios
    .post("/api/create_update_subcategory", account)
    .then((res) => {
      if (res.data.msg === "Title is Missing") {
        toast.info("Title is Missing");
      } else if (res.data.msg === "sub category updated successfully") {
        toast.success("Sub Category Updated Successfully");
      } else if (res.data.msg === "sub category saved successfully") {
        toast.success("Sub Category Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function PostSupSubCategory(account) {
  console.log("account", account);
  await axios
    .post("/api/create_update_super_subcategory", account)
    .then((res) => {
      if (res.data.msg === "Title is Missing") {
        toast.info("Title is Missing");
      } else if (res.data.msg === "super sub category updated successfully") {
        toast.success("Super Sub Category Updated Successfully");
      } else if (res.data.msg === "super sub category saved successfully") {
        toast.success("Super Sub Category Saved Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostTestimonial(account) {
  await axios
    .post("/api/insert_update_testimonial", account)
    .then((res) => {
      if (
        res.data.msg === "Name, Deisgnation, Image or Description is missing"
      ) {
        toast.info("Name, Deisgnation, Image or Description is missing");
      } else if (res.data.msg === "testimonial updated successfully") {
        toast.success("Testimonial Updated successfully");
      } else if (res.data.msg === "testimonial inserted successfully") {
        toast.success("Testimonial Inserted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostHotProducts(account) {
  await axios
    .post("/api/insert_update_hotproduct", account)
    .then((res) => {
      if (res.data.msg === "Title or Description is missing") {
        toast.info("Title or Description is missing");
      } else if (res.data.msg === "product updated successfully") {
        toast.success("Product Updated Successfully");
      } else if (res.data.msg === "product inserted successfully") {
        toast.success("Product Inserted Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostSupport(account) {
  await axios
    .post("/api/create_update_support", account)
    .then((res) => {
      if (res.data.msg === "Title is Missing") {
        toast.info("Title is Missing");
      } else if (res.data.msg === "support updated successfully") {
        toast.success("Support Updated Successfully");
      } else if (res.data.msg === "support saved successfully") {
        toast.success("Support Inserted Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostRating(account) {
  await axios
    .post("/api/buyer_seller_rating", account)
    .then((res) => {
      if (res.data.msg === "User type is required.") {
        toast.info("User type is required.");
      } else if (res.data.msg === "Seller Rated Successfully") {
        toast.success("Seller Rated Successfully");
      } else if (res.data.msg === "Buyer Rated Successfully") {
        toast.success("Buyer Rated Successfully");
      } else {
        toast.error("Token mismatch");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostInvoice(account) {
  console.log("account", account);
  await axios
    .post("/api/create_invoice", account)
    .then((res) => {
      console.log("response", res);
      if (res.data.msg === "Invoice Title is missing") {
        toast.info("Invoice Title is missing");
      } else if (res.data.msg === "Successfully Inserterd Invoice") {
        toast.success("Successfully Inserterd Invoice");
        window.location = "/sellerdashboard/Orders";
      } else if (res.data.msg === "Successfully Updated Invoice") {
        toast.success("Successfully Updated Invoice");
        window.location = "/sellerdashboard/Orders";
      } else {
        toast.error("Token mismatch");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function PostMessage(message) {
  console.log("message", message);
  await axios
    .post("/api/create_helpdesk", message)
    .then((res) => {
      console.log("res", res);
    })
    .catch((err) => toast.error(err.message));
}

async function PostGetMessage(message) {
  return await axios
    .post("/api/getAllMessage", message)
    .then((res) => res.data.message_list)
    .catch((err) => toast.error(err.message));
}

async function PostContactUs(account) {
  console.log("account", account);
  await axios
    .post("/api/contact_submit", account)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.msg);
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostContactLeed(account) {
  console.log("account", account);
  await axios
    .post("/api/lead_submit", account)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.msg);
        localStorage.setItem("contact_lead", true);
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function PostUpdateStatus(account) {
  console.log("account", account);
  await axios
    .post("/api/updatestatus", account)
    .then((res) => {
      if (res.data.status === 1) {
        toast.success(res.data.msg);
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

export default {
  PostAward,
  PostBanner,
  PostBusinessType,
  PostHeading,
  PostSubscription,
  PostRequirement,
  PostContent,
  PostRequirementClose,
  PostHotProducts,
  PostBid,
  PostPayment,
  PostTracker,
  PostCMS,
  PostClient,
  PostSocial,
  PostInvoice,
  PostContact,
  PostServices,
  PostSupport,
  PostCategory,
  PostSubCategory,
  PostSupSubCategory,
  PostTestimonial,
  PostRating,
  PostMessage,
  PostGetMessage,
  PostContactUs,
  PostContactLeed,
  PostUpdateStatus,
};
