import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Delete from "./../../../common/delete";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import SearchBox from "../../../common/searchBox";

const BusinessTable = () => {
  const [businesses, setBusiness] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);

  const fetchBusiness = async () => {
    const data = await getService.handleBusinessTypeList();
    setBusiness(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteBusinessType({ id });
      setCurrentPage(1);
      fetchBusiness();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    let filtered = businesses;
    if (searchQuery)
      filtered = businesses.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else filtered = businesses;

    const business = paginate(filtered, currentPage, pageSize);
    return { totalCount: businesses.length, data: business };
  };

  const { length: count } = businesses;

  const { totalCount, data: business } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (business) => <p>{businesses.indexOf(business) + 1}</p>,
    },
    { path: "title", label: "Title" },
    { path: "user_type", label: "User Type" },
    {
      key: "Action",
      label: "Action",
      content: (business) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/businessform/${business.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={business.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchBusiness();
  }, []);

  if (count === 0) return <p>There are no item in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Business List </h4>
              </div>
              <CSVLink data={businesses} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <SearchBox value={searchQuery} onChange={handleSearch} />
              </div>
            </div>

            <div class="panel-body">
              <Table columns={columns} data={business} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default BusinessTable;
