import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from "./components/shared/Navbar";
import Admin from "./components/admin-panel/Admin";
import Footer from "./components/shared/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  useNavigate,
} from "react-router-dom";
import "./components/css/style.css";
import "./components/css/dashboard.css";
import "./components/css/activity.css";
import "./components/css/body.css";
import "./components/css/listing.css";
import "./components/css/sidebar.css";
import "./components/css/top.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Approval,
  Authorization,
  Error,
  ForgotPassword,
  Home,
  MyProfile,
  Signin,
  Signup,
  Membership,
  BuyerDashboard,
  SellerDashboard,
  Payment,
  CreatePassword,
  ReturnRefund,
  ShippingPolicy,
  PrivacyPolicy,
  TermsCondition,
} from "./components/application/";

import "font-awesome/css/font-awesome.css";
import "font-awesome/css/font-awesome.min.css";

import {
  BuyerBody,
  BuyerPersonal,
  BuyerCompany,
  BuyerBank,
  BuyerImages,
  BuyerPlans,
  BuyerOrder,
  BuyerInvoice,
  BuyerConcern,
  BuyerLedger,
  BuyerAnalytics,
  BuyerRatings,
  PostRequirement,
  MyRequirement,
  PostRequirementForm,
} from "./components/application/BuyerDashboard/BuyerBody Section";

import {
  SellerBody,
  SellerPersonal,
  SellerCompany,
  SellerBank,
  SellerImages,
  SellerPlans,
  SellerInvoiceForm,
  SellerPlants,
  SellerOrder,
  SellerInvoice,
  SellerLedger,
  SellerAnalytics,
  SellerRatings,
  SellerConcern,
  ViewRequirements,
  ViewRequirementsForm,
  MyBids,
} from "./components/application/SellerDashboard/SellerBody Section";

import {
  Dashboard,
  Bid,
  BidForm,
  BidTable,
  Client,
  ClientForm,
  ClientTable,
  Project,
  ProjectForm,
  ProjectTable,
  User,
  UserCreateForm,
  UserFormField,
  UserForm,
  UserTable,
  UserDetails,
  ContentForm,
  Content,
  AdminRequirement,
  AdminRequirementTable,
  CMS,
  CMSForm,
  CMSTable,
  Service,
  ServiceForm,
  ServiceTable,
  Categories,
  CategoriesForm,
  CategoriesTable,
  SubCategoriesForm,
  SubCategoriesTable,
  SupSubCategoriesForm,
  SupSubCategoriesTable,
  HotProducts,
  HotProductsForm,
  HotProductsTable,
  Testimonial,
  TestimonialForm,
  TestimonialTable,
  AdminRequirementForm,
  AdminInvoice,
  AdminSubscription,
  AdminSubscriptionForm,
  AdminSubscriptionTable,
  Social,
  Contact,
  SocialForm,
  Banner,
  BannerForm,
  BannerTable,
  Heading,
  HeadingForm,
  HeadingTable,
  Business,
  BusinessTable,
  BusinessForm,
  Support,
  SupportTable,
  SupportForm,
  Audience,
  ContactsTable,
  LeadsTable,
  Control,
  SubAdminTable,
  ModulesTable,
  BuyerTable,
  SellerTable,
  SubAdminForm,
  AllQueries,
  Helpdesk,
} from "./components/admin-panel/screens";

import AdminLogin from "./components/admin-panel/AdminLogin";

import { ToastContainer } from "react-toastify";
import "./components/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import ProtectedRoutes from "./components/common/protectedRoutes";
import auth from "./components/services/authService";
import Signout from "./components/common/Signout";
import PublicRoute from "./components/PublicRoutes/PublicRoute";
import BuyerPrivateRoute from "./components/PrivateRoutes/BuyerPrivateRoutes";
import SellerPrivateRoute from "./components/PrivateRoutes/SellerPrivateRoutes";
import AdminPrivateRoute from "./components/PrivateRoutes/AdminPrivateRoutes";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Invoice from "./components/common/invoice";
import Loader from "./components/common/loader";
import getService from "./components/services/getService";

const App = () => {
  // JWT Token Data
  const [jwtToken, setJwtToken] = useState({});

  const [showNav, setShowNav] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [myAccount, setMyAccount] = useState([]);
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headings, setHeading] = useState([]);
  const navigate = useNavigate();

  const fetchContact = async () => {
    const data = await getService.handleContact();
    setContact(data);
    setLoading(true);
  };

  const fetchHeading = async () => {
    const data = await getService.handleHeading();
    setHeading(data);
    setLoading(true);
  };

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    const user = auth.getCurrentUser();
    setMyAccount(user);
  }, []);

  useEffect(() => {
    fetchContact();
  }, []);

  useEffect(() => {
    fetchHeading();
  }, []);

  // useEffect(() => {
  //   if (headings && headings[9]?.title) {
  //     document.title = headings[9].title;
  //   } else {
  //     document.title = "Loading...";
  //   }

  //   return () => {
  //     document.title = "Loading...";
  //   };
  // }, [headings]);

  if (loading === false) return <Loader />;

  return (
    <>
      <ToastContainer />

      {showNav && (
        <Navbar userType={userType} user={myAccount} data={contact[0]} />
      )}
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={
            <PublicRoute
              Component={Home}
              userType={userType}
              myAccount={myAccount}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="*"
          element={<Error funcNav={setShowNav} funcFooter={setShowFooter} />}
        />

        <Route
          path="/authorization"
          element={
            <Authorization funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path="/createpassword"
          element={
            <CreatePassword funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />

        <Route
          path="/myProfile"
          element={
            <MyProfile funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />

        <Route
          path="/approval"
          element={<Approval funcNav={setShowNav} funcFooter={setShowFooter} />}
        />

        <Route
          path="/forgotpassword"
          element={
            <PublicRoute
              Component={ForgotPassword}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/return-refund"
          element={
            <PublicRoute
              Component={ReturnRefund}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/shipping-policy"
          element={
            <PublicRoute
              Component={ShippingPolicy}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PublicRoute
              Component={PrivacyPolicy}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/terms-condition"
          element={
            <PublicRoute
              Component={TermsCondition}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/signin"
          element={
            <PublicRoute
              Component={Signin}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
              userType={userType}
              myAccount={myAccount}
            />
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute
              Component={Signup}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
              userType={userType}
              myAccount={myAccount}
            />
          }
        />
        <Route
          path="/signout"
          element={<Signout funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        {/* <Route
          path="/membership"
          element={
            <PublicRoute
              Component={Membership}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        /> */}
        <Route
          path="/payment/:price"
          element={<Payment funcNav={setShowNav} funcFooter={setShowFooter} />}
        />

        <Route
          path="/buyerdashboard"
          element={
            <BuyerPrivateRoute
              Component={BuyerDashboard}
              userType={userType}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
              myAccount={myAccount}
            />
          }
        >
          <Route path="" element={<BuyerBody myAccount={myAccount} />} />
          <Route
            path="Personal Details/:token"
            element={<BuyerPersonal myAccount={myAccount} />}
          />
          <Route
            path="Comapny Details/:token"
            element={<BuyerCompany myAccount={myAccount} />}
          />
          <Route
            path="Bank Details/:token"
            element={<BuyerBank myAccount={myAccount} />}
          />
          <Route
            path="Images/:token"
            element={<BuyerImages myAccount={myAccount} />}
          />
          <Route path="Buyer Plans/:token" element={<BuyerPlans />} />
          <Route path="Orders" element={<BuyerOrder myAccount={myAccount} />} />
          <Route
            path="Orders/Invoices/:id"
            element={<BuyerInvoice myAccount={myAccount} />}
          />
          <Route
            path="Orders/Invoice/:id"
            element={<Invoice myAccount={myAccount} />}
          />

          <Route
            path="Ledger"
            element={<BuyerLedger myAccount={myAccount} />}
          />
          <Route
            path="Analytics"
            element={<BuyerAnalytics myAccount={myAccount} />}
          />
          <Route
            path="Ratings"
            element={<BuyerRatings myAccount={myAccount} />}
          />
          <Route
            path="Concern"
            element={<BuyerConcern myAccount={myAccount} />}
          />
          <Route
            path="PostRequirement"
            element={<PostRequirement myAccount={myAccount} />}
          />
          <Route
            path="PostRequirementForm/:id"
            element={<PostRequirementForm myAccount={myAccount} />}
          />
          <Route
            path="MyRequirement"
            element={<MyRequirement myAccount={myAccount} />}
          />
          <Route
            path="*"
            element={<Error funcNav={setShowNav} funcFooter={setShowFooter} />}
          />
        </Route>

        <Route
          path="/sellerdashboard"
          element={
            <SellerPrivateRoute
              userType={userType}
              Component={SellerDashboard}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
              myAccount={myAccount}
            />
          }
        >
          <Route path="" element={<SellerBody myAccount={myAccount} />} />
          <Route
            path="Personal Details/:token"
            element={<SellerPersonal myAccount={myAccount} />}
          />
          <Route
            path="Comapny Details/:token"
            element={<SellerCompany myAccount={myAccount} />}
          />
          <Route
            path="Plant Details/:token"
            element={<SellerPlants myAccount={myAccount} />}
          />
          <Route
            path="Bank Details/:token"
            element={<SellerBank myAccount={myAccount} />}
          />
          <Route
            path="Images/:token"
            element={<SellerImages myAccount={myAccount} />}
          />
          <Route
            path="Seller Plans/:token"
            element={<SellerPlans myAccount={myAccount} />}
          />
          <Route
            path="Orders"
            element={<SellerOrder myAccount={myAccount} />}
          />
          <Route
            path="Orders/Invoices/:id"
            element={<SellerInvoice myAccount={myAccount} />}
          />
          <Route
            path="Orders/Invoice/:id"
            element={<Invoice myAccount={myAccount} />}
          />
          <Route
            path="Orders/InvoiceForm/:id"
            element={<SellerInvoiceForm myAccount={myAccount} />}
          />
          <Route
            path="Ledger"
            element={<SellerLedger myAccount={myAccount} />}
          />
          <Route
            path="Analytics"
            element={<SellerAnalytics myAccount={myAccount} />}
          />
          <Route
            path="Ratings"
            element={<SellerRatings myAccount={myAccount} />}
          />
          <Route
            path="Concern"
            element={<SellerConcern myAccount={myAccount} />}
          />
          <Route path="ViewRequirements" element={<ViewRequirements />} />
          <Route
            path="ViewRequirementsForm/:id"
            element={<ViewRequirementsForm myAccount={myAccount} />}
          />
          <Route path="MyBids" element={<MyBids myAccount={myAccount} />} />
          <Route
            path="*"
            element={<Error funcNav={setShowNav} funcFooter={setShowFooter} />}
          />
        </Route>

        <Route
          path="/adminlogin"
          element={
            <AdminLogin funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />

        <Route
          path="/admin"
          element={
            <AdminPrivateRoute
              Component={Admin}
              userType={userType}
              myAccount={myAccount}
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        >
          <Route path="" element={<Dashboard />} />
          <Route path="bid" element={<Bid />} />
          <Route path="bidform/:id" element={<BidForm />} />
          <Route path="bidtable" element={<BidTable />} />
          <Route path="client" element={<Client />} />
          <Route path="clientform/:id" element={<ClientForm />} />
          <Route path="clienttable" element={<ClientTable />} />
          <Route path="support" element={<Support />} />
          <Route path="supportform/:id" element={<SupportForm />} />
          <Route path="supporttable" element={<SupportTable />} />
          <Route path="banner" element={<Banner />} />
          <Route path="bannerform/:id" element={<BannerForm />} />
          <Route path="bannertable" element={<BannerTable />} />
          <Route path="project" element={<Project />} />
          <Route path="projectform/:id" element={<ProjectForm />} />
          <Route path="projecttable" element={<ProjectTable />} />
          <Route path="user" element={<User />} />
          <Route path="user/usercreateform" element={<UserCreateForm />} />
          <Route path="user/userformfield" element={<UserFormField />} />
          <Route path="userdetails/:id" element={<UserDetails />} />
          <Route path="userform/:id" element={<UserForm />} />
          <Route path="usertable" element={<UserTable />} />
          <Route path="sellertable" element={<SellerTable />} />
          <Route path="buyertable" element={<BuyerTable />} />
          <Route path="audience" element={<Audience />} />
          <Route path="contactstable" element={<ContactsTable />} />
          <Route path="leadstable" element={<LeadsTable />} />
          <Route path="requirements" element={<AdminRequirement />} />
          <Route path="requirementstable" element={<AdminRequirementTable />} />
          <Route
            path="requirementsform/:id"
            element={<AdminRequirementForm />}
          />
          <Route path="requirementstable/:id" element={<AdminInvoice />} />
          <Route path="subscription" element={<AdminSubscription />} />
          <Route
            path="subscriptiontable"
            element={<AdminSubscriptionTable />}
          />
          <Route
            path="subscriptionform/:id"
            element={<AdminSubscriptionForm />}
          />
          <Route path="cms" element={<CMS />} />
          <Route path="cmstable" element={<CMSTable />} />
          <Route path="cmsform/:id" element={<CMSForm />} />
          <Route path="service" element={<Service />} />
          <Route path="servicetable" element={<ServiceTable />} />
          <Route path="serviceform/:id" element={<ServiceForm />} />
          <Route path="heading" element={<Heading />} />
          <Route path="headingtable" element={<HeadingTable />} />
          <Route path="headingform/:id" element={<HeadingForm />} />
          <Route path="business" element={<Business />} />
          <Route path="businesstable" element={<BusinessTable />} />
          <Route path="businessform/:id" element={<BusinessForm />} />
          <Route path="categories" element={<Categories />} />
          <Route path="categoriestable" element={<CategoriesTable />} />
          <Route path="categoriesform/:id" element={<CategoriesForm />} />
          <Route path="subcategoriestable" element={<SubCategoriesTable />} />
          <Route path="subcategoriesform/:id" element={<SubCategoriesForm />} />
          <Route
            path="supsubcategoriestable"
            element={<SupSubCategoriesTable />}
          />
          <Route
            path="supsubcategoriesform/:id"
            element={<SupSubCategoriesForm />}
          />
          <Route path="hotproducts" element={<HotProducts />} />
          <Route path="hotproductstable" element={<HotProductsTable />} />
          <Route path="hotproductsform/:id" element={<HotProductsForm />} />
          <Route path="testimonial" element={<Testimonial />} />
          <Route path="testimonialtable" element={<TestimonialTable />} />
          <Route path="testimonialform/:id" element={<TestimonialForm />} />
          <Route path="usertable" element={<UserTable />} />
          <Route path="social" element={<Social />} />
          <Route path="socialform/:id" element={<SocialForm />} />
          <Route path="content" element={<Content />} />
          <Route path="contentform/:id" element={<ContentForm />} />
          <Route path="contact" element={<Contact />} />
          <Route path="control" element={<Control />} />
          <Route path="allmodules" element={<ModulesTable />} />
          <Route path="allsubadmins" element={<SubAdminTable />} />
          <Route path="subadminform/:id" element={<SubAdminForm />} />
          <Route path="helpdesk" element={<Helpdesk />} />
          <Route
            path="allqueries"
            element={<AllQueries myAccount={myAccount} />}
          />

          <Route
            path="*"
            element={<Error funcNav={setShowNav} funcFooter={setShowFooter} />}
          />
        </Route>

        <Route path="/home" element={<Navigate to="/" replace />} />
      </Routes>
      {showFooter && <Footer />}
    </>
  );
};

export default App;
