import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import NoImage from "../../../assets/images/NoImage.png";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import Delete from "../../../common/delete";
import { CSVLink } from "react-csv";
import SearchBox from "../../../common/searchBox";

const SupportTable = () => {
  const [supports, setSupport] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);

  const fetchSupport = async () => {
    const data = await getService.handleSupport();
    setSupport(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteSupport({ id });
      setCurrentPage(1);
      fetchSupport();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    let filtered = supports;
    if (searchQuery)
      filtered = supports.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else filtered = supports;

    const support = paginate(filtered, currentPage, pageSize);
    return { totalCount: supports.length, data: support };
  };

  const { length: count } = supports;

  const { totalCount, data: support } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (support) => <p>{supports.indexOf(support) + 1}</p>,
    },
    { path: "title", label: "Title" },
    {
      path: "logo",
      label: "Logo",
      content: (c) => (
        <>
          {c.img1 !== null ? (
            <img
              style={{ width: "100px", height: "100px" }}
              src={c.img1}
              alt="Onclq.com"
            />
          ) : (
            <img
              style={{ width: "100px", height: "100px" }}
              src={NoImage}
              alt="Onclq.com"
            />
          )}
        </>
      ),
    },

    {
      key: "Action",
      label: "Action",
      content: (support) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/supportform/${support.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={support.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchSupport();
  }, []);

  if (count === 0) return <p>There are no orders in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Support List </h4>
              </div>
              <CSVLink data={supports} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <SearchBox value={searchQuery} onChange={handleSearch} />
              </div>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={support} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default SupportTable;
