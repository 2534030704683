import React, { useState } from "react";

const ChatList = ({ orderList, currentUser, changeChat, handleSendMsg }) => {
  const [currentSelected, setCurrentSelected] = useState(undefined);

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
  };

  if (!orderList) return null;

  return (
    <div className="activitySection">
      <div className="heading flex">
        <h1>
          {" "}
          <i className="fa fa-comment-o" /> Tickets{" "}
        </h1>
      </div>

      {currentUser && (
        <>
          <div className="secContainer grid">
            {orderList.map((contact, index) => {
              return (
                <>
                  <div
                    className="singleCustomer flex"
                    onClick={() => changeCurrentChat(index, contact)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="customerDetails">
                      <span className="name">
                        #Order Id - {contact.order_id}
                      </span>
                      <small>{contact.order_date}</small>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ChatList;
