import React, {useState} from 'react';
import Top from './Top Section/Top'
import CompanyDetails from './../../Card/Forms/CompanyDetails';
import { SignUpData } from '../../../data/signUpData';

const SellerCompany = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
        <CompanyDetails account={account} setAccount={setAccount} myAccount={myAccount} />
      </div>

    </div>
  )
}

export default SellerCompany