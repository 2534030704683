import React from 'react';
import Top from './Top Section/Top';
import { BsInfoCircleFill } from 'react-icons/bs';
import Input from '../../../common/input';

const BuyerPlans = () => {
  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
      <div className='lisitingSection'>
      <div className="heading flex">
        <h1>Upload Payment Receipt</h1>
      </div>

      <div className="heading flex">
        <Input type="file" name="upload" label="Upload Your Payment Receipt" />
      </div>
     
     </div>
      </div>
      <div className='bottom flex'>
      <div className='lisitingSection'>
      <div className="heading flex">
        <h1>My Spendings</h1>
        <button className='btn flex'>
          Billing Info. <BsInfoCircleFill className="icon"/>
        </button>
      </div>

      <div className="heading flex">
        <h1>Plan Info</h1>
        <button className='btn flex'>
            Bank details 
        </button>
      </div>
     
     </div>
      </div>
    </div>
  )
}

export default BuyerPlans