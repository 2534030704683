import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "./../../../common/input";
import getService from "../../../services/getService";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import upload from "../../../../components/assets/images/upload.jpg";
import { MdCancel } from "react-icons/md";

const AdminSubscriptionForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [Img, setImg] = useState("");
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [account, setAccount] = useState({
    subscription_id: "",
    title: "",
    business_bandwidth_title: "",
    business_bandwidth_value: "",
    price_per_month: "",
    price_three_month: "",
    validity_in_month: "",
    img1: "",
  });

  const PopulateForm = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleCategory();
      let data = await getService.getData(collection, Id);
      data = { subscription_id: data.id, ...data };
      setAccount(mapToViewModel(data));
      setImageUrl(data.img1);
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ ...rest }) => ({ ...rest });

  useEffect(() => {
    PopulateForm();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await postService.PostSubscription(account);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const setImgPic = () => {
    setImageUrl(fileUrl);
    setAccount({ ...account, img1: url });
    setUrl("");
  };

  const ImageUpload = () => {
    Img === "img1" ? setImgPic() : console.log("account");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const removeImg = () => {
    setFileUrl(null);
    setAccount({ ...account, img1: null });
    setImageUrl(null);
  };

  const {
    title,
    business_bandwidth_title,
    business_bandwidth_value,
    price_per_month,
    price_three_month,
    validity_in_month,
  } = account;

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Add/Update Subscription </h4>
              </div>
            </div>
            <div class="panel-body">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div className="col-sm-6">
                    <Input
                      name="title"
                      label="Title"
                      value={title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="business_bandwidth_title"
                      label="Business Bandwidth Title"
                      value={business_bandwidth_title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="business_bandwidth_value"
                      label="Business Bandwidth Value"
                      value={business_bandwidth_value}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="price_per_month"
                      label="Price Per Month"
                      value={price_per_month}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="price_three_month"
                      label="Price Three Month"
                      value={price_three_month}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      name="validity_in_month"
                      label="Validity In Month"
                      value={validity_in_month}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      Upload Image
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      name="img1"
                      label="Img1"
                      accept="image/png, image/jpeg"
                      onChange={onImageChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="col-sm-12 mt-3 d-flex flex-column">
                      <label for="exampleFormControlInput1" class="form-label">
                        Image
                      </label>

                      {imageUrl && (
                        <div className="img-admin-upload">
                          <MdCancel
                            onClick={removeImg}
                            color="red"
                            size={25}
                            className="icon"
                          />
                          <img
                            src={imageUrl}
                            style={{ width: "100px", height: "100px" }}
                            alt="Preview"
                          />
                        </div>
                      )}
                      {!imageUrl && (
                        <img
                          src={upload}
                          style={{ width: "100px", height: "100px" }}
                          alt="Preview"
                        />
                      )}
                    </div>
                  </div>

                  <div class="col-md-1 offset-md-11">
                    <button type="submit" className="btn btn-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSubscriptionForm;
