import React from "react";
import Sidebar from "./SideBar Section/Sidebar";
import { Outlet } from "react-router-dom";

const SellerDashboard = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  return (
    <>
      <section className="dashboard">
        <div className="dashboard-body">
          <div className="dashboard-container">
            <Sidebar myAccount={props.myAccount} />
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};

export default SellerDashboard;
