import React, {useState} from 'react';
import Top from './Top Section/Top'
import ImagesBuyer from './../../Card/Forms/ImagesBuyer';
import { SignUpData } from './../../../data/signUpData';

const BuyerImages = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
        <ImagesBuyer account={account} setAccount={setAccount} myAccount={myAccount} />
      </div>
    </div>
  )
}

export default BuyerImages