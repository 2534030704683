import React from "react";
import Sidebar from "./SideBar Section/Sidebar";
import { Outlet } from "react-router-dom";

const BuyerDashboard = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  return (
    <>
      <div className="dashboard-body">
        <div className="dashboard-container">
          <Sidebar myAccount={props.myAccount} />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default BuyerDashboard;
