import React from "react";
import noCart from "../assets/images/empty_cart.gif";

const NoCart = () => {
  return (
    <>
      <div
        className="mainContent"
        style={{ display: "contents", width: "100%" }}
      >
        {" "}
        <img src={noCart} alt="" />{" "}
      </div>
    </>
  );
};

export default NoCart;
