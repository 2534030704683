export const ViewRequirementsData = {
  bid_id: "",
  post_requirement_id: "",
  user_id: "",
  token: "",
  seller_name: "",
  buyer_name: "",
  location: "",
  bid_price: "",
  bid_specification: "",
  credit_facility: "",
  credit_no_of_days: "",
  delivery_timeline: "",
};
