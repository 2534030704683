import React, {useState} from 'react';
import Top from './Top Section/Top'
import PersonalDetails from './../../Card/Forms/PersonalDetails';
import { SignUpData } from '../../../data/signUpData';

const SellerPersonal = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
      <PersonalDetails account={account} setAccount={setAccount} myAccount={myAccount} />

      </div>
    </div>
  )
}

export default SellerPersonal