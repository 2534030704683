import React, { useEffect } from "react";
import auth from "../services/authService";

const Signout = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  useEffect(() => {
    auth.signout();
    window.location = "/";
  });
  return null;
};

export default Signout;
