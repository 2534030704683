import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import Input from "../../../common/input";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import { ImagesBuyerSchema } from "./../../../data/ImagesBuyerSchema";
import upload from "../../../../components/assets/images/upload.jpg";

const ImagesBuyer = ({
  account,
  setAccount,
  currentStep,
  myAccount,
  updateStep,
  userType,
}) => {
  const [Img, setImg] = useState("");
  const [companyAreaUrl, setCompanyAreaUrl] = useState(
    account.company_area_pic
  );
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, ImagesBuyerSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: ImagesBuyerSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      account.profile_complete = "Yes";
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "images",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateImage = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    PopulateImage();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const handleCompanyArea = (item) => {
    companyAreaRef.current.click();
  };

  const setCompanyAreaPic = () => {
    setCompanyAreaUrl(fileUrl);
    setAccount({ ...account, company_area_pic: url });
    setUrl("");
  };

  const ImageUpload = (url) => {
    Img === "company_area_pic"
      ? setCompanyAreaPic()
      : console.log("account company_area_pic");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const companyAreaRef = useRef();

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  const { signup_step } = account;

  return (
    <>
      <section className="images">
        <div className="container">
          <h3>Images</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6"> Image of Area</div>
              <div className="col-md-6">
                <div className="img-upload">
                  <div className="img">
                    {companyAreaUrl && (
                      <img src={companyAreaUrl} alt="company_area_pic" />
                    )}
                    {!companyAreaUrl && (
                      <img src={upload} alt="company_area_pic" />
                    )}
                  </div>
                  <div className="upload-button">
                    <input
                      ref={companyAreaRef}
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      name="company_area_pic"
                      label="Upload Image"
                      type="file"
                      onChange={onImageChange}
                    />
                    <button
                      type="button"
                      className="btn btn-attach"
                      onClick={handleCompanyArea}
                    >
                      <i className="fa fa-cloud-upload"></i> <br />
                      Upload Image
                    </button>
                  </div>
                </div>
                {errors && (
                  <p className="text-danger">{errors.company_area_pic}</p>
                )}
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Input
                  name="signup_step"
                  label="Signup_step"
                  value={signup_step}
                  onChange={handleChange}
                  disabled="true"
                />
              </div>
            </div>

            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    type="button"
                    className="primaryButton"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={() => updateStep(currentStep + 1)}
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>
                  <button
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default ImagesBuyer;
