export const RatingData = [
    // {
    //   id: 1,
    //   title: "Quality",
    //   rating: 3,
    // },
    // {
    //   id: 2,
    //   title: "Timeline",
    //   rating: 5,
    // },
    // {
    //   id: 3,
    //   title: "Pricing",
    //   rating: 1,
    // },
  ];