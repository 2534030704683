import React from 'react'
import { Link } from 'react-router-dom';

const Error = (props) => {
  props.funcNav(false);
  props.funcFooter(false);
  return (
    <>
    <section className="banner" style={{height: window.innerHeight}}>
      <h1>Error 404 !</h1>
      <p>OPPS ! SOMETHING WENT WRONG... <i className="fa fa-meh-o"></i> </p>
      <p>Sorry, the page you are looking for doesn't exist or not found.</p>
      <Link to="/"> 
      <button>
      Go to home 
      </button>
      </Link>
    </section>
    </>
  )
}

export default Error