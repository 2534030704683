import React, { useState, useEffect } from "react";
import { getOrders } from "./../../../data/SampleOrderData";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Delete from "./../../../common/delete";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const BidTable = () => {
  const [bids, setBids] = useState([]);
  const [id, setId] = useState(null);

  const fetchBids = async () => {
    const data = await getService.handleBids({
      user_id: "",
      token: "",
      post_requirement_id: "",
    });
    setBids(data.post_requirement_bid_list);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteBusinessType({ id });
      setCurrentPage(1);
      fetchBids();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const formatter = new Intl.NumberFormat("hi", {
    style: "currency",
    currency: "INR",
  });

  const getPagedData = () => {
    const business = paginate(bids, currentPage, pageSize);
    return { totalCount: bids.length, data: business };
  };

  const { length: count } = bids;

  const { totalCount, data: business } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (business) => <p>{bids.indexOf(business) + 1}</p>,
    },
    { path: "seller_name", label: "Seller Name" },
    { path: "buyer_name", label: "Buyer Name" },
    {
      path: "bid_price",
      label: "Bid Price",
      content: (bid) => <>{formatter.format(bid.bid_price)}</>,
    },
    {
      key: "Action",
      label: "Action",
      content: (business) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/bidform/${business.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={business.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchBids();
  }, []);

  if (count === 0) return <p>There are no item in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Bids List </h4>
              </div>
              <CSVLink data={business} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={business} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default BidTable;
