import React, { useState, useEffect } from "react";
import { ViewRequirementsData } from "../../../data/ViewRequirementsData";
import { ViewRequirementsSchema } from "../../../data/ViewRequirementsSchema";
import { Joi } from "joi-browser";
import Select from "../../../common/select";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import getService from "../../../services/getService";
import Checkbox from "../../../common/checkbox";
import Input from "../../../common/input";

const BidForm = ({ requirement, handleBid }) => {
  const [post, setPost] = useState(ViewRequirementsData);
  const [users, setUsers] = useState([]);
  const [account, setAccount] = useState({
    user_id: "",
    token: "",
    seller_name: "",
    location: "",
  });
  const [creditFacility, setCreditFacility] = useState(false);

  const fetchUsers = async () => {
    const user = await getService.handleUserList();
    const filteredArray = user.filter(
      (filter) => filter.user_type === "Seller"
    );

    const campanyArray = filteredArray.map(
      ({ name: title, company_name: name, ...rest }) => ({
        title,
        name,
        ...rest,
      })
    );
    const data = campanyArray.filter((c) => c.name !== null);
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const posts = { ...post };
    posts[input.name] = input.value;
    setPost(posts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...post,
      user_id: account.user_id,
      token: account.token,
      seller_name: account.seller_name,
      location: account.location,
      post_requirement_id: requirement.id,
      buyer_name: requirement.name,
      credit_facility: creditFacility,
      delivery_timeline: requirement.delivery_date,
    };
    try {
      await handleBid(data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (post.seller_name !== "") {
      let data = users.find((u) => u.name === post.seller_name);
      data === undefined
        ? setAccount({
            user_id: "",
            token: "",
            seller_name: "",
            location: "",
          })
        : setAccount({
            user_id: data.id,
            token: data.token,
            seller_name: data.name,
            location: data.address,
          });
    }
  }, [post.seller_name]);

  const {
    seller_name,
    buyer_name,
    bid_price,
    bid_specification,
    credit_no_of_days,
  } = post;

  return (
    <>
      <ul class="list-group">
        <li class="list-group-item">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Select
                  label="Company Name"
                  type="text"
                  name="seller_name"
                  value={seller_name}
                  placeholder="Enter your company name"
                  onChange={handleChange}
                  options={users}
                />
              </div>
              <div className="col-md-12 mt-2">
                <Input
                  name="bid_price"
                  value={bid_price}
                  label="Bid Price"
                  onChange={handleChange}
                  type="number"
                />
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <label for="bid_specification" className="form-label">
                      Tell us more about your specification.
                    </label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="bid_specification"
                      value={bid_specification}
                      placeholder="Describe your bid specification here...."
                      onChange={handleChange}
                    />
                    <p className="td-words-linit">4000 character left.</p>
                  </div>

                  <div className="col-md-12 mt-2">
                    <div className="row">
                      <div className="col-md-7 mt-3">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <label htmlFor="creditFacility">
                            {" "}
                            Credit Facility
                          </label>
                          <div>
                            <Checkbox
                              label="Yes"
                              type="radio"
                              name="creditFacility"
                              value="Yes"
                              onChange={() => setCreditFacility("Yes")}
                            />
                            <Checkbox
                              label="No"
                              type="radio"
                              name="creditFacility"
                              value="No"
                              onChange={() => setCreditFacility("No")}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-5"
                        style={{
                          display: creditFacility === "Yes" ? "block" : "none",
                        }}
                      >
                        <Input
                          type="number"
                          label="No Of Days"
                          placeholder="No of Days"
                          name="credit_no_of_days"
                          value={credit_no_of_days}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <div className="d-flex justify-content-end w-100">
                  <button type="submit" className="btn btn-success btn-lg mt-2">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </li>
      </ul>
    </>
  );
};

export default BidForm;
