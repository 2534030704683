import React from 'react'

const Select = ({name, label, options, error, ...rest }) => {
  return (
    <>
    <div className="form-group">
    <div>
        <label htmlFor={name}>{label}</label>
    </div>
    <div>

            <select name={name} id={name} {...rest} className="custom form-select">
               <option value="" >Open the Select Menu </option>
                {options.map(option => (
                <option key={option._id} value={option.name}>
                    {option.name}
                </option>
                ))}
            </select>
            {error && <p className="text-danger">{error}</p>}
     </div>
    </div>
    </>
  )
}

export default Select