export const SignUpData = {
  account_holder_name: "",
  account_no: "",
  address: "",
  available_capacity: "",
  bank_name: "",
  business_type: "",
  cancel_cheque_pic: "",
  cin_no: "",
  cin_pic: "",
  city_id: "",
  city_name: "",
  company_area_pic: "",
  company_logo: "",
  company_name: "",
  company_registration_date: "",
  company_type: "",
  country_id: "",
  country_name: "",
  created_date: "",
  comp_file: "",
  user_id: "",
  designation: "",
  email: "",
  gst_no: "",
  gst_pic: "",
  id: "",
  ifsc_code: "",
  inside_of_plant_pic: "",
  lamination_facility: "",
  machinary_type: "",
  machine_pic: "",
  mobile: "",
  name: "",
  no_of_machine: "",
  other_facility: "",
  otp: "",
  outside_with_board_pic: "",
  pan_no: "",
  pan_pic: "",
  password: "",
  phone: "",
  pincode: "",
  plant_capacity: "",
  plant_pic: "",
  plant_type: "",
  printing_facility: "",
  state_id: "",
  state_name: "",
  profile_complete: "",
  status: "",
  to_be_verified: "",
  token: "",
  updated_date: "",
  user_type: "",
  uv_coating: "",
  visiting_card_pic: "",
  signup_step: "",
};
