import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import { BsArrowLeftCircle } from "react-icons/bs";
import { animateScroll } from "react-scroll";

const ChatPanel = ({
  currentChat,
  setCurrentChat,
  messages,
  handleSendMsg,
  chatRef,
  handleClick,
  activeTab,
  setActiveTab,
}) => {
  if (!messages) return null;

  return (
    <>
      {currentChat && (
        <div className="activitySection">
          <div className="heading flex" style={{ flexDirection: "row" }}>
            <h1>Order Id - {currentChat.order_id}</h1>
            <div className="back">
              <BsArrowLeftCircle
                size={25}
                onClick={() => setCurrentChat(undefined)}
              />
            </div>
          </div>

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "buyer" ? "active" : ""}`}
                id="buyer-tab"
                data-bs-toggle="tab"
                data-bs-target="#buyer"
                type="button"
                role="tab"
                aria-controls="buyer"
                aria-selected={activeTab === "buyer"}
                onClick={() => setActiveTab("buyer")}
              >
                Buyer
              </button>{" "}
            </li>
            <li class="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "seller" ? "active" : ""}`}
                id="seller-tab"
                data-bs-toggle="tab"
                data-bs-target="#seller"
                type="button"
                role="tab"
                aria-controls="seller"
                aria-selected={activeTab === "seller"}
                onClick={() => setActiveTab("seller")}
              >
                Seller
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "buyer" ? "show active" : ""
              }`}
              id="buyer"
              role="tabpanel"
              aria-labelledby="buyer-tab"
            >
              {/* Buyer content goes here */}

              <div class="chat">
                <div
                  id="chat-container"
                  class="chat-box"
                  style={{ height: "50vh" }}
                  ref={chatRef}
                >
                  {messages.map((message) => (
                    <>
                      {message.fromSelf && (
                        <div class="chat-r">
                          <div class="sp"></div>
                          <div class="mess mess-r">
                            <p>{message.message}</p>
                          </div>
                        </div>
                      )}

                      {!message.fromSelf && (
                        <div class="chat-l">
                          <div class="mess">
                            <p>{message.message}</p>
                          </div>
                          <div class="sp"></div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "seller" ? "show active" : ""
              }`}
              id="seller"
              role="tabpanel"
              aria-labelledby="seller-tab"
            >
              {/* Seller content goes here */}

              <div class="chat">
                <div
                  id="chat-container"
                  class="chat-box"
                  style={{ height: "50vh" }}
                  ref={chatRef}
                >
                  {messages.map((message) => (
                    <>
                      {message.fromSelf && (
                        <div class="chat-r">
                          <div class="sp"></div>
                          <div class="mess mess-r">
                            <p>{message.message}</p>
                          </div>
                        </div>
                      )}

                      {!message.fromSelf && (
                        <div class="chat-l">
                          <div class="mess">
                            <p>{message.message}</p>
                          </div>
                          <div class="sp"></div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <ChatInput handleSendMsg={handleSendMsg} />
        </div>
      )}
    </>
  );
};

export default ChatPanel;
