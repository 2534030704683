import React, { useState, useEffect } from "react";
import Input from "../../../common/input";
import { useNavigate } from "react-router-dom";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import PostBid from "./../../../common/PostBid";
import { ViewRequirementsSchema } from "./../../../data/ViewRequirementsSchema";
import Joi from "joi-browser";
import { ViewRequirementsData } from "./../../../data/ViewRequirementsData";
import Loader from "./../../../common/loader";
import ViewGsm from "../../../common/viewGsm";

const BidRequirement = ({ post_requirement_id, requirement, myAccount }) => {
  const [bid, setBid] = useState(ViewRequirementsData);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [creditFacility, setCreditFacility] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postService.PostBid({
        ...bid,
        user_id: myAccount.id,
        token: myAccount.token,
        seller_name: myAccount.company_name,
        buyer_name: requirement.name,
        post_requirement_id: post_requirement_id,
        location: myAccount.address,
        credit_facility: creditFacility,
        delivery_timeline: requirement.delivery_date,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(bid, ViewRequirementsSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: ViewRequirementsSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];
    const bids = { ...bid };
    bids[input.name] = input.value;
    setBid(bids);
  };

  const userType = localStorage.getItem("userType");

  const { bid_price, bid_specification, credit_no_of_days } = bid;

  if (requirement.req_img === undefined) return <Loader />;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="col-md-10 col-md-pull-2 mt-2">
            <div className="row">
              <div className="col-md-6 mt-1">
                <Input
                  disabled="true"
                  label="Company Name"
                  type="text"
                  name="name"
                  value={requirement.name}
                  placeholder="Enter your company name"
                />
              </div>
              <div className="col-md-6 mt-1">
                <Input
                  disabled="true"
                  label="My Location"
                  type="text"
                  name="location"
                  value={requirement.location}
                  placeholder="Enter your location"
                />
              </div>
            </div>
          </div>

          <div class="col-md-10 col-md-pull-2 mt-2">
            <label for="description" class="form-label">
              Tell us more about your requirement. What product you will pack in
              this and what would be the weight of your product?
            </label>
            <textarea
              class="form-control"
              rows="5"
              disabled="true"
              name="description"
              value={requirement.description}
              placeholder="Description"
            />
            <p className="td-words-linit">4000 character left.</p>
          </div>

          <div class="col-md-10 col-md-pull-2 mt-2">
            <div className="row">
              <div className="col-md-4">
                <Input
                  disabled="true"
                  label="Category"
                  name="cat_name"
                  value={requirement.cat_name}
                />
              </div>
              <div className="col-md-4">
                <Input
                  disabled="true"
                  label="Sub Category"
                  name="subcat_name"
                  value={requirement.subcat_name}
                />
              </div>
              <div className="col-md-4">
                <Input
                  disabled="true"
                  label="Sup Sub Category"
                  name="super_subcat_name"
                  value={requirement.super_subcat_name}
                />
              </div>
            </div>
          </div>

          <div
            class="col-md-10 col-md-pull-2 mt-2"
            style={{
              display: requirement.cat_name === "Paper" ? "block" : "none",
            }}
          >
            <div className="row">
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Length"
                  placeholder="Length"
                  name="length"
                  value={requirement.length}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Width"
                  placeholder="Width"
                  name="width"
                  value={requirement.width}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Height"
                  placeholder="Height"
                  name="height"
                  value={requirement.height}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  label="Size"
                  name="size"
                  value={requirement.size}
                />
              </div>
              <div className="col-md-4 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Ply."
                  placeholder="Ply."
                  name="ply"
                  value={requirement.ply}
                />
              </div>
              <div className="col-md-4 mt-3">
                <Input
                  disabled="true"
                  label="Style"
                  name="style_name"
                  value={requirement.style_name}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label for="gsmPaper" class="form-label" style={{ margin: 0 }}>
                  View GSM
                </label>

                <ViewGsm gsm={requirement.req_gsm} />
              </div>
            </div>
          </div>

          <div
            class="col-md-10 col-md-pull-2 mt-2"
            style={{
              display: requirement.cat_name === "Plastic" ? "block" : "none",
            }}
          >
            <div className="row">
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Length"
                  placeholder="Length"
                  name="length"
                  value={requirement.length}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Width"
                  placeholder="Width"
                  name="width"
                  value={requirement.width}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Gazette"
                  placeholder="Gazette"
                  name="gazette"
                  value={requirement.gazette}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  label="Size"
                  name="size"
                  value={requirement.size}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  type="number"
                  label="Flap"
                  placeholder="Flap"
                  name="flap"
                  value={requirement.flap}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  label="Style"
                  name="style_name"
                  value={requirement.style_name}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  label="Micron Type"
                  name="micron_type_name"
                  value={requirement.micron_type_name}
                />
              </div>
              <div className="col-md-3 mt-3">
                <Input
                  disabled="true"
                  label="Material Type"
                  name="material_type_name"
                  value={requirement.material_type_name}
                />
              </div>
            </div>
          </div>

          <div class="col-md-10 col-md-pull-2 mt-2">
            <div className="row">
              <div className="col-md-4">
                <Input
                  disabled="true"
                  type="date"
                  label="Delivery Date"
                  name="delivery_date"
                  value={requirement.delivery_date}
                />
              </div>
              <div className="col-md-4">
                <Input
                  disabled="true"
                  type="number"
                  label="Quantity"
                  placeholder="Quantity"
                  name="quantity"
                  value={requirement.quantity}
                />
              </div>
              <div
                className="col-md-4"
                style={{
                  display: requirement.printing === "Yes" ? "block" : "none",
                }}
              >
                <Input
                  disabled="true"
                  type="number"
                  label="No Of Colours"
                  placeholder="No Of Colours"
                  name="no_of_color"
                  value={requirement.no_of_color}
                />
              </div>
            </div>
          </div>

          <div class="col-md-10 col-md-pull-2 mt-2">
            <div className="table-responsive">
              <table class="table table-attach" width="100%">
                <tr>
                  <td colSpan={5}>
                    {" "}
                    <h3>Attachments</h3>{" "}
                  </td>
                </tr>
                <tbody>
                  {requirement.req_img.map((item) => (
                    <tr>
                      <td style={{ width: "75%" }}>
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={item.req_image}
                          alt="req_image"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {userType === "Seller" && (
            <>
              <div class="col-md-10 mt-2">
                <button
                  className="btn btn-req"
                  type="button"
                  onClick={() => setOpen(!open)}
                >
                  Bid on this requirement
                </button>
                <PostBid
                  bid_price={bid_price}
                  bid_specification={bid_specification}
                  errors={errors}
                  onChange={handleChange}
                  open={open}
                  setOpen={setOpen}
                  onValidate={validate}
                  credit_no_of_days={credit_no_of_days}
                  creditFacility={creditFacility}
                  setCreditFacility={setCreditFacility}
                />
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default BidRequirement;
