import React, { useState, useEffect } from "react";
import appLogo from "../assets/navigation-menu/WhiteLogo.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import getService from "../services/getService";
import Loader from "./../common/loader";
import FootOne from "./Footer/FootOne";
import FootTwo from "./Footer/FootTwo";
import Address from "./Footer/Address";
import Email from "./Footer/Email";

const Footer = () => {
  const scrollToElement = (item) => {
    scroller.scrollTo(item, {
      duration: 100,
      delay: 0,
      smooth: "smooth",
    });
  };

  const [contact, setContact] = useState([]);
  const [social, setSocial] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cms, setCMS] = useState([]);

  const fetchCMS = async () => {
    const data = await getService.handleCMSList();
    setCMS(data);
    setLoading(true);
  };

  const fetchContact = async () => {
    const data = await getService.handleContact();
    setContact(data);
    setLoading(true);
  };

  const fetchSocial = async () => {
    const data = await getService.handleSocialList();
    setSocial(data);
    setLoading(true);
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  useEffect(() => {
    fetchContact();
  }, []);

  useEffect(() => {
    fetchSocial();
  }, []);

  const faIcon = (title, item) => {
    if (title === "Facebook") return <i className="fa fa-facebook"></i>;
    if (title === "Instagram") return <i className="fa fa-instagram"></i>;
    if (title === "LinkedIn") return <i className="fa fa-linkedin"></i>;
    if (title === "Twitter") return <i className="fa fa-twitter"></i>;
    if (title === "Youtube") return <i className="fa fa-youtube-square"></i>;
    if (title === "Pinterest") return <i className="fa fa-pinterest"></i>;
    if (title === "Snapchat") return <i className="fa fa-snapchat"></i>;
    if (title === "Tumblr") return <i className="fa fa-tumblr"></i>;
  };

  if (loading === false) return <Loader />;

  return (
    <>
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="foot-box" style={{ paddingTop: "0px" }}>
                <FootOne data={cms[7]} />
                <Address data={contact[0]} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="foot-box">
                    <h4 style={{ padding: "0px 8px" }}>Моге</h4>
                    <ul>
                      <li onClick={() => scrollToElement("my-about")}>
                        <Link>About us</Link>
                      </li>
                      <li onClick={() => scrollToElement("top")}>
                        <Link to="return-refund">Return & Refund</Link>
                      </li>
                      <li>
                        <Link to="shipping-policy">Shipping Policy</Link>
                      </li>
                      <li>
                        <Link to="privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="#">Payment Options</Link>
                      </li>
                      <li>
                        <Link to="terms-condition">Terms & Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <FootTwo data={cms[8]} />

                  <div className="foot-box">
                    <h4>Social Media</h4>
                    {social.map((item, index) => (
                      <>
                        {item.url && (
                          <a
                            href={`${item.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {faIcon(item.title)}
                          </a>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bottom-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="bottom-f">
                <Email data={contact[0]} />

                <p style={{ float: "right" }}>Copyright © OnClq 2023.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
