import React, { useState, useEffect } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import auth from "../../services/authService";
import hourglass from "../../assets/images/hourglass.gif";

const Approval = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();

  const [account, setAccount] = useState([]);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (auth.getCurrentUser()) {
      let data = {
        ...auth.getCurrentUser(),
        user_id: auth.getCurrentUser().id,
      };
      setAccount(data);
      setUserType(auth.getCurrentUser().user_type);
    }
  }, []);

  if (
    (auth.getCredUser().credEmailKey && auth.getCredUser().credStepKey) === null
  )
    window.location = "/signup";

  return (
    <>
      <section className="new_login_panel d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="login_right_sec" style={{ height: "auto" }}>
                  <div className="app-form-logo">
                    <Link to="/">
                      <img
                        alt="Onclq.com"
                        class="LogoImg"
                        src={appLogo}
                        data-size="mid"
                      />
                    </Link>
                  </div>
                  <h6 className="text-center text-warning">
                    Your account is not approved{" "}
                  </h6>
                  <div
                    className="app-packaging-logo"
                    style={{ display: "flex" }}
                  >
                    <img
                      alt="Hourglass logo"
                      class="PackagingImg"
                      src={hourglass}
                      data-size="mid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Approval;
