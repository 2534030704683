import React, { useState, useRef, useEffect } from "react";
import { TrackerData } from "./../data/trackerData";
import upload from "../../components/assets/images/upload.jpg";
import { toast } from "react-toastify";
import postService from "../services/postService";

const Tracker = ({ handleTracker, tracker, userType }) => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    userType === "Buyer" ? null : 0
  );
  const [currentTrack, setCurrentTrack] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [award, setAward] = useState({
    remarks: "",
    traker_img: "",
  });

  const fetchTrack = async (tracker) => {
    let array3 = TrackerData.filter(
      (obj2) => !tracker.some((obj1) => obj1.title === obj2.steps)
    );
    setCurrentTrack(array3);
  };

  useEffect(() => {
    if (tracker) fetchTrack(tracker);
  }, [tracker]);

  const handleChange = ({ currentTarget: input }) => {
    const awards = { ...award };
    awards[input.name] = input.value;
    setAward(awards);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setAward({ ...award, traker_img: resultedString });
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleImage = (item) => {
    inputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...award,
        steps: currentTrack[0].steps,
        order_id: tracker[0].order_id,
        seller_id: tracker[0].seller_id,
        buyer_id: tracker[0].buyer_id,
      };
      handleTracker(data);
      setOpen(!open);
    } catch (error) {
      toast.error(error);
    }
  };

  function formatDate(date) {
    let currentDate = new Date(date);
    let dateString = currentDate.toDateString(); // "Tue Feb 07 2023"
    return dateString;
  }

  function formatTime(date) {
    let currentTime = new Date(date);
    let timeString = currentTime.toLocaleTimeString(); // "12:00:00 AM"
    return timeString;
  }

  const inputRef = useRef();
  const { remarks } = award;
  const currentDate = new Date();

  return (
    <>
      <button
        type="button"
        className="btn btn-warning btn-sm"
        onClick={() => setOpen(!open)}
      >
        <i className="fa fa-truck" />
      </button>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Tracker Status
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {tracker.map((item, index) => (
                  <>
                    <div className={`order-tracking completed p-1`}>
                      <button
                        className="is-complete"
                        onClick={() => setCurrentStep(index)}
                      ></button>
                      <div className="order-status">
                        <p>{item.title}</p>
                        {/* <p>{item.created_date}</p> */}
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <i class="fa fa-calendar" /> Date:{" "}
                            {formatDate(item.created_date)}{" "}
                          </span>
                          <span>
                            <i className="fa fa-clock-o" /> Time:{" "}
                            {formatTime(item.created_date)}
                          </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>{item.remarks}</p>
                      </div>
                      <div className="order-image">
                        {item.img1 === "" ||
                          (item.img1 !== null && (
                            <>
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={item.img1}
                                alt="Onclq.com"
                              />
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                ))}

                {currentTrack.map((item, index) => (
                  <>
                    <div
                      className={`order-tracking ${
                        index <= currentStep ? "completed" : ""
                      } p-1`}
                    >
                      <button
                        type="button"
                        disabled={index === currentStep ? false : true}
                        className="is-complete"
                        onClick={() => setCurrentStep(index)}
                      ></button>
                      <div className="order-status">
                        <p>{item.steps}</p>
                        {/* <p>{item.date}</p> */}
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <i class="fa fa-calendar" /> Date:{" "}
                            {formatDate(currentDate)}{" "}
                          </span>
                          <span>
                            <i className="fa fa-clock-o" /> Time:{" "}
                            {formatTime(currentDate)}
                          </span>
                        </p>
                        <p
                          style={{
                            display: index === currentStep ? "none" : "block",
                            textAlign: "justify",
                          }}
                        >
                          {item.remarks}
                        </p>
                        <div
                          className="col-md-12 mt-2"
                          style={{
                            display:
                              index === currentStep && userType === "Seller"
                                ? "block"
                                : "none",
                          }}
                        >
                          <label for="bid_specification" className="form-label">
                            Remarks you want to add.
                          </label>
                          <textarea
                            className="form-control"
                            rows="2"
                            name="remarks"
                            value={remarks}
                            placeholder="Your Remarks here...."
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="order-image">
                        {fileUrl && (
                          <img
                            src={index === currentStep ? fileUrl : upload}
                            style={{
                              display:
                                index === currentStep && userType === "Seller"
                                  ? "block"
                                  : "none",
                              width: "100px",
                              height: "100px",
                            }}
                            alt="Preview"
                          />
                        )}
                        {!fileUrl && (
                          <img
                            src={upload}
                            style={{
                              display:
                                index === currentStep && userType === "Seller"
                                  ? "block"
                                  : "none",
                              width: "100px",
                              height: "100px",
                            }}
                            alt="Preview"
                          />
                        )}
                        <input
                          ref={inputRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="image"
                          label="Upload Image"
                          type="file"
                          onChange={(event) => handleFileSelect(event)}
                        />
                        <button
                          type="button"
                          style={{
                            display:
                              index === currentStep && userType === "Seller"
                                ? "block"
                                : "none",
                          }}
                          className="btn btn-attach"
                          onClick={() => handleImage(item)}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>

                    <div
                      className="order-button"
                      style={{
                        display:
                          index === currentStep && userType === "Seller"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <button type="submit" className="btn btn-success btn-sm">
                        {" "}
                        Save{" "}
                      </button>
                    </div>
                  </>
                ))}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracker;
