import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import StarRating from "./StarRating";

const Rate = (props) => {
  const [open, setOpen] = useState(false);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const [account, setAccount] = useState([]);
  const [quality, setQuality] = useState(props.rating[0].quality);
  const [timeline, setTimeline] = useState(props.rating[0].timeline);
  const [pricing, setPricing] = useState(props.rating[0].pricing);
  const [payment_cycle, setPaymentCycle] = useState(
    props.rating[0].payment_cycle
  );
  const [information_share, setInformationShare] = useState(
    props.rating[0].information_share
  );
  const [behaviour, setBehaviour] = useState(props.rating[0].behaviour);

  const handleAccount = () => {
    let data = props.rating[0];
    setAccount(data);
    setOpen(!open);
  };
  const handleClose = () => {
    setAccount([]);
    setOpen(!open);
  };

  const handleValidation = () => {
    if (props.userType === "Buyer") {
      if (quality === null) {
        toast.error("Quality is mandatory.", toastOptions);
        return false;
      } else if (timeline === null) {
        toast.error("Timeline is mandatory.", toastOptions);
        return false;
      } else if (pricing === null) {
        toast.error("Pricing is mandatory.", toastOptions);
        return false;
      }
    } else if (props.userType === "Seller") {
      if (information_share === null) {
        toast.error("Information Share is mandatory.", toastOptions);
        return false;
      } else if (payment_cycle === null) {
        toast.error("Payment Cycle is mandatory.", toastOptions);
        return false;
      } else if (behaviour === null) {
        toast.error("Behaviour is mandatory.", toastOptions);
        return false;
      }
      return true;
    } else if (props.userType === null) {
      toast.error("User Type is mandatory.", toastOptions);
      return false;
    }
    return true;
  };

  const handleRating = () => {
    if (handleValidation()) {
      const data = {
        ...account,
        information_share,
        payment_cycle,
        behaviour,
        quality,
        timeline,
        pricing,
        token: props.token,
        user_type: props.userType,
      };
      props.onClick(data);
      setOpen(!open);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-warning btn-sm"
        onClick={handleAccount}
      >
        <i className="fa fa-star" />
      </button>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Rate Now
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Quality</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating rating={quality} setRating={setQuality} />
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Timeline</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating rating={timeline} setRating={setTimeline} />
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Pricing</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Buyer" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating rating={pricing} setRating={setPricing} />
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Payment Cycle</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating
                    rating={payment_cycle}
                    setRating={setPaymentCycle}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Information Share</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating
                    rating={information_share}
                    setRating={setInformationShare}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <h6>Behaviour</h6>{" "}
                </div>
                <div
                  className="col-md-6"
                  style={{
                    display: props.userType === "Seller" ? "block" : "none",
                  }}
                >
                  {" "}
                  <StarRating rating={behaviour} setRating={setBehaviour} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={
                  props.userType === "Buyer"
                    ? props.rating[0].rating_buyer_status === "0"
                      ? false
                      : true
                    : props.rating[0].rating_seller_status === "0"
                    ? false
                    : true
                }
                type="submit"
                className="btn btn-success"
                onClick={handleRating}
              >
                Yes, Rate
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rate;
