import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import SellerTable from "./SellerTable";
import Pagination from "../../../common/pagination";
import { Link, useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import { CSVLink, CSVDownload } from "react-csv";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";
import NoCart from "../../../common/noCart";
import { toast } from "react-toastify";
import postService from "../../../services/postService";

const SellerInvoice = ({ myAccount }) => {
  const [orders, setOrders] = useState([]);
  const [odr, setOdr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    const data = list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.invoice_date);
      const date2 = new Date(b.invoice_date);
      return date2 - date1;
    });
    let resultedData = sortedDateArray.slice(0, -1);
    setOrders(resultedData);
    setLoading(true);
  };

  const fetchOdr = async () => {
    const order = await location.state.order;
    setOdr(order);
  };

  useEffect(() => {
    fetchOdr();
  }, []);

  const location = useLocation();

  const fetchOrderList = async (myAccount) => {
    const list = await location.state.data;
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(myAccount);
  }, [myAccount]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    const order = paginate(orders, currentPage, pageSize);
    return { totalCount: orders.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (order) => <p>{orders.indexOf(order) + 1}</p>,
    },
    { path: "id", label: "Invoice No" },
    { path: "invoice_description", label: "Description" },
    { path: "gst_amount", label: "GST" },
    { path: "amount", label: "Amount" },

    {
      key: "Invoice",
      label: "Invoice",
      content: (order) => (
        <>
          <Link
            style={{ textAlign: "center" }}
            class="btn btn-info btn-sm"
            to={`/sellerdashboard/Orders/Invoice/${order.id}`}
            state={{ data: order }}
          >
            <i class="fa fa-eye" aria-hidden="true"></i>
          </Link>
          <Link
            style={{ textAlign: "center" }}
            class="btn btn-primary btn-sm"
            to={`/sellerdashboard/Orders/InvoiceForm/${order.id}`}
            state={{ data: order, order: odr }}
          >
            <i class="fa fa-edit" aria-hidden="true"></i>
          </Link>
        </>
      ),
    },
  ];

  if (loading === false) return <Loader />;

  return (
    <>
      {totalCount === 0 && <NoCart />}

      {totalCount !== 0 && (
        <div className="mainContent">
          <Top />
          <div>
            <Link
              to={`/sellerdashboard/Orders/InvoiceForm/new`}
              className="btn btn-outline-primary"
              state={{ order: odr }}
            >
              {" "}
              Create Invoice{" "}
            </Link>
            <CSVLink data={orders} className="btn btn-outline-success">
              Download Report
            </CSVLink>
          </div>
          <SellerTable columns={columns} data={order} />

          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default SellerInvoice;
