import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import getService from "../../services/getService";
import Loader from "./../../common/loader";

const Section1 = lazy(() => import("./Section1"));
const Section2 = lazy(() => import("./Section2"));
const Section3 = lazy(() => import("./Section3"));
const Section4 = lazy(() => import("./Section4"));
const Section5 = lazy(() => import("./Section5"));
const Section6 = lazy(() => import("./Section6"));
const Section7 = lazy(() => import("./Section7"));
const Section8 = lazy(() => import("./Section8"));
const Section9 = lazy(() => import("./Section9"));
const Section10 = lazy(() => import("./Section10"));
const Section11 = lazy(() => import("./Section11"));
const LeadModal = lazy(() => import("./LeadModal"));

const Home = (props) => {
  props.funcNav(true);
  props.funcFooter(true);

  const navigate = useNavigate();

  const [cms, setCMS] = useState([]);
  const [loading, setLoading] = useState(false);
  const [services, setService] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategory] = useState([]);
  const [clients, setClients] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [hotproducts, setHotProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [requirement, setRequirement] = useState([]);
  const [banners, setBanners] = useState([]);
  const [headings, setHeading] = useState([]);
  const [supports, setSupport] = useState([]);
  const [contact, setContact] = useState([]);
  const LeadOpem = localStorage.getItem("contact_lead");

  const fetchBanner = async () => {
    const data = await getService.handleBanner();
    setBanners(data);
    setLoading(true);
  };

  const fetchCMS = async () => {
    const data = await getService.handleCMSList();
    setCMS(data);
    setLoading(true);
  };

  const fetchContact = async () => {
    const data = await getService.handleContact();
    setContact(data);
    setLoading(true);
  };

  const fetchHeading = async () => {
    const data = await getService.handleHeading();
    setHeading(data);
    setLoading(true);
  };

  const fetchRequirement = async () => {
    const list = await getService.handleRequirement({ user_id: "", token: "" });
    setRequirement(list.post_requirement_list);
    setLoading(true);
  };

  const fetchService = async () => {
    const data = await getService.handleServiceList();
    setService(data);
    setLoading(true);
  };

  const fetchProducts = async () => {
    const category = await getService.handleSubCategoryList();
    setProducts(category);
    setLoading(true);
  };

  const fetchCategory = async () => {
    const category = await getService.handleCategoryList();
    const filter = category.filter((f) => f.title !== null);
    setCategory(filter);
    setLoading(true);
  };

  const fetchClient = async () => {
    const data = await getService.handleClientList();
    setClients(data);
    setLoading(true);
  };

  const fetchSupport = async () => {
    const data = await getService.handleSupport();
    setSupport(data);
    setLoading(true);
  };

  const fetchTestimonial = async () => {
    const data = await getService.handleTestimonials();
    setTestimonials(data);
    setLoading(true);
  };

  const fetchHotProduct = async () => {
    const data = await getService.handleHotProducts();
    setHotProducts(data);
    setLoading(true);
  };

  const fetchUsers = async () => {
    const user = await getService.handleUserList();
    setUsers(user);
    setLoading(true);
  };

  let data = props.myAccount;
  let userType = props.userType;

  // if (data && userType) {
  //   if (userType === "Buyer") navigate("/buyerdashboard");
  //   if (userType === "Seller") navigate("/sellerdashboard");
  //   if (userType === "Admin") navigate("/admin");
  // }

  useEffect(() => {
    fetchCMS();
  }, []);

  useEffect(() => {
    fetchRequirement();
  }, []);

  useEffect(() => {
    fetchContact();
  }, []);

  useEffect(() => {
    fetchService();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchHeading();
  }, []);

  useEffect(() => {
    fetchClient();
  }, []);

  useEffect(() => {
    fetchSupport();
  }, []);

  useEffect(() => {
    fetchTestimonial();
  }, []);

  useEffect(() => {
    fetchHotProduct();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchBanner();
  }, []);

  if (loading === false) return <Loader />;
  return (
    <>
      <div className="home">
        <Section1 data={banners} />
        {!LeadOpem && (
          <Suspense fallback={<Loader />}>
            <LeadModal LeadOpem={LeadOpem} heading={headings} />
          </Suspense>
        )}{" "}
        {/* <Section2
          users={users}
          categories={categories}
          products={products}
          requirement={requirement}
        /> */}
        <Section3 data={cms[0]} />
        <Section4 data={services} heading={headings[0]} />
        <Section5 data={categories} heading={headings[1]} />
        <Section6 data={cms[1]} />
        <Section7 data={hotproducts} heading={headings[3]} />
        <Section8 data={clients} heading={headings[2]} />
        <Section9 data={supports} heading={headings[5]} />
        <Section10 data={testimonials} heading={headings[4]} />
        <Section11 data={cms} heading={headings} contact={contact[0]} />
      </div>
    </>
  );
};

export default Home;
