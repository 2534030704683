import PersonalDetails from "../application/Card/Forms/PersonalDetails";
import CompanyDetails from "../application/Card/Forms/CompanyDetails";
import PlantDetails from './../application/Card/Forms/PlantDetails';
import BankDetails from './../application/Card/Forms/BankDetails';
import ImagesSeller from './../application/Card/Forms/ImagesSeller';

import { BsFilePersonFill} from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import { GiPlantsAndAnimals } from 'react-icons/gi';
import { RiBankCardFill } from 'react-icons/ri';
import { FaImages } from 'react-icons/fa';


export const SellerFormData = [
    {id: 0, name: "Personal Details", form: <PersonalDetails />, ReactIcons: <BsFilePersonFill className="icon" /> },
    {id: 1, name: "Company Details",  form: <CompanyDetails />, ReactIcons: <HiUserGroup className="icon" /> },
    {id: 2, name: "Plant Details",  form: <PlantDetails />, ReactIcons: <GiPlantsAndAnimals className="icon" /> },
    {id: 3, name: "Bank details",  form: <BankDetails />, ReactIcons: <RiBankCardFill className="icon" />},
    {id: 4, name: "Images",  form: <ImagesSeller />, ReactIcons: <FaImages className="icon" />},
]