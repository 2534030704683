import React, {useState} from 'react';
import Top from './Top Section/Top'
import PlantDetails from './../../Card/Forms/PlantDetails';
import { SignUpData } from '../../../data/signUpData';

const SellerPlants = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
        <PlantDetails account={account} setAccount={setAccount} myAccount={myAccount} />
      </div>
    </div>
  )
}

export default SellerPlants