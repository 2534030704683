import axios from "axios";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

let userType = "userType";
let user_data = "User Data";
let tokenSigninKey = "Signin";
let tokenSignupKey = "Signup";

async function adminLogin(account) {
  await axios
    .post("/api/adminlogin", account)
    .then((res) => {
      if (res.data.msg === "User does not Exist") {
        toast.info("User does not Exist");
      } else if (res.data.user_data[0].msg === "successfully login") {
        const item = JSON.stringify(res.data.user_data[0]);
        const user = btoa(item);
        localStorage.setItem("myProfile", user);
        localStorage.setItem("userType", "Admin");
        localStorage.setItem("credEmailKey", account.username);
        localStorage.setItem("credStepKey", "Admin");

        toast.success("Successfully Login");
        window.location = "/admin";
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function login(email, password) {
  await axios
    .post("/api/signin", { email, password })
    .then((res) => {
      console.log("response", res);
      if (res.data.msg === "User does not Exist") {
        // User does not exist
        toast.info("User does not Exist");
      } else if (res.data.msg === "Your account is not approved") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        localStorage.setItem("credEmailKey", email);
        localStorage.setItem("credStepKey", "New User");
        localStorage.setItem("myProfile", jwt);

        toast.info("Your account is not approved");
        window.location = "/myProfile";
      } else if (res.data.msg === "successfully login") {
        // User Exist
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        localStorage.setItem("credEmailKey", email);
        localStorage.setItem("credStepKey", "New User");
        localStorage.setItem("myProfile", jwt);
        if (res.data.user_data.profile_complete === "No") {
          toast.warning("Complete your Profile.");
          window.location = "/myProfile";
        } else {
          const userType = res.data.user_data.user_type;
          const status = res.data.user_data.status;

          if (status === "Approved") {
            localStorage.setItem("userType", userType);

            if (userType === "Buyer") {
              window.location = "/buyerdashboard";
            } else if (userType === "Seller") {
              window.location = "/sellerdashboard";
            }

            toast.success("Successfully login");
          } else {
            window.location = "/approval";
          }
        }
      } else if (res.data.user_data[0].msg === "Your account is not verified") {
        // If User exist, but account not verified
        localStorage.setItem("credStepKey", "New User");
        localStorage.setItem("credEmailKey", email);
        toast.info("Your account is not verified");
        window.location = "/authorization";
      }
      // System Fails
      else if (res.data.msg === "System Fail") {
        toast.error("System Fail");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function signup(account, credStep) {
  console.log("sign up data", account);
  let updatedData = [];
  await axios
    .post("/api/signup", account)
    .then((res) => {
      console.log("response", res);

      if (res.data.msg === "user password updated") {
        toast.success("Successfully Updated");
      }

      // User Already Exist
      else if (res.data.msg === "User Already Exist") {
        toast.info("User Already Exist");
      }

      // User Personal Details Updated Successfully
      else if (res.data.msg === "Personal details updated successfully") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        let credStep = localStorage.getItem("credStepKey");
        if (credStep === "New User") {
          updatedData = res.data.user_data;
          if (updatedData) {
            localStorage.setItem("myProfile", jwt);
            toast.success("Personal details updated successfully");
          }
        }
      }

      // Company Details Updated Successfully
      else if (res.data.msg === "Company details updated successfully") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        let credStep = localStorage.getItem("credStepKey");
        if (credStep === "New User") {
          updatedData = res.data.user_data;
          if (updatedData) {
            localStorage.setItem("myProfile", jwt);
            toast.success("Company details updated successfully");
          }
        }
      }
      // Plant Details Updated Successfully
      else if (res.data.msg === "Plant details updated successfully") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        let credStep = localStorage.getItem("credStepKey");
        if (credStep === "New User") {
          updatedData = res.data.user_data;
          if (updatedData) {
            localStorage.setItem("myProfile", jwt);
            toast.success("Plant details updated successfully");
          }
        }
      }
      // Bank Details Updated Successfully
      else if (res.data.msg === "Bank details updated successfully") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        let credStep = localStorage.getItem("credStepKey");
        if (credStep === "New User") {
          updatedData = res.data.user_data;
          if (updatedData) {
            localStorage.setItem("myProfile", jwt);
            toast.success("Bank details updated successfully");
          }
        }
      }
      // All Images Details Updated Successfully && Profile is Verfied
      else if (res.data.msg === "All Images updated successfully") {
        const item = JSON.stringify(res.data?.user_data);
        const encoder = new TextEncoder();
        const data = encoder.encode(item);
        const jwt = btoa(String.fromCharCode(...data));
        let credStep = localStorage.getItem("credStepKey");

        if (credStep === "New User") {
          updatedData = res.data.user_data;
          if (updatedData) {
            localStorage.setItem("myProfile", jwt);
            localStorage.setItem("userType", account.user_type);
            toast.success("All Images inserted successfully");
          }
        }

        const BUYER_DASHBOARD_URL = "/buyerdashboard";
        const SELLER_DASHBOARD_URL = "/sellerdashboard";
        const APPROVAL_URL = "/approval";

        if (updatedData.user_type) {
          switch (updatedData.user_type) {
            case "Buyer":
              window.location.href = BUYER_DASHBOARD_URL;
              break;
            case "Seller":
              window.location.href = SELLER_DASHBOARD_URL;
              break;
            default:
              break;
          }
        } else if (updatedData.status === "Approved") {
          window.location.href = APPROVAL_URL;
        }
      }

      // Successfully Registered User
      else if (res.data.user_data[0].msg === "successfully registration") {
        console.log("res signup", res);

        localStorage.setItem("credStepKey", credStep);
        localStorage.setItem("credEmailKey", account.email);
        toast.success("Successfully Registration");
        window.location = "/authorization";
      }

      // Successfully Registered User By Admin
      else if (res.data.user_data[0].msg === "Admin User Registration") {
        console.log("res signup", res);
        toast.success("Successfully User Registration");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => {});
  return updatedData;
}

async function forgot_password(account, credStep) {
  await axios
    .post("/api/sendOTPEmail", account)
    .then((res) => {
      if (res.data.msg === "Email does not exist") {
        toast.info("Email doesnot exist");
      } else if (res.data.msg === "Email should not be empty") {
        toast.info("Email should not be empty");
      } else if (
        res.data.msg === "otp send successfully. kindly check spam folder"
      ) {
        localStorage.setItem("credStepKey", credStep);
        localStorage.setItem("credEmailKey", account.email);
        toast.success("OTP send successfully. Kindly check spam folder");
        window.location = "/authorization";
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function change_password(account) {
  await axios
    .post("/api/forgot_password", account)
    .then((res) => {
      if (res.data.msg === "Email id does not exist") {
        toast.info("Email id does not exist");
      } else if (res.data.msg === "Email & Password should be required") {
        toast.info("Email & Password should be required");
      } else if (res.data.msg === "password updated successfully") {
        toast.success("Password Updated successfully");
        localStorage.removeItem("credStepKey");
        localStorage.removeItem("credEmailKey");
        window.location = "/signin";
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function verify_otp(account, credStep) {
  await axios
    .post("/api/verify_email", account)
    .then((res) => {
      if (res.data.msg === "Otp doesnot exist") {
        // OTP doesn't exist.
        toast.info("Otp doesnot exist");
      } else if (res.data.msg === "email verified successfully") {
        // Email Verified Successfully.

        console.log("res verify otp", res);
        toast.success("Email Verified Successfully");
        if (credStep === "Forgot Password") {
          // User is registered, but forgot password.
          localStorage.setItem("credStepKey", "Create Password");
          window.location = "/createpassword";
        } else if (credStep === "New User") {
          // New User is registered, untill the profile is completed
          const item = JSON.stringify(res.data?.user_data);
          const encoder = new TextEncoder();
          const data = encoder.encode(item);
          const jwt = btoa(String.fromCharCode(...data));
          localStorage.setItem("myProfile", jwt);
          localStorage.setItem("credStepKey", "New User");
          window.location = "/myProfile";
        } else {
          toast.error("System Fail");
        }

        // localStorage.setItem(tokenSignupKey, jwt);
        // window.location = "/checkoutsignup";
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("myProfile");
    return jwtDecode(jwt, { header: true });
  } catch (ex) {
    return null;
  }
}

function getCredUser() {
  try {
    const credStepKey = localStorage.getItem("credStepKey");
    const credEmailKey = localStorage.getItem("credEmailKey");
    return { credStepKey, credEmailKey };
  } catch (ex) {
    return null;
  }
}

function getSigninJwt() {
  return localStorage.getItem(tokenSigninKey);
}

function getSignupJwt() {
  return localStorage.getItem(tokenSignupKey);
}

function signout() {
  localStorage.removeItem("credStepKey");
  localStorage.removeItem("credEmailKey");
  localStorage.removeItem("userType");
  localStorage.removeItem("myProfile");
}

async function PostSubAdmin(account) {
  console.log("sub admin", account);
  await axios
    .post("/api/insert_update_admin", account)
    .then((res) => {
      console.log("sub admin response", res);
      if (res.data.msg === "admin updated successfully") {
        toast.success("Admin Updated Successfully");
      } else if (res.data.msg === "admin inserted successfully") {
        toast.success("Admin Inserted Successfully");
      } else if (
        res.data.msg ===
        "Username, Deisgnation, Image or Description is missing"
      ) {
        toast.info("Username, Deisgnation, Image or Description is missing");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

export default {
  login,
  adminLogin,
  signout,
  signup,
  change_password,
  verify_otp,
  forgot_password,
  getCurrentUser,
  getSigninJwt,
  getSignupJwt,
  getCredUser,
  PostSubAdmin,
};
