import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import NoImage from "../../../assets/images/NoImage.png";
import Delete from "../../../common/delete";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import SearchBox from "./../../../common/searchBox";

const LeadsTable = () => {
  const [leads, setLeads] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);

  const fetchLeads = async () => {
    const data = await getService.handleLeads();
    setLeads(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteTestimonial({ id });
      setCurrentPage(1);
      fetchLeads();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    let filtered = leads;
    if (searchQuery)
      filtered = leads.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else filtered = leads;

    const lead = paginate(filtered, currentPage, pageSize);
    return { totalCount: leads.length, data: lead };
  };
  const { length: count } = leads;

  const { totalCount, data: lead } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (lead) => <p>{leads.indexOf(lead) + 1}</p>,
    },
    { path: "name", label: "Name" },
    // { path: "email", label: "Email" },
    { path: "mobile", label: "Mobile" },
    // { path: "lead_type", label: "Lead_Type" },
    { path: "message", label: "Message" },

    // {
    //   path: "logo",
    //   label: "Logo",
    //   content: (c) => (
    //     <>
    //       {c.img1 !== null ? (
    //         <img
    //           style={{ width: "100px", height: "100px" }}
    //           src={c.img1}
    //           alt="Onclq.com"
    //         />
    //       ) : (
    //         <img
    //           style={{ width: "100px", height: "100px" }}
    //           src={NoImage}
    //           alt="Onclq.com"
    //         />
    //       )}
    //     </>
    //   ),
    // },

    {
      key: "Action",
      label: "Action",
      content: (lead) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            // onClick={() => {
            //   navigate(`/admin/testimonialform/${testimonial.id}`);
            // }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          {/* <Delete
            id={id}
            ItemId={testimonial.id}
            setId={setId}
            onClick={handleDelete}
          /> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchLeads();
  }, []);

  if (count === 0) return <p>There are no orders in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Leads List </h4>
              </div>
              <CSVLink data={leads} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <SearchBox value={searchQuery} onChange={handleSearch} />
              </div>
            </div>

            <div class="panel-body">
              <Table columns={columns} data={lead} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default LeadsTable;
