import React, { useState, useEffect } from "react";
import BidForm from "./BidForm";
import BidTable from "./BidTable";
import getService from "../../../services/getService";

const BidList = (props) => {
  const [open, setOpen] = useState(false);
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("btn-1");
  const [pageSize, setPageSize] = useState(10);
  const [selectedGenre, setSelectedGenre] = useState("Active");

  const ZeroBids = () => {
    setBids([]);
    setLoading(true);
  };

  const AllBids = (list) => {
    const data = list.post_requirement_bid_list.map(
      ({
        seller_name: name,
        bid_specification: description,
        bid_price: price,
        ...rest
      }) => ({ name, description, price, ...rest })
    );
    const filteredArray = data.sort((a, b) => {
      const date1 = a.price;
      const date2 = b.price;
      return date1 - date2;
    });
    setBids(filteredArray);

    setLoading(true);
  };

  const fetchBids = async (props) => {
    console.log("propps", props);
    const list = await getService.handleBids({
      user_id: props.item.user_id,
      token: props.item.token,
      post_requirement_id: props.id,
    });
    list.msg === "No record found" ? ZeroBids() : AllBids(list);
  };

  useEffect(() => {
    if (props.id) fetchBids(props);
  }, [props]);

  console.log("props", props);
  console.log("bids", bids);

  const handleId = () => {
    props.setId(props.item.id);
    setOpen(!open);
  };

  const handleClose = () => {
    props.setId(null);
    setOpen(!open);
  };

  const handleBid = (bid) => {
    props.onClick(bid);
    setOpen(!open);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-warning btn-xs m-b-5 custom_btn"
        onClick={handleId}
      >
        <i className="fa fa-snowflake-o " aria-hidden="true"></i>
      </button>

      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Bids Details
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <section className="tabContainer" style={{ height: "50px" }}>
                <div className="container">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          currentPage === "btn-1"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        onClick={() => setCurrentPage("btn-1")}
                      >
                        Add New
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          currentPage === "btn-2"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        onClick={() => setCurrentPage("btn-2")}
                      >
                        Bids
                      </button>
                    </li>
                  </ul>
                </div>
              </section>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  style={{
                    display: currentPage === "btn-1" ? "contents" : "none",
                  }}
                >
                  <BidForm requirement={props.item} handleBid={handleBid} />
                </div>
                <div
                  className="tab-pane fade"
                  style={{
                    display: currentPage === "btn-2" ? "contents" : "none",
                  }}
                >
                  <BidTable bids={bids} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidList;
