import React from 'react';
import { Link } from 'react-router-dom';


const User = () => {
  return (
    <>
      <div class="se-pre-con"></div> 
         <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="small-box bg-green whitecolor">
                    <div class="inner">
                        <h4><span class="count-number">155</span></h4>
                        <p>Total Users</p>
                    </div> 
                    <div class="icon">
                         <i class="fa fa-users"></i>
                    </div>
                          <Link to="#" class="small-box-footer">Total Users</Link>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="small-box bg-pase whitecolor">
                    <div class="inner">
                        <h4><span class="count-number">21</span></h4>
                        <p>Total Product</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-shopping-bag"></i>
                    </div>
                        <Link to="#" class="small-box-footer">Total Product</Link>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="small-box bg-bringal whitecolor">
                    <div class="inner">
                        <h4><span class="count-number">3</span></h4>
                        <p>Total Supplier</p>
                    </div>
                <div class="icon">
                    <i class="fa fa-user"></i>
                    </div>
                        <Link to="#" class="small-box-footer">Total Supplier </Link>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="small-box bg-darkgreen whitecolor">
                    <div class="inner">
                        <h4><span class="count-number">12</span> </h4>
                        <p>Total Sale</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-money"></i>
                    </div>
                        <Link to="#" class="small-box-footer">Total Sale </Link>
                </div>
             </div>
           </div>
    </>
  )
}

export default User