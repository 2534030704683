import React from "react";

const Email = ({ data }) => {
  if (!data) return null;
  return (
    <p style={{ float: "left" }}>
      Email Us :{" "}
      <a style={{ color: "#fff" }} href={`mailto: ${data.email}`}>
        {data.email}
      </a>{" "}
    </p>
  );
};

export default Email;
