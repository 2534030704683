import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../../utils/paginate";
import SellerTable from "./../SellerTable";
import { getSellingData } from "../../../../data/SampleSellingData";

const TopSellingSKUData = () => {
  const [orders, setOrders] = useState(getSellingData());

  const navigate = useNavigate();

  const getPagedData = () => {
    const sortedDateArray = orders.sort((a, b) => {
      const date1 = new Date(a.date);
      const date2 = new Date(b.date);
      return date2 - date1;
    });
    const order = paginate(sortedDateArray, 1, 5);
    return { totalCount: sortedDateArray.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  if (totalCount === 0) return <p>There are no orders in the database.</p>;

  const columns = [
    { path: "sellingCode", label: "Sale ID" },
    { path: "skuCode", label: "SKU ID" },
    { path: "quantity", label: "Quantity" },
    { path: "Status", label: "Status" },
  ];

  return (
    <>
      <SellerTable columns={columns} data={order} />
    </>
  );
};

export default TopSellingSKUData;
