import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SortReqData } from "../../../../data/sortReqData";
import { paginate } from "../../../../utils/paginate";
import Select from "./../../../../common/select";
import RequirementCard from "./../../../Card/Requirement/RequirementCard";
import Pagination from "./../../../../common/pagination";

const Recent = ({ requirement, myAccount }) => {
  const navigate = useNavigate();

  const handleNavigate = (item) => {
    navigate(`/sellerdashboard/ViewRequirementsForm/${item.id}`);
  };

  return (
    <>
      <RequirementCard
        requirement={requirement}
        onNavigate={handleNavigate}
        myAccount={myAccount}
      />
    </>
  );
};

export default Recent;
