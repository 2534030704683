import React, { useState, useEffect } from "react";
import Select from "./../../../../common/select";
import getService from "../../../../services/getService";

const ChatForm = ({ currentUser, changeCurrentChat, handleSendMsg }) => {
  const [orders, setOrders] = useState([]);
  const [msg, setMsg] = useState("");
  const [account, setAccount] = useState({
    optionValue: "",
  });

  const [optionValue, setOptionValue] = useState("");

  const ZeroOrder = () => {
    setOrders([]);
  };

  const AllOrder = (list) => {
    const data = list.order_list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    console.log("data", data);
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.order_date);
      const date2 = new Date(b.order_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(filter => filter.status === "Active");
    setOrders(sortedDateArray);
  };

  const fetchOrderList = async (currentUser) => {
    const list = await getService.handleSellerOrderList({
      user_id: currentUser.id,
      token: currentUser.token,
    });
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(currentUser);
  }, [currentUser]);

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
    }
  };

  const orderOption = orders.map(({ id, ...rest }) => ({
    name: `#Order Id-${id}`,
    ...rest,
  }));

  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    let string = accounts["optionValue"]; // use accounts instead of account
    if (string != null) {
      let match = string.match(/\d+/);
      if (match != null) {
        let number = parseInt(match[0]);
        let result = orders.find((item) => item.id === String(number));
        changeCurrentChat(number, result);
      } else {
        console.log("No digits found in the string");
      }
    } else {
      console.log("Option value is null or undefined");
    }
    setAccount(accounts); // use accounts instead of account
  };

  return (
    <>
      <form onSubmit={(event) => sendChat(event)}>
        <div className="secContainer grid" style={{ gap: "0.5rem" }}>
          <Select
            options={orderOption}
            label="Select Order Id"
            name="optionValue"
            value={account.optionValue}
            onChange={handleChange}
          />
          <label className="form-label">
            We apologize for any inconvenience caused. <br />
            Please share more details about your query. <br />
            Our executive will assist you soon. <br />
            Thank you for your patience...!!
          </label>
          <textarea
            placeholder="Type a message"
            name="Message"
            rows={5}
            class="form-control"
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
          />
          <div className="d-flex justify-content-end">
            <button
              style={{ width: "max-content" }}
              type="submit"
              class="btn btn-outline-success"
            >
              Send Request
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChatForm;
