import React, { useState, useEffect } from "react";
import { getOrders } from "./../../../../data/SampleOrderData";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../../utils/paginate";
import getService from "../../../../services/getService";
import Loader from "../../../../common/loader";
import BuyerTable from "./../BuyerTable";

const NewOrderData = ({ myAccount }) => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ZeroOrder = () => {
    setOrders([]);
    setLoading(true);
  };

  const AllOrder = (list) => {
    const data = list.order_list.map(({ target_price: price, ...rest }) => ({
      price,
      ...rest,
    }));
    console.log("data", data);
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.order_date);
      const date2 = new Date(b.order_date);
      return date2 - date1;
    });
    const order = paginate(sortedDateArray, 1, 5);

    // const filteredArray = sortedDateArray.filter(filter => filter.status === "Active");
    setOrders(order);
    setLoading(true);
  };

  const fetchOrderList = async (myAccount) => {
    const list = await getService.handleSellerOrderList({
      user_id: myAccount.id,
      token: myAccount.token,
    });
    list === null ? ZeroOrder() : AllOrder(list);
  };

  useEffect(() => {
    fetchOrderList(myAccount);
  }, [myAccount]);

  const getPagedData = () => {
    const order = paginate(orders, currentPage, pageSize);
    return { totalCount: orders.length, data: order };
  };

  const { totalCount, data: order } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (order) => <p>{orders.indexOf(order) + 1}</p>,
    },
    // {path: "customer", label: "Customer"},
    { path: "post_requirement_id", label: "Order_Id" },
    { path: "order_date", label: "Date" },
    { path: "amount", label: "Amount" },
  ];

  if (loading === false) return <Loader />;
  if (totalCount === 0) return;

  return (
    <>
      <h5>New Order</h5>
      <BuyerTable columns={columns} data={order} />
    </>
  );
};

export default NewOrderData;
