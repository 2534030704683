import React from "react";

const BuyerDashboardContent = ({ data }) => {
  if (!data) return null;

  return (
    <>
      <h1>{data.title}</h1>
      <div
        style={{ display: "contents" }}
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </>
  );
};

export default BuyerDashboardContent;
