import React, { useState, useEffect } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import googlePlayStore from "../../assets/form/google-play-store-badge.webp";
import appleAppStore from "../../assets/form/apple-app-store-badge.webp";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Input from "./../../common/input";
import Joi from "joi-browser";
import auth from "../../services/authService";
import { toast } from "react-toastify";

const CreatePassword = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();

  const [account, setAccount] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  let credStep = "";

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string().required().label("Password"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      await auth.change_password(account);
      // window.location = "/userdashboard";
    } catch (error) {
      toast.error(error);
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { email, password } = account;

  useEffect(() => {
    account.email = auth.getCredUser().credEmailKey;
    credStep = auth.getCredUser().credStepKey;
  });

  if (
    (auth.getCredUser().credEmailKey && auth.getCredUser().credStepKey) === null
  )
    window.location = "/signup";

  // if(auth.getCurrentUser()) return <Navigate to="/userdashboard" />

  return (
    <>
      <section className="d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="app-login">
              <div className="app-form-logo">
                <div className="app-form-logo-content">
                  <Link to="/">
                    <picture>
                      <source srcset={appLogo} />
                      <img
                        alt="Freelancer logo"
                        class="LogoImg"
                        src={appLogo}
                        data-size="mid"
                      />
                    </picture>
                  </Link>
                </div>
              </div>

              <form className="app-form" onSubmit={handleSubmit}>
                <h2 className="text-align-center">Welcome Back</h2>
                <Input
                  disabled
                  type="email"
                  placeholder="Email or Username"
                  name="email"
                  label="Email"
                  error={errors.email}
                  value={email}
                  onChange={handleChange}
                />
                <Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  label="New Password"
                  error={errors.password}
                  value={password}
                  onChange={handleChange}
                />
                <button
                  type="submit"
                  className="btn app-login-button"
                  disabled={validate()}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatePassword;
