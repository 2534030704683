import React, { useState, useEffect, useRef } from "react";
import Loader from "../../common/loader";
import getService from "../../services/getService";

const ReturnRefund = (props) => {
  props.funcNav(true);
  props.funcFooter(true);
  const [cms, setCMS] = useState([]);
  const [loading, setLoading] = useState(false);
  const top = useRef(null);

  const fetchCMS = async () => {
    const data = await getService.handleCMSList();
    setCMS(data);
    setLoading(true);
  };

  useEffect(() => {
    fetchCMS();
  }, []);

  if (loading === false) return <Loader />;

  return (
    <>
      <section class="policy-section" ref={top} id="top">
        <div class="container">
          <div class="row">
            <div class="policy-box">
              <h1 style={{ textAlign: "center" }}>
                Return and Refund Policy for Onclq
              </h1>
              <div dangerouslySetInnerHTML={{ __html: cms[2].description }} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnRefund;
