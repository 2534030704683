import Joi from "joi-browser";

export const PlantSchema = {
  user_id: Joi.optional().allow(""),
  name: Joi.optional().allow(""),
  designation: Joi.optional().allow(""),
  mobile: Joi.optional().allow(""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.optional().allow(""),
  signup_step: Joi.optional().allow(""),
  user_type: Joi.optional().allow(""),
  address: Joi.optional().allow(""),
  token: Joi.optional().allow(""),
  company_name: Joi.optional().allow(""),
  company_type: Joi.optional().allow(""),
  business_type: Joi.optional().allow(""),
  company_registration_date: Joi.optional().allow(""),
  gst_no: Joi.optional().allow(""),
  gst_pic: Joi.optional().allow(""),
  cin_no: Joi.optional().allow(""),
  cin_pic: Joi.optional().allow(""),
  pan_no: Joi.optional().allow(""),
  pan_pic: Joi.optional().allow(""),
  plant_type: Joi.string()
    .required()
    .error(() => {
      return { message: "Type of plant is required" };
    }),
  plant_pic: Joi.optional().allow(""),
  plant_capacity: Joi.optional().allow(""),
  profile_complete: Joi.optional().allow(""),
  available_capacity: Joi.optional().allow(""),
  machinary_type: Joi.optional().allow(""),
  machine_pic: Joi.optional().allow(""),
  no_of_machine: Joi.optional().allow(""),
  printing_facility: Joi.optional().allow(""),
  lamination_facility: Joi.optional().allow(""),
  uv_coating: Joi.optional().allow(""),
  other_facility: Joi.optional().allow(""),
  bank_name: Joi.optional().allow(""),
  account_holder_name: Joi.optional().allow(""),
  account_no: Joi.optional().allow(""),
  ifsc_code: Joi.optional().allow(""),
  branch_address: Joi.optional().allow(""),
  payment_terms: Joi.optional().allow(""),
  credit_facility: Joi.optional().allow(""),
  cancel_cheque_no: Joi.optional().allow(""),
  cancel_cheque_pic: Joi.optional().allow(""),
  company_area_pic: Joi.optional().allow(""),
  outside_with_board_pic: Joi.optional().allow(""),
  inside_of_plant_pic: Joi.optional().allow(""),
  company_logo: Joi.optional().allow(""),
  visiting_card_pic: Joi.optional().allow(""),
  comp_file: Joi.optional().allow(""),
  city_id: Joi.optional().allow(""),
  city_name: Joi.optional().allow(""),
  country_id: Joi.optional().allow(""),
  country_name: Joi.optional().allow(""),
  created_date: Joi.optional().allow(""),
  id: Joi.optional().allow(""),
  otp: Joi.optional().allow(""),
  phone: Joi.optional().allow(""),
  pincode: Joi.optional().allow(""),
  state_id: Joi.optional().allow(""),
  state_name: Joi.optional().allow(""),
  status: Joi.optional().allow(""),
  to_be_verified: Joi.optional().allow(""),
  updated_date: Joi.optional().allow(""),
};
