import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Input from "./../../../common/input";
import getService from "../../../services/getService";
import postService from "../../../services/postService";
import { toast } from "react-toastify";
import TextArea from "./../../../common/TextArea";

const ServiceForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [account, setAccount] = useState({
    id: "",
    title: "",
    description: "",
  });

  const [textArea, setTextArea] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
  };

  const PopulateForm = async () => {
    try {
      const Id = params.id;
      if (Id === "new") return;

      const collection = await getService.handleServiceList();
      const data = await getService.getData(collection, Id);
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    PopulateForm();
  }, []);

  const mapToViewModel = (data) => {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postService.PostServices({ ...account, description: textArea });
    } catch (error) {
      toast.error(error);
    }
  };

  const { title } = account;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Edit Content </h4>
              </div>
            </div>
            <div class="panel-body">
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div className="col-md-6">
                    <div className="col-sm-12 mt-2">
                      <Input
                        name="title"
                        label="Title"
                        value={title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div class="col-md-12 mt-2">
                      <label for="description" class="form-label">
                        Description.
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <TextArea
                      setTextArea={setTextArea}
                      textInput={account.description}
                    />
                    <p className="td-words-linit">4000 character left.</p>
                  </div>

                  <div class="col-md-1 offset-md-11">
                    <button className="btn btn-success">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceForm;
