import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Input from "../../../common/input";
import { PersonalSchema } from "./../../../data/PersonalSchema";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";

const PersonalDetails = ({
  account,
  setAccount,
  currentStep,
  myAccount,
  updateStep,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState({});

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, PersonalSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: PersonalSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "personal",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulatePerson = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    PopulatePerson();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const { name, designation, email, mobile } = account;

  return (
    <>
      <section className="personaldetails">
        <div className="container">
          <h3>Personal Details</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  name="name"
                  label="Name of Person"
                  value={name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="designation"
                  label="Designation"
                  value={designation}
                  error={errors.designation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="mobile"
                  label="Contact number"
                  value={mobile}
                  error={errors.mobile}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  name="email"
                  disabled={true}
                  label="Email Id"
                  value={email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    className="primaryButton"
                    type="button"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    // disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>

                  <button
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    onClick={() => navigate("/membership")}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default PersonalDetails;
