import React, { useState, useEffect, useRef } from "react";
import ChatList from "./ChatList/ChatList";
import ChatPanel from "./ChatList/ChatPanel";
import { ChatListData } from "./../../../data/chatListData";
import getService from "../../../services/getService";
import ChatWelcome from "./ChatList/ChatWelcome";
import postService from "../../../services/postService";
import { animateScroll } from "react-scroll";

const SellerConcern = ({ myAccount }) => {
  const [orderList, setOrderList] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState([]);
  const chatRef = useRef(null);

  function scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "chat-container",
      duration: 500, // adjust duration as needed
      delay: 0,
      smooth: "easeInOutQuint", // use easing function for smooth scrolling
      isDynamic: true, // optimize for dynamic content
      useRAF: false, // use requestAnimationFrame for smoother animation
    });

    if (chatRef.current) {
      chatRef.current.lastChild.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  function handleClick() {
    scrollToBottom();
  }

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
    setMessages([]);
  };
  const fetchData = async () => {
    const response = await postService.PostGetMessage({
      to:
        currentChat.to === undefined
          ? currentChat.admininfo.id
          : currentChat.to,
      from: myAccount.id,
      order_id:
        currentChat.order_id === undefined
          ? currentChat.id
          : currentChat.order_id,
    });
    setMessages(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (myAccount) {
        const list = await getService.handleTicketUserMsgs({
          user_id: myAccount.id,
        });
        console.log("list", list);
        setOrderList(list);
        setIsLoaded(true);
      }
    };
    const intervalId = setInterval(fetchData, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [myAccount]);

  const handleSendMsg = async (msg) => {
    await postService.PostMessage({
      ticket_id: "",
      from: myAccount.id,
      to:
        currentChat.to === undefined
          ? currentChat.admininfo.id
          : currentChat.to,
      order_id:
        currentChat.order_id === undefined
          ? currentChat.id
          : currentChat.order_id,
      message: msg,
    });
    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
    await fetchData();
    scrollToBottom();
  };

  useEffect(() => {
    const intervalId = setInterval(fetchData, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentChat, myAccount]);

  return (
    <>
      <div className="mainContent">
        <div className="row desktop-chat">
          <div className="col-md-5">
            <div className="chat-list">
              
              <ChatList
                orderList={orderList}
                currentUser={myAccount}
                changeChat={handleChatChange}
                messages={messages}
                setMessages={setMessages}
                handleSendMsg={handleSendMsg}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="chat-panel">
              {isLoaded && currentChat === undefined ? (
                <ChatWelcome />
              ) : (
                <ChatPanel
                  chatRef={chatRef}
                  handleClick={handleClick}
                  messages={messages}
                  setMessages={setMessages}
                  currentChat={currentChat}
                  currentUser={myAccount}
                  setCurrentChat={setCurrentChat}
                  handleSendMsg={handleSendMsg}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row mobile-chat">
          <div className="col-md-5">
            {currentChat == undefined && (
              <div className="chat-list">
                <ChatList
                  orderList={orderList}
                  currentUser={myAccount}
                  changeChat={handleChatChange}
                  messages={messages}
                  setMessages={setMessages}
                  handleSendMsg={handleSendMsg}
                />
              </div>
            )}
          </div>
          <div className="col-md-7">
            <div className="chat-panel">
              {isLoaded && currentChat !== undefined && (
                <ChatPanel
                  chatRef={chatRef}
                  handleClick={handleClick}
                  messages={messages}
                  setMessages={setMessages}
                  currentChat={currentChat}
                  currentUser={myAccount}
                  setCurrentChat={setCurrentChat}
                  handleSendMsg={handleSendMsg}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerConcern;
