import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import Recent from "./components/Recent";
import getService from "../../../services/getService";
import Loader from "./../../../common/loader";
import { paginate } from "../../../utils/paginate";
import Pagination from "./../../../common/pagination";
import Select from "./../../../common/select";
import { MyRequirementData } from "../../../data/MyRequirementData";
import NoCart from "./../../../common/noCart";
import SearchBox from "./../../../common/searchBox";

const MyBids = ({ myAccount }) => {
  const [bids, setBids] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedGenre, setSelectedGenre] = useState("Active");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const ZeroBids = () => {
    setBids([]);
    setLoading(true);
  };

  const AllBids = (list) => {
    const data = list.post_requirement_bid_list.map(
      ({
        buyer_name: name,
        bid_specification: description,
        bid_price: price,
        ...rest
      }) => ({ name, description, price, ...rest })
    );
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.created_date);
      const date2 = new Date(b.created_date);
      return date2 - date1;
    });

    const data2 = sortedDateArray.map(
      ({ id: bid_id, post_requirement_id: id, ...rest }) => ({
        bid_id,
        id,
        ...rest,
      })
    );

    setBids(data2);
    setLoading(true);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const fetchMyBids = async (myAccount) => {
    const list = await getService.handleBids({
      user_id: myAccount.id,
      token: myAccount.token,
      post_requirement_id: "",
    });

    list.msg === "No record found" ? ZeroBids() : AllBids(list);
  };

  useEffect(() => {
    if (myAccount) fetchMyBids(myAccount);
  }, [myAccount]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = bids;

    if (searchQuery)
      filtered = bids.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre)
      filtered = bids.filter((m) => m.status === selectedGenre);
    else filtered = bids;
    const required = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: required };
  };

  if (loading === false) return <Loader />;

  const { totalCount, data: required } = getPagedData();

  console.log("required", required);

  return (
    <>
      <div className="mainContent">
        <Top />

        <div className="row">
          <div className="col-md-12 p-4">
            <div className="bRequirementList">
              <ul class="list-group">
                {bids.length !== 0 && (
                  <>
                    <li class="list-group-item">
                      <div className="bRequirementList">
                        <h3>Showing {totalCount} entries in the Database</h3>
                        <div className="select">
                          <Select
                            name="req_name"
                            value={selectedGenre}
                            options={MyRequirementData}
                            onChange={(e) =>
                              setSelectedGenre(e.currentTarget.value)
                            }
                          />
                        </div>
                      </div>
                      <SearchBox value={searchQuery} onChange={handleSearch} />
                    </li>
                  </>
                )}

                <li class="list-group-item" style={{ padding: 0 }}>
                  <Recent myAccount={myAccount} requirement={required} />
                </li>

                {totalCount === 0 && (
                  <li class="list-group-item">
                    <NoCart />
                  </li>
                )}

                {totalCount !== 0 && (
                  <li class="list-group-item">
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default MyBids;
