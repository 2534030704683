import React, { useState, useEffect } from "react";
import appLogo from "../../assets/navigation-menu/BlackLogo.png";
import googlePlayStore from "../../assets/form/google-play-store-badge.webp";
import appleAppStore from "../../assets/form/apple-app-store-badge.webp";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Input from "./../../common/input";
import Joi from "joi-browser";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import getService from "../../services/getService";
import Loader from "./../../common/loader";

const Signin = (props) => {
  props.funcNav(false);
  props.funcFooter(false);

  let navigate = useNavigate();

  const [account, setAccount] = useState({
    email: "",
    password: "",
  });

  const [contact, setContact] = useState([]);
  const [cms, setCMS] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isVerified, setVerified] = useState(true);

  const [errors, setErrors] = useState({});

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string().required().label("Password"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      await auth.login(account.email, account.password);
    } catch (error) {
      toast.error(error);
    }
  };

  const onCaptcha = (value) => {
    console.log("Captcha value:", value);
    value !== null ? setVerified(false) : setVerified(true);
  };

  const fetchCMS = async () => {
    const data = await getService.handleCMSList();
    setCMS(data);
    setLoading(true);
  };

  const fetchContact = async () => {
    const data = await getService.handleContact();
    setContact(data);
    setLoading(true);
  };

  const { email, password } = account;

  let data = props.myAccount;
  let userType = props.userType;

  if (data && userType) {
    if (userType === "Buyer") navigate("/buyerdashboard");
    if (userType === "Seller") navigate("/sellerdashboard");
    if (userType === "Admin") navigate("/admin");
  }

  useEffect(() => {
    fetchCMS();
  }, []);

  useEffect(() => {
    fetchContact();
  }, []);

  if (auth.getCurrentUser()) return <Navigate to="/" />;
  if (loading === false) return <Loader />;

  return (
    <>
      <section className="new_login_panel d-flex h-100 pt-2">
        <div className="container">
          <div className="app-form-card mt-3">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-sm-12">
                <form className="app-form" onSubmit={handleSubmit}>
                  <h4>{cms[11]?.title}</h4>
                  <p>Welcome Back</p>
                  <Input
                    type="email"
                    placeholder="Email or Username"
                    name="email"
                    label="Email"
                    error={errors.email}
                    value={email}
                    onChange={handleChange}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    label="Password"
                    error={errors.password}
                    value={password}
                    onChange={handleChange}
                  />
                  <div className="app-form-checkbox">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        style={{ paddingRight: "20px" }}
                        class="form-check-label"
                        for="flexCheckDefault"
                      >
                        Remember me
                      </label>
                    </div>
                    <Link to="/forgotpassword">Forgot Password </Link>
                  </div>
                  <ReCAPTCHA
                    sitekey="6Lf2lfckAAAAADpbqtaeW9YDWj9yO-5kF8p6_Jsz"
                    onChange={onCaptcha}
                  />
                  <button
                    type="submit"
                    className="btn app-login-button"
                    disabled={isVerified}
                  >
                    Log in
                  </button>
                  <div className="app-form-forgot-password">
                    <p class="text-align-center">
                      Don't have an account ??
                      <Link to="/signup">Signup</Link>
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="login_right_sec">
                  <div className="app-form-logo">
                    <Link to="/">
                      <img
                        alt="Onclq.com"
                        class="LogoImg"
                        src={contact[0]?.img1}
                        data-size="mid"
                      />
                    </Link>
                  </div>
                  <div className="app-packaging-logo">
                    <img
                      alt="Packaging logo"
                      class="PackagingImg"
                      src={cms[11]?.img1}
                      data-size="mid"
                    />
                  </div>
                  <div className="right_login_con">
                    <p
                      dangerouslySetInnerHTML={{ __html: cms[11]?.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signin;
