import axios from "axios";
import { toast } from "react-toastify";

async function DeletePostRequirements(account) {
  await axios
    .post("/api/delete_post_requirements", account)
    .then((res) => {
      if (res.data.msg === "Post Requirement deleted successfully") {
        toast.success("Post Requirement deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteSubscription(account) {
  await axios
    .post("/api/delete_subscription", account)
    .then((res) => {
      if (res.data.msg === "Subscription deleted successfully") {
        toast.success("Subscription deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteService(account) {
  await axios
    .post("/api/delete_services", account)
    .then((res) => {
      if (res.data.msg === "Service deleted successfully") {
        toast.success("Service deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteHomeBanner(account) {
  await axios
    .post("/api/delete_home_banner", account)
    .then((res) => {
      if (res.data.msg === "Home Banner deleted successfully") {
        toast.success("Home Banner deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function DeleteClient(account) {
  await axios
    .post("/api/delete_client", account)
    .then((res) => {
      if (res.data.msg === "Client deleted successfully") {
        toast.success("Client deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function DeleteHotProduct(account) {
  await axios
    .post("/api/delete_hot_product", account)
    .then((res) => {
      if (res.data.msg === "Hot Product deleted successfully") {
        toast.success("Hot Product deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function DeleteCategory(account) {
  await axios
    .post("/api/delete_category", account)
    .then((res) => {
      if (res.data.msg === "Category deleted successfully") {
        toast.success("Category deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteSubCategory(account) {
  await axios
    .post("/api/delete_subcategory", account)
    .then((res) => {
      if (res.data.msg === "Sub category deleted successfully") {
        toast.success("Sub Category Deleted Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteSupSubCategory(account) {
  await axios
    .post("/api/delete_super_subcategory", account)
    .then((res) => {
      if (res.data.msg === "Super Sub category deleted successfully") {
        toast.success("Super Sub Category Deleted Successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function DeleteBusinessType(account) {
  await axios
    .post("/api/delete_business_type", account)
    .then((res) => {
      if (res.data.msg === "Business Type deleted successfully") {
        toast.success("Business Type deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

async function DeleteUser(account) {
  await axios
    .post("/api/delete_users", account)
    .then((res) => {
      if (res.data.msg === "User deleted successfully") {
        toast.success("User deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteTestimonial(account) {
  await axios
    .post("/api/delete_testimonial", account)
    .then((res) => {
      if (res.data.msg === "Testimonial deleted successfully") {
        toast.success("Testimonial deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}
async function DeleteSupport(account) {
  await axios
    .post("/api/delete_support", account)
    .then((res) => {
      if (res.data.msg === "Support deleted successfully") {
        toast.success("Support deleted successfully");
      } else {
        toast.error("System Fail");
      }
    })
    .catch((err) => toast.error(err.message));
}

export default {
  DeletePostRequirements,
  DeleteSubscription,
  DeleteService,
  DeleteHomeBanner,
  DeleteClient,
  DeleteHotProduct,
  DeleteCategory,
  DeleteSubCategory,
  DeleteSupSubCategory,
  DeleteBusinessType,
  DeleteUser,
  DeleteTestimonial,
  DeleteSupport,
};
