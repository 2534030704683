import React from 'react';

const ProjectForm = () => {
  return (
   <>
   
      <div class="row">
            <div class="col-sm-12">
                <div class="panel panel-bd lobidrag">
                     <div class="panel-heading">
                          <div class="panel-title">
                                <h4>Add Project </h4>
                          </div>
                     </div>
                     <div class="panel-body">
                        
                     <form class="" id="customer_form" method="post" accept-charset="utf-8">
<input type="hidden" name="customer_id" id="customer_id" value="" />
<div class="form-group row">
<label for="customer_name" class="col-sm-2 text-left col-form-label">Customer Name <i class="text-danger"> * </i>:</label>
<div class="col-sm-4">
<div class="">
<input type="text" name="customer_name" class="form-control" id="customer_name" placeholder="Customer Name" value="" />
<input type="hidden" name="old_name" value="" />
</div>
</div>
<label for="customer_mobile" class="col-sm-2 text-left col-form-label">Mobile No <i class="text-danger"> </i>:</label>
<div class="col-sm-4">
<div class="">
<input type="number" name="customer_mobile" class="form-control input-mask-trigger text-left" id="customer_mobile" placeholder="Mobile No" value="" data-inputmask="'alias': 'decimal', 'groupSeparator': '', 'autoGroup': true" im-insert="true" />
</div>
</div>
</div>
<div class="form-group row">
<label for="customer_email" class="col-sm-2 text-left col-form-label">Email Address:</label>
<div class="col-sm-4">
<div class="">
<input type="text" class="form-control input-mask-trigger" name="customer_email" id="email" data-inputmask="'alias': 'email'" im-insert="true" placeholder="Email" value="" />
</div>
</div>
<label for="email_address" class="col-sm-2 text-left col-form-label">VAT NO:</label>
<div class="col-sm-4">
<div class="">
<input type="text" class="form-control" name="email_address" id="email_address" placeholder="VAT NO" value="" />
</div>
</div>
</div>
<div class="form-group row">
<label for="phone" class="col-sm-2 text-left col-form-label">Phone:</label>
<div class="col-sm-4">
<div class="">
<input class="form-control input-mask-trigger text-left" id="phone" type="number" name="phone" placeholder="Phone" data-inputmask="'alias': 'decimal', 'groupSeparator': '', 'autoGroup': true" im-insert="true" value="" />
</div>
</div>
<label for="contact" class="col-sm-2 text-left col-form-label">CR NO&nbsp;
<span data-toggle="tooltip" data-placement="top" title="Commercial Registration Number">
<i class="fa fa-question-circle" aria-hidden="true"></i>
</span> :
</label>
<div class="col-sm-4">
<div class="">
<input class="form-control" id="contact" type="text" name="contact" placeholder="CR NO" value="" />
</div>
</div>
</div>
<div class="form-group row">
<label for="address1" class="col-sm-2 text-left col-form-label">Address1:</label>
<div class="col-sm-4">
<div class="">
<textarea name="customer_address" id="customer_address" class="form-control" placeholder="Address1"></textarea>
</div>
</div>
<label for="address2" class="col-sm-2 text-left col-form-label">Address2:</label>
<div class="col-sm-4">
<div class="">
<textarea name="address2" id="address2" class="form-control" placeholder="Address2"></textarea>
</div>
</div>
</div>
<div class="form-group row">
<label for="fax" class="col-sm-2 text-left col-form-label">Fax:</label> 
<div class="col-sm-4">
<div class="">
<input type="text" name="fax" class="form-control" id="fax" placeholder="Fax" value="" />
</div>
</div>
<label for="city" class="col-sm-2 text-left col-form-label">City:</label>
<div class="col-sm-4">
<div class="">
<input type="text" name="city" class="form-control" id="city" placeholder="City" value="" /> 
</div>
</div>
</div>
<div class="form-group row">
<label for="state" class="col-sm-2 text-left col-form-label">State:</label> 
<div class="col-sm-4">
<div class="">
<input type="text" name="state" class="form-control" id="state" placeholder="State" value="" /> 
</div>
</div>
<label for="zip" class="col-sm-2 text-left col-form-label">Zip code:</label>
<div class="col-sm-4">
<div class="">
<input name="zip" type="text" class="form-control" id="zip" placeholder="Zip code" value="" /> 
</div>
</div>
</div>
<div class="form-group row">
<label for="country" class="col-sm-2 text-left col-form-label">Country:</label>
<div class="col-sm-4">
<div class="">
<input name="country" type="text" class="form-control " placeholder="Country" value="" id="country" />
</div>
</div>

</div>
<div class="form-group row">
<div class="col-sm-6 text-left">
</div>
<div class="col-sm-6 text-left">
<div class="">
<button type="button" class="btn btn-success">
Save</button>
</div>
</div>
</div>
</form>

                     </div>
                </div>
            </div>
      </div>
   </>
  )
}

export default ProjectForm