import React, {useState} from 'react';
import Top from './Top Section/Top';
import BankDetails from './../../Card/Forms/BankDetails';
import { SignUpData } from '../../../data/signUpData';

const BuyerBank = ({myAccount}) => {
  const [account, setAccount] = useState(SignUpData);

  return (
    <div className='mainContent'>
      <Top/>

      <div className='bottom flex'>
      <BankDetails account={account} setAccount={setAccount} myAccount={myAccount} />
      </div>
    </div>
  )
}

export default BuyerBank