import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const BuyerPrivateRoute = (props) => {
  const { Component } = props;
  const userType = props.userType;
  const myAccount = props.myAccount;

  if (userType === "Buyer" && myAccount && myAccount.status === "Approved") {
    return <Component {...props} />;
  } else {
    return <Navigate to={"/signin"} />;
  }
};

export default BuyerPrivateRoute;
