import React from "react";
import Top from "./Top Section/Top";
import Graphs from "./Graph Section/Graphs";

const BuyerBody = ({ myAccount }) => {
  return (
    <div className="mainContent">
      <Top />
      <Graphs myAccount={myAccount} />
    </div>
  );
};

export default BuyerBody;
