import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import { useParams } from "react-router-dom";
import RequirementList from "./components/RequirementList";
import BidList from "./components/BidList";
import getService from "../../../services/getService";
import Loader from "../../../common/loader";

const PostRequirementForm = ({ myAccount }) => {
  const params = useParams();
  const post_requirement_id = params.id;
  const [requirement, setRequirement] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRequirement = async (myAccount) => {
    const list = await getService.handleRequirement({
      user_id: myAccount.id,
      token: myAccount.token,
    });

    const collection = list.post_requirement_list;
    const data = await getService.getData(collection, post_requirement_id);
    setRequirement(data);
    setLoading(true);
  };

  useEffect(() => {
    if (myAccount) fetchRequirement(myAccount);
  }, [myAccount]);

  let status = requirement.status;
  if (loading === false) return <Loader />;

  return (
    <>
      <div className="mainContent">
        <Top />

        <section className="tabContainer" style={{ height: "50px" }}>
          <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Dashboard
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="bids-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bids"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Bids
                </button>
              </li>
            </ul>
          </div>
        </section>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <RequirementList
              post_requirement_id={post_requirement_id}
              requirement={requirement}
              setRequirement={setRequirement}
              myAccount={myAccount}
            />
          </div>
          <div
            className="tab-pane fade"
            id="bids"
            role="tabpanel"
            aria-labelledby="bids-tab"
          >
            <BidList
              post_requirement_id={post_requirement_id}
              status={status}
              myAccount={myAccount}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostRequirementForm;
