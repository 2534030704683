import React, { useState } from "react";
import Input from "./input";
import Checkbox from "./checkbox";

const PostBid = ({
  bid_price,
  bid_specification,
  credit_no_of_days,
  onChange,
  open,
  setOpen,
  errors,
  onValidate,
  creditFacility,
  setCreditFacility,
}) => {
  return (
    <>
      <div
        className={open ? "modal fade show" : "modal fade"}
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Post Requirement
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpen(!open)}
              ></button>
            </div>
            <div className="modal-body postBid">
              <Input
                name="bid_price"
                value={bid_price}
                label="Bid Price"
                onChange={onChange}
                type="number"
                error={errors.bid_price}
              />
              <div className="row">
                <div className="col-md-12 mt-2">
                  <label for="bid_specification" className="form-label">
                    Tell us more about your specification.
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    name="bid_specification"
                    value={bid_specification}
                    placeholder="Describe your bid specification here...."
                    onChange={onChange}
                    error={errors.bid_specification}
                  />
                  <p className="td-words-linit">4000 character left.</p>
                </div>

                <div className="col-md-12 mt-2">
                  <div className="row">
                    <div className="col-md-8 mt-3">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor="creditFacility"> Credit Facility</label>
                        <div>
                          <Checkbox
                            label="Yes"
                            type="radio"
                            name="creditFacility"
                            value="Yes"
                            onChange={() => setCreditFacility("Yes")}
                          />
                          <Checkbox
                            label="No"
                            type="radio"
                            name="creditFacility"
                            value="No"
                            onChange={() => setCreditFacility("No")}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-4"
                      style={{
                        display: creditFacility === "Yes" ? "block" : "none",
                      }}
                    >
                      <Input
                        type="number"
                        label="No Of Days"
                        placeholder="No of Days"
                        name="credit_no_of_days"
                        error={errors.credit_no_of_days}
                        value={credit_no_of_days}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setOpen(!open)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={onValidate()}
                  onClick={() => setOpen(!open)}
                >
                  Yes, post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostBid;
