import React, { useState, useEffect } from "react";
import Top from "./Top Section/Top";
import Filter from "./components/Filter";
import Recent from "./components/Recent";
import getService from "../../../services/getService";
import { paginate } from "./../../../utils/paginate";
import Pagination from "./../../../common/pagination";
import Loader from "./../../../common/loader";
import NoCart from "../../../common/noCart";
import { MyRequirementData } from "./../../../data/MyRequirementData";
import Select from "./../../../common/select";
import { RiMenuFoldLine } from "react-icons/ri";
import SearchBox from "./../../../common/searchBox";

const ViewRequirement = ({ myAccount }) => {
  const [requirement, setRequirement] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [quantity, setQuantity] = useState("");
  const [material, setMaterial] = useState("");
  const [product, setProduct] = useState("");
  const [timeline, setTimeline] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("Active");
  const [searchQuery, setSearchQuery] = useState("");

  const ZeroRequirement = () => {
    setRequirement([]);
    setLoading(true);
  };

  const AllRequirement = (list) => {
    const data = list.post_requirement_list.map(
      ({ target_price: price, ...rest }) => ({
        price,
        ...rest,
      })
    );
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.created_date);
      const date2 = new Date(b.created_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(
    //   (filter) => filter.status === "Active"
    // );
    setRequirement(sortedDateArray);
    setLoading(true);
  };

  const fetchRequirement = async () => {
    const list = await getService.handleRequirement({ user_id: "", token: "" });
    list.msg === "No record found" ? ZeroRequirement() : AllRequirement(list);
  };

  useEffect(() => {
    fetchRequirement();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLocation = (query) => {
    setLocation(query);
    setCurrentPage(1);
  };

  const handleQuantity = (query) => {
    setQuantity(query);
    setCurrentPage(1);
  };

  const handleMaterial = (query) => {
    setMaterial(query);
    setCurrentPage(1);
  };

  const handleProduct = (query) => {
    setProduct(query);
    setCurrentPage(1);
  };

  const handleTimeline = (query) => {
    setTimeline(query);
    setCurrentPage(1);
  };

  const handleMax = (query) => {
    setMaxValue(query);
    setCurrentPage(1);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleMin = (query) => {
    setMinValue(query);
    setCurrentPage(1);
  };

  const getPagedData = () => {
    let filtered = requirement;

    if (location)
      filtered = requirement.filter((m) =>
        m.location !== null
          ? m.location.toLowerCase().includes(location.toLowerCase())
          : null
      );
    else if (quantity)
      filtered = requirement.filter((m) => Number(m.quantity) <= quantity);
    else if (material)
      filtered = requirement.filter((m) =>
        m.cat_name.toLowerCase().startsWith(material.toLowerCase())
      );
    else if (minValue && maxValue)
      filtered = requirement.filter(
        (m) => Number(m.price) >= minValue && Number(m.price) <= maxValue
      );
    else if (timeline)
      filtered = requirement.filter((m) => m.active_till_date <= timeline);
    else if (searchQuery)
      filtered = requirement.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre)
      filtered = requirement.filter((m) => m.status === selectedGenre);
    else filtered = requirement;

    const required = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: required };
  };

  const { totalCount, data: required } = getPagedData();

  if (loading === false) return <Loader />;

  return (
    <>
      {/* {totalCount === 0 && <NoCart />} */}
      <div className="mainContent">
        <Top />

        <div className="row">
          <div className="col-md-8">
            <div className="bRequirementList">
              <ul class="list-group list-heading">
                {requirement.length !== 0 && (
                  <>
                    <li class="list-group-item">
                      <div className="bRequirementList">
                        <h3>Showing {totalCount} entries in the Database</h3>
                        <div className="select">
                          <Select
                            name="req_name"
                            value={selectedGenre}
                            options={MyRequirementData}
                            onChange={(e) =>
                              setSelectedGenre(e.currentTarget.value)
                            }
                          />
                          <button
                            class="btn btn-light btn-filter"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            style={{ borderRadius: "16px" }}
                          >
                            <RiMenuFoldLine />
                          </button>
                          <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel"
                          >
                            <div class="offcanvas-header">
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="offcanvas-body">
                              <Filter
                                location={location}
                                quantity={quantity}
                                material={material}
                                product={product}
                                timeline={timeline}
                                minValue={minValue}
                                maxValue={maxValue}
                                handleLocation={handleLocation}
                                handleQuantity={handleQuantity}
                                handleMaterial={handleMaterial}
                                handleProduct={handleProduct}
                                handleTimeline={handleTimeline}
                                handleMin={handleMin}
                                handleMax={handleMax}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <SearchBox value={searchQuery} onChange={handleSearch} />
                    </li>
                  </>
                )}
              </ul>
              <ul class="list-group list">
                <li class="list-group-item" style={{ padding: 0 }}>
                  <Recent requirement={required} myAccount={myAccount} />
                </li>

                {totalCount === 0 && (
                  <li class="list-group-item">
                    <NoCart />
                  </li>
                )}
                {totalCount !== 0 && (
                  <li class="list-group-item">
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <Filter
              location={location}
              quantity={quantity}
              material={material}
              product={product}
              timeline={timeline}
              minValue={minValue}
              maxValue={maxValue}
              handleLocation={handleLocation}
              handleQuantity={handleQuantity}
              handleMaterial={handleMaterial}
              handleProduct={handleProduct}
              handleTimeline={handleTimeline}
              handleMin={handleMin}
              handleMax={handleMax}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRequirement;
