import axios from "axios";
import { toast } from "react-toastify";

async function getData(collection, id) {
  return collection.find((m) => m.id === id);
}

async function handleCategory() {
  return await axios
    .get("/api/subscription_list")
    .then((res) => res.data.supersubcategory)
    .catch((err) => toast.err(err));
}

async function handleCategoryList() {
  return await axios
    .get("/api/category_list")
    .then((res) => res.data.category)
    .catch((err) => toast.err(err));
}

async function handleSubCategoryList() {
  return await axios
    .get("/api/subcategory_list")
    .then((res) => res.data.subcategory)
    .catch((err) => toast.err(err));
}

async function handleSuperSubCategoryList() {
  return await axios
    .get("/api/super_subcategory_list")
    .then((res) => res.data.supersubcategory)
    .catch((err) => toast.err(err));
}

async function handleUserList() {
  return await axios
    .get("/api/users_list")
    .then((res) => res.data.users_list)
    .catch((err) => toast.err(err));
}

async function handleRequirement(myAccount) {
  return await axios
    .post("/api/post_requirement_list", myAccount)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
async function handleSellerOrderList(myAccount) {
  return await axios
    .post("/api/order_list", myAccount)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

async function handleLeadgerList(myAccount) {
  return await axios
    .post("/api/leadger", myAccount)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

async function handleBids(Bid) {
  return await axios
    .post("/api/bid_list", Bid)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

async function handleBanner() {
  return await axios
    .get("/api/home_banner_list")
    .then((res) => res.data.banner_list)
    .catch((err) => console.log(err));
}

async function handleHeading() {
  return await axios
    .get("/api/heading_list")
    .then((res) => res.data.heading_list)
    .catch((err) => console.log(err));
}

async function handleBusinessTypeList() {
  return await axios
    .get("/api/business_type_list")
    .then((res) => res.data.business_type_list)
    .catch((err) => console.log(err));
}

async function handleCMSList() {
  return await axios
    .get("/api/cms_list")
    .then((res) => res.data.cms_list)
    .catch((err) => toast.err(err));
}

async function handleSocialList() {
  return await axios
    .get("/api/social_list")
    .then((res) => res.data.social)
    .catch((err) => toast.err(err));
}

async function handleContentList() {
  return await axios
    .get("/api/content_list")
    .then((res) => res.data.content_list)
    .catch((err) => toast.err(err));
}

async function handleContact() {
  return await axios
    .get("/api/contact_list")
    .then((res) => res.data.contact)
    .catch((err) => toast.err(err));
}

async function handleServiceList() {
  return await axios
    .get("/api/services_list")
    .then((res) => res.data.services_list)
    .catch((err) => toast.err(err));
}

async function handleClientList() {
  return await axios
    .get("/api/client_list")
    .then((res) => res.data.client_list)
    .catch((err) => toast.err(err));
}

async function handleTestimonials() {
  return await axios
    .get("/api/testimonial_list")
    .then((res) => res.data.testimonial_list)
    .catch((err) => toast.err(err));
}

async function handleHotProducts() {
  return await axios
    .get("/api/hot_product_list")
    .then((res) => res.data.hotproduct_list)
    .catch((err) => toast.err(err));
}

async function handleSupport() {
  return await axios
    .get("/api/support_list")
    .then((res) => res.data.support_list)
    .catch((err) => toast.err(err));
}

async function handleContacts() {
  return await axios
    .get("/api/contact_list_new")
    .then((res) => res.data.contact_list)
    .catch((err) => toast.err(err));
}

async function handleLeads() {
  return await axios
    .get("/api/lead_list")
    .then((res) => res.data.lead_list)
    .catch((err) => toast.err(err));
}

async function handleModules() {
  return await axios
    .get("/api/module_list")
    .then((res) => res.data.module_list)
    .catch((err) => toast.err(err));
}
async function handleAdmin() {
  return await axios
    .get("/api/admin_list")
    .then((res) => res.data.admin_list)
    .catch((err) => toast.err(err));
}

async function handleAdminUserMsgList() {
  try {
    const res = await axios.get("/api/getAllUserMsgs");
    return res.data;
  } catch (error) {
    toast.error(error);
  }
}

async function handleTicketUserMsgs(myAccount) {
  return await axios
    .post("/api/getTicketUserMsgs", myAccount)
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export default {
  getData,
  handleCategory,
  handleCategoryList,
  handleCMSList,
  handleHotProducts,
  handleHeading,
  handleBanner,
  handleBusinessTypeList,
  handleRequirement,
  handleSellerOrderList,
  handleSubCategoryList,
  handleSuperSubCategoryList,
  handleSocialList,
  handleContact,
  handleServiceList,
  handleContentList,
  handleUserList,
  handleBids,
  handleSupport,
  handleClientList,
  handleTestimonials,
  handleLeads,
  handleContacts,
  handleLeadgerList,
  handleModules,
  handleAdmin,
  handleAdminUserMsgList,
  handleTicketUserMsgs,
};
