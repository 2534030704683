import React, { useState, useEffect } from "react";
import getService from "../services/getService";
import Select from "./select";
import Input from "./input";

const Categories = ({
  post,
  setPost,
  onChange,
  cat_name,
  subcat_name,
  super_subcat_name,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubcategoryList] = useState([]);
  const [superSubCategoryList, setSuperSubCategoryList] = useState([]);

  const fetchCategoryList = async () => {
    const category = await getService.handleCategoryList();
    const newCategory = category.map(({ title: name, ...rest }) => ({
      name,
      ...rest,
    }));
    const filter = newCategory.filter((f) => f.name !== null || "");
    setCategoryList(filter);
  };

  const fetchSubCategoryList = async (post_category) => {
    const subcategory = await getService.handleSubCategoryList();
    const newSubCategory = subcategory.map(({ title: name, ...rest }) => ({
      name,
      ...rest,
    }));
    const filtered = newSubCategory.filter(
      (subCat) => subCat.cat_name === post_category
    );
    setSubcategoryList(filtered);
  };

  const fetchSuperSubCategoryList = async (post_subCategory) => {
    const supersubcategory = await getService.handleSuperSubCategoryList();
    const newSuperSubCategory = supersubcategory.map(
      ({ title: name, ...rest }) => ({ name, ...rest })
    );
    const filtered = newSuperSubCategory.filter(
      (supsubCat) => supsubCat.subcat_name === post_subCategory
    );
    setSuperSubCategoryList(filtered);
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    if (post.cat_name) fetchSubCategoryList(post.cat_name);
  }, [post.cat_name]);

  useEffect(() => {
    if (post.subcat_name) fetchSuperSubCategoryList(post.subcat_name);
  }, [post.subcat_name]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Select
            label="Category"
            name="cat_name"
            value={cat_name}
            options={categoryList}
            onChange={onChange}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Sub Category"
            name="subcat_name"
            value={subcat_name}
            options={subCategoryList}
            onChange={onChange}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Sup Sub Category"
            name="super_subcat_name"
            value={super_subcat_name}
            options={superSubCategoryList}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="row">
        {cat_name === "Others" && (
          <div className="col-md-4">
            <Input
              label="Category"
              placeholder="Category"
              name="cat_name"
              value={cat_name}
              options={categoryList}
              onChange={onChange}
            />
          </div>
        )}

        {subcat_name === "Others" && (
          <div className="col-md-4">
            <Input
              label="Sub Category"
              placeholder="Sub Category"
              name="subcat_name"
              value={subcat_name}
              options={subCategoryList}
              onChange={onChange}
            />
          </div>
        )}

        {super_subcat_name === "Others" && (
          <div className="col-md-4">
            <Input
              label="Sup Sub Category"
              placeholder="Sup Sub Category"
              name="super_subcat_name"
              value={super_subcat_name}
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Categories;
