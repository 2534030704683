import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SellerFormData } from "./../../data/SellerFormData";
import PersonalDetails from "./../Card/Forms/PersonalDetails";
import CompanyDetails from "./../Card/Forms/CompanyDetails";
import PlantDetails from "./../Card/Forms/PlantDetails";
import BankDetails from "./../Card/Forms/BankDetails";
import ImagesSeller from "../Card/Forms/ImagesSeller";

const SellerPanel = ({ account, setAccount, userType }) => {
  let navigate = useNavigate();

  const [currentStep, updateCurrentStep] = useState(0);

  const updateStep = (step) => {
    updateCurrentStep(step);
  };

  return (
    <>
      <div class="steps">
        <div class="steps-body">
          {SellerFormData.map((item, index) => (
            <>
              <div
                class={currentStep === index ? "step step-active" : "step"}
                // onClick={() => updateStep(index)}
              >
                <span class="step-icon">{item.ReactIcons}</span>
                {item.name}
              </div>
            </>
          ))}
        </div>
      </div>

      <div class="steps-header">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            style={{
              width: `${(100 / SellerFormData.length) * (currentStep + 1)}%`,
            }}
            aria-valuenow="40"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <div
        style={{ display: currentStep === 0 ? "block" : "none" }}
        class="tab-1 "
      >
        <PersonalDetails
          account={account}
          setAccount={setAccount}
          updateStep={updateStep}
          currentStep={currentStep}
          formData={SellerFormData}
          updateCurrentStep={updateCurrentStep}
        />
      </div>
      <div
        style={{ display: currentStep === 1 ? "block" : "none" }}
        class="tab-1 "
      >
        <CompanyDetails
          account={account}
          setAccount={setAccount}
          updateStep={updateStep}
          currentStep={currentStep}
          formData={SellerFormData}
          updateCurrentStep={updateCurrentStep}
        />
      </div>
      <div
        style={{ display: currentStep === 2 ? "block" : "none" }}
        class="tab-1 "
      >
        <PlantDetails
          account={account}
          setAccount={setAccount}
          updateStep={updateStep}
          currentStep={currentStep}
          formData={SellerFormData}
          updateCurrentStep={updateCurrentStep}
        />
      </div>
      <div
        style={{ display: currentStep === 3 ? "block" : "none" }}
        class="tab-1 "
      >
        <BankDetails
          account={account}
          setAccount={setAccount}
          updateStep={updateStep}
          currentStep={currentStep}
          formData={SellerFormData}
          updateCurrentStep={updateCurrentStep}
        />
      </div>
      <div
        style={{ display: currentStep === 4 ? "block" : "none" }}
        class="tab-1 "
      >
        <ImagesSeller
          account={account}
          setAccount={setAccount}
          updateStep={updateStep}
          currentStep={currentStep}
          formData={SellerFormData}
          updateCurrentStep={updateCurrentStep}
          userType={userType}
        />
      </div>
    </>
  );
};

export default SellerPanel;
