import React, { useState, useRef, useEffect } from "react";
import Input from "./../../../common/input";
import { Link, useParams } from "react-router-dom";
import PackagingandDesign from "../../../assets/images/PackagingandDesign.webp";
import Select from "./../../../common/select";
import { BoxStyle } from "./../../../data/boxStyle";
import { PolyMaterialType } from "./../../../data/polyMaterialType";
import { PolyMicronType } from "./../../../data/polyMicronType";
import { PolyStyle } from "./../../../data/polyStyle";
import Checkbox from "./../../../common/checkbox";
import postService from "../../../services/postService";
import Categories from "./../../../common/categories";
import { Badge } from "react-bootstrap";
import PostReq from "./../../../common/PostReq";
import { toast } from "react-toastify";
import { Units } from "../../../data/Units";
import Joi from "joi-browser";
import { PostRequirementSchema } from "./../../../data/postRequirementSchema";
import Gsm from "./../../../common/gsm";
import getService from "../../../services/getService";
import PostReqContent from "./../../../common/postReqContent";
import { ViewRequirementsData } from "./../../../data/ViewRequirementsData";

const MAX_COUNT = 5;

const BidForm = () => {
  const [post, setPost] = useState(ViewRequirementsData);

  const [users, setUsers] = useState([]);
  const [account, setAccount] = useState([]);

  const [requirements, setRequirements] = useState([]);
  const [userrequirements, setUserRequirements] = useState([]);

  const [creditFacility, setCreditFacility] = useState(false);

  const fetchRequirement = async () => {
    const list = await getService.handleRequirement({ user_id: "", token: "" });
    const data = list.post_requirement_list.map(
      ({ target_price: price, ...rest }) => ({
        price,
        ...rest,
      })
    );
    const sortedDateArray = data.sort((a, b) => {
      const date1 = new Date(a.created_date);
      const date2 = new Date(b.created_date);
      return date2 - date1;
    });
    // const filteredArray = sortedDateArray.filter(
    //   (filter) => filter.status === "Active"
    // );
    setRequirements(sortedDateArray);
  };
  const fetchUsers = async () => {
    const user = await getService.handleUserList();
    const filteredArray = user.filter(
      (filter) => filter.user_type === "Seller"
    );
    setUsers(filteredArray);
  };

  useEffect(() => {
    fetchRequirement();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const inputRef = useRef();
  const params = useParams();
  const Id = params.id;

  const handleChange = ({ currentTarget: input }) => {
    const posts = { ...post };
    posts[input.name] = input.value;
    setPost(posts);
  };

  const handleAccountChange = ({ currentTarget: input }) => {
    const posts = { ...post };
    posts[input.name] = input.value;
    setPost(posts);
    const selectedOptionIndex = input.selectedIndex;
    const data = users.filter(
      (u) => users.indexOf(u) === selectedOptionIndex - 1
    );
    setAccount(...data);
  };

  const handleRequirementChange = ({ currentTarget: input }) => {
    const posts = { ...post };
    posts[input.name] = input.value;
    setPost(posts);
    const selectedOptionIndex = input.selectedIndex;
    const data = requirements.filter(
      (u) => requirements.indexOf(u) === selectedOptionIndex - 1
    );
    setUserRequirements(...data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...post,
        user_id: account.id,
        token: account.token,
        seller_name: account.name,
        location: account.address,
        post_requirement_id: userrequirements.post_requirement_id,
        buyer_name: userrequirements.name,
        credit_facility: creditFacility,
        delivery_timeline: userrequirements.delivery_date,
      };

      await postService.PostBid(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateForm = async () => {
    try {
      if (Id === "new") return;

      const collection = await getService.handleBids({
        user_id: "",
        token: "",
        post_requirement_id: "",
      });

      const data = await getService.getData(
        collection.post_requirement_bid_list,
        Id
      );
      setPost(data);
      setCreditFacility(data.credit_facility);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    PopulateForm();
  }, []);

  console.log("posts", post);
  console.log("account", account);
  console.log("userrequirements", userrequirements);

  const {
    seller_name,
    buyer_name,
    bid_price,
    bid_specification,
    credit_no_of_days,
  } = post;

  return (
    <>
      <div class="panel panel-bd lobidrag">
        <div class="panel-heading">
          <div class="panel-title">
            <h4>Add/Update Business </h4>
          </div>
        </div>
        <div class="panel-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <Select
                  label="Select Seller"
                  name="seller_name"
                  value={seller_name}
                  options={users}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="col-md-4">
                <Select
                  label="Select Requirement"
                  name="buyer_name"
                  value={buyer_name}
                  options={requirements}
                  onChange={handleRequirementChange}
                />
              </div>
              <div className="col-md-10 col-md-pull-2 mt-2">
                <Input
                  name="bid_price"
                  value={bid_price}
                  label="Bid Price"
                  onChange={handleChange}
                  type="number"
                />
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <label for="bid_specification" className="form-label">
                      Tell us more about your specification.
                    </label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="bid_specification"
                      value={bid_specification}
                      placeholder="Describe your bid specification here...."
                      onChange={handleChange}
                    />
                    <p className="td-words-linit">4000 character left.</p>
                  </div>

                  <div className="col-md-12 mt-2">
                    <div className="row">
                      <div className="col-md-8 mt-3">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <label htmlFor="creditFacility">
                            {" "}
                            Credit Facility
                          </label>
                          <div>
                            <Checkbox
                              label="Yes"
                              type="radio"
                              name="creditFacility"
                              value="Yes"
                              onChange={() => setCreditFacility("Yes")}
                            />
                            <Checkbox
                              label="No"
                              type="radio"
                              name="creditFacility"
                              value="No"
                              onChange={() => setCreditFacility("No")}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-4"
                        style={{
                          display: creditFacility === "Yes" ? "block" : "none",
                        }}
                      >
                        <Input
                          type="number"
                          label="No Of Days"
                          placeholder="No of Days"
                          name="credit_no_of_days"
                          value={credit_no_of_days}
                          onChange={handleChange}
                        />
                      </div>
                      <div class="col-md-1 offset-md-11">
                        <button type="submit" className="btn btn-success">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BidForm;
