import React, { useState, useEffect } from "react";
import Pagination from "../../../common/pagination";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { paginate } from "../../../utils/paginate";
import Delete from "./../../../common/delete";
import Table from "./../../../common/table";
import getService from "../../../services/getService";
import deleteService from "../../../services/deleteService";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import SearchBox from "./../../../common/searchBox";

const ServiceTable = () => {
  const [services, setService] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);

  const fetchService = async () => {
    const data = await getService.handleServiceList();
    setService(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (id) => {
    try {
      await deleteService.DeleteService({ id });
      setCurrentPage(1);
      fetchService();
    } catch (error) {
      toast.error(error);
    }
    setId(null);
  };

  const getPagedData = () => {
    let filtered = services;
    if (searchQuery)
      filtered = services.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else filtered = services;

    const service = paginate(filtered, currentPage, pageSize);
    return { totalCount: services.length, data: service };
  };

  const { length: count } = services;

  const { totalCount, data: service } = getPagedData();

  const columns = [
    {
      key: "",
      label: "S.No",
      content: (service) => <p>{services.indexOf(service) + 1}</p>,
    },
    { path: "title", label: "Title" },
    { path: "description", label: "Description" },
    {
      key: "Action",
      label: "Action",
      content: (service) => (
        <>
          <button
            class="btn btn-info btn-xs m-b-5 custom_btn"
            onClick={() => {
              navigate(`/admin/serviceform/${service.id}`);
            }}
          >
            <i class="pe-7s-note" aria-hidden="true"></i>
          </button>
          <Delete
            id={id}
            ItemId={service.id}
            setId={setId}
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchService();
  }, []);

  if (count === 0) return <p>There are no item in the database.</p>;

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>Service List </h4>
              </div>
              <CSVLink data={services} className="btn btn-outline-success">
                Download Report
              </CSVLink>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <SearchBox value={searchQuery} onChange={handleSearch} />
              </div>
            </div>
            <div class="panel-body">
              <Table columns={columns} data={service} />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="supplierList_info"
            role="status"
            aria-live="polite"
          >
            Showing {totalCount} entries in the Database
          </div>
        </div>
        <div class="col-sm-7">
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceTable;
