import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import { BankSchema } from "./../../../data/BankSchema";
import Input from "../../../common/input";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import upload from "../../../../components/assets/images/upload.jpg";

const BankDetails = ({
  account,
  setAccount,
  currentStep,
  myAccount,
  updateStep,
}) => {
  const [Img, setImg] = useState("");
  const [chequeUrl, setChequeUrl] = useState(account.cancel_cheque_pic);
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, BankSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: BankSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;
    try {
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "bank",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      console.log("returnAccount", returnAccount);
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateBank = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    PopulateBank();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const handleCheque = (item) => {
    chequeRef.current.click();
  };

  const setCheque = () => {
    setChequeUrl(fileUrl);
    setAccount({ ...account, cancel_cheque_pic: url });
    setUrl("");
  };

  const ImageUpload = (url) => {
    Img === "cancel_cheque_pic"
      ? setCheque()
      : console.log("account cancel_cheque_pic");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const { bank_name, account_holder_name, account_no, ifsc_code, signup_step } =
    account;

  const chequeRef = useRef();

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  return (
    <>
      <section className="bank-details">
        <div className="container">
          <h3>Bank Details</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Bank Name"
                  name="bank_name"
                  value={bank_name}
                  error={errors.bank_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Account Holder Name"
                  name="account_holder_name"
                  value={account_holder_name}
                  error={errors.account_holder_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Account No"
                  name="account_no"
                  value={account_no}
                  error={errors.account_no}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="IFSC Code"
                  name="ifsc_code"
                  value={ifsc_code}
                  error={errors.ifsc_code}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6" style={{ display: "none" }}>
                <Input
                  name="signup_step"
                  label="Signup_step"
                  value={signup_step}
                  onChange={handleChange}
                  disabled="true"
                />
              </div>
              {/* <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Cancel Cheque Pic</div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {chequeUrl && <img src={chequeUrl} alt="gstPic" />}
                        {!chequeUrl && <img src={upload} alt="gstPic" />}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={chequeRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="cancel_cheque_pic"
                          label="Upload Image"
                          type="file"
                          error={errors.cancel_cheque_pic}
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleCheque}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    type="button"
                    className="primaryButton"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    // disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>

                  <button
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    onClick={() => navigate("/membership")}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default BankDetails;
