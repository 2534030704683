import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import getService from "../../../services/getService";
import postService from "../../../services/postService";
import ChatPanel from "./ChatPanel";
import ChatList from "./ChatList";
import ChatWelcome from "./ChatWelcome";
import { animateScroll } from "react-scroll";

const AllQueries = ({ myAccount }) => {
  const [queries, setQueries] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [activeTab, setActiveTab] = useState("buyer");
  const chatRef = useRef(null);
  const [user, setUser] = useState([]);

  function scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "chat-container",
      duration: 500, // adjust duration as needed
      delay: 0,
      smooth: "easeInOutQuint", // use easing function for smooth scrolling
      isDynamic: true, // optimize for dynamic content
      useRAF: false, // use requestAnimationFrame for smoother animation
    });

    if (chatRef.current) {
      chatRef.current.lastChild.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  function handleClick() {
    scrollToBottom();
  }

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
    setMessages([]);
  };

  const fetchQueries = async () => {
    const data = await getService.handleAdminUserMsgList();
    setQueries(data);
    setIsLoaded(true);
  };

  useEffect(() => {
    const intervalId = setInterval(fetchQueries, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchData = async (user) => {
    const response = await postService.PostGetMessage({
      to: user.user_id,
      from: myAccount.admin_id,
      order_id: currentChat.order_id,
    });
    setMessages(response);
  };

  const handleSendMsg = async (msg) => {
    await postService.PostMessage({
      ticket_id: "",
      to: user.user_id,
      from: currentChat.admin_id,
      order_id: currentChat.order_id,
      message: msg,
    });
    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
    await fetchData(user);
    scrollToBottom();
  };

  useEffect(() => {
    if (currentChat !== undefined) {
      if (activeTab) {
        activeTab === "buyer"
          ? setUser({
              user_id: currentChat.buyer_id,
            })
          : setUser({
              user_id: currentChat.seller_id,
            });
      }
    }
  }, [currentChat, activeTab]);

  useEffect(() => {
    const intervalId = setInterval(() => fetchData(user), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentChat, myAccount, user]);

  return (
    <>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-bd lobidrag">
            <div class="panel-heading">
              <div class="panel-title">
                <h4>All Queries </h4>
              </div>
            </div>
            <div class="panel-body">
              <div className="row desktop-chat">
                <div className="col-md-5">
                  <div className="chat-list">
                    {/* <h6>There is no query till now.</h6> */}
                    <ChatList
                      orderList={queries}
                      currentUser={myAccount}
                      changeChat={handleChatChange}
                      messages={messages}
                      setMessages={setMessages}
                      handleSendMsg={handleSendMsg}
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="chat-panel">
                    {isLoaded && currentChat === undefined ? (
                      <ChatWelcome />
                    ) : (
                      <ChatPanel
                        chatRef={chatRef}
                        handleClick={handleClick}
                        messages={messages}
                        setMessages={setMessages}
                        currentChat={currentChat}
                        currentUser={myAccount}
                        setCurrentChat={setCurrentChat}
                        handleSendMsg={handleSendMsg}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row mobile-chat">
                <div className="col-md-5">
                  {currentChat == undefined && (
                    <div className="chat-list">
                      <ChatList
                        orderList={queries}
                        currentUser={myAccount}
                        changeChat={handleChatChange}
                        messages={messages}
                        setMessages={setMessages}
                        handleSendMsg={handleSendMsg}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-7">
                  <div className="chat-panel">
                    {isLoaded && currentChat !== undefined && (
                      <ChatPanel
                        chatRef={chatRef}
                        handleClick={handleClick}
                        messages={messages}
                        setMessages={setMessages}
                        currentChat={currentChat}
                        currentUser={myAccount}
                        setCurrentChat={setCurrentChat}
                        handleSendMsg={handleSendMsg}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllQueries;
