import { HiOutlineDocumentReport } from 'react-icons/hi';
import { GiPlantsAndAnimals } from 'react-icons/gi';
import { MdDeliveryDining, MdStarRate } from 'react-icons/md';
import { FaWallet } from 'react-icons/fa';


export const SidebarData = [
    {id: 0, name: "Orders", ReactIcons: <MdDeliveryDining className="icon"/> },
    {id: 1, name: "Ledger",   ReactIcons: <FaWallet className="icon" /> },
    // {id: 3, name: "Analytics",  ReactIcons: <HiOutlineDocumentReport className="icon" />},
    {id: 2, name: "Ratings",  ReactIcons: <MdStarRate className="icon" />},
    {id: 3, name: "Concern",  ReactIcons: <GiPlantsAndAnimals className="icon" />},
]