import React, { useState, useEffect, useRef } from "react";
import Joi from "joi-browser";
import Input from "../../../common/input";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import { ImagesSellerSchema } from "./../../../data/ImagesSellerSchema";
import upload from "../../../../components/assets/images/upload.jpg";

const ImagesSeller = ({
  account,
  setAccount,
  currentStep,
  updateStep,
  myAccount,
  userType,
}) => {
  const [Img, setImg] = useState("");
  const [companyAreaUrl, setCompanyAreaUrl] = useState(
    account.company_area_pic
  );
  const [outsideWithBoardUrl, setOutsideWithBoardUrl] = useState(
    account.outside_with_board_pic
  );
  const [insideOfPlantUrl, setInsideOfPlantUrl] = useState(
    account.inside_of_plant_pic
  );
  const [logoCompanyUrl, setLogoCompanyUrl] = useState(account.company_logo);
  const [visitingCardUrl, setVisitingCardUrl] = useState(
    account.visiting_card_pic
  );
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(account, ImagesSellerSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemas = { [name]: ImagesSellerSchema[name] };
    const { error } = Joi.validate(obj, schemas);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    console.error("errors", errors);

    if (errors) return;
    try {
      account.profile_complete = "Yes";
      let returnAccount = await auth.signup({
        ...account,
        signup_step: "images",
      });
      let data = { ...returnAccount, user_id: returnAccount.id };
      setAccount(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const PopulateImage = () => {
    try {
      const person_token = params.token;
      if (person_token === "") return;

      const data = myAccount;
      setAccount(mapToViewModel(data));
    } catch (error) {
      toast.error(error);
    }
  };

  const mapToViewModel = ({ id: user_id, ...rest }) => ({ user_id, ...rest });

  useEffect(() => {
    PopulateImage();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const error = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const accounts = { ...account };
    accounts[input.name] = input.value;
    setAccount(accounts);
    setErrors(error);
  };

  const onImageChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setImg(e.target.name);
    getBase64(file);
  };

  const handleCompanyArea = (item) => {
    companyAreaRef.current.click();
  };
  const handleOutsideWithBoard = (item) => {
    outsideWithBoardRef.current.click();
  };
  const handleInsideOfPlant = (item) => {
    insideOfPlantRef.current.click();
  };
  const handleLogoCompany = (item) => {
    logoCompanyRef.current.click();
  };
  const handleVisitingCard = (item) => {
    visitingCardRef.current.click();
  };

  const setCompanyAreaPic = () => {
    setCompanyAreaUrl(fileUrl);
    setAccount({ ...account, company_area_pic: url });
    setUrl("");
  };

  const setCompanyLogo = () => {
    setLogoCompanyUrl(fileUrl);
    setAccount({ ...account, company_logo: url });
    setUrl("");
  };

  const setOutSideWithBoardPic = () => {
    setOutsideWithBoardUrl(fileUrl);
    setAccount({ ...account, outside_with_board_pic: url });
    setUrl("");
  };

  const setInsideofPlantPic = () => {
    setInsideOfPlantUrl(fileUrl);
    setAccount({ ...account, inside_of_plant_pic: url });
    setUrl("");
  };

  const setVisitingCard = () => {
    setVisitingCardUrl(fileUrl);
    setAccount({ ...account, visiting_card_pic: url });
    setUrl("");
  };

  const ImageUpload = (url) => {
    Img === "company_area_pic"
      ? setCompanyAreaPic()
      : console.log("account company_area_pic");
    Img === "company_logo"
      ? setCompanyLogo()
      : console.log("account company_logo");
    Img === "outside_with_board_pic"
      ? setOutSideWithBoardPic()
      : console.log("account outside_with_board_pic");
    Img === "inside_of_plant_pic"
      ? setInsideofPlantPic()
      : console.log("account inside_of_plant_pic");
    Img === "visiting_card_pic"
      ? setVisitingCard()
      : console.log("account visiting_card_pic");
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setFileUrl(reader.result);
      let string = reader.result;
      let spilitFile = string.split(",");
      let resultedString = spilitFile[1];
      setUrl(resultedString);
    };
    reader.readAsDataURL(file);
  };

  const companyAreaRef = useRef();
  const outsideWithBoardRef = useRef();
  const insideOfPlantRef = useRef();
  const logoCompanyRef = useRef();
  const visitingCardRef = useRef();

  useEffect(() => {
    if (url) ImageUpload(url);
  }, [url]);

  const { signup_step } = account;

  return (
    <>
      <section className="images">
        <div className="container">
          <h3>Images</h3>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Company Area</div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {companyAreaUrl && (
                          <img src={companyAreaUrl} alt="company_area_pic" />
                        )}
                        {!companyAreaUrl && (
                          <img src={upload} alt="company_area_pic" />
                        )}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={companyAreaRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="company_area_pic"
                          label="Upload Image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleCompanyArea}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <p className="text-danger">{errors.company_area_pic}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Outside with Board</div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {outsideWithBoardUrl && (
                          <img
                            src={outsideWithBoardUrl}
                            alt="outside_with_board_pic"
                          />
                        )}
                        {!outsideWithBoardUrl && (
                          <img src={upload} alt="outside_with_board_pic" />
                        )}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={outsideWithBoardRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="outside_with_board_pic"
                          label="Upload Image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleOutsideWithBoard}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <p className="text-danger">
                        {errors.outside_with_board_pic}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Inside of Plant </div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {insideOfPlantUrl && (
                          <img
                            src={insideOfPlantUrl}
                            alt="inside_of_plant_pic"
                          />
                        )}
                        {!insideOfPlantUrl && (
                          <img src={upload} alt="inside_of_plant_pic" />
                        )}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={insideOfPlantRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="inside_of_plant_pic"
                          label="Upload Image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleInsideOfPlant}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <p className="text-danger">
                        {errors.inside_of_plant_pic}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Logo of company </div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {logoCompanyUrl && (
                          <img src={logoCompanyUrl} alt="company_logo" />
                        )}
                        {!logoCompanyUrl && (
                          <img src={upload} alt="company_logo" />
                        )}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={logoCompanyRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="company_logo"
                          label="Upload Image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleLogoCompany}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <p className="text-danger">{errors.company_logo}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6"> Visiting Card </div>
                  <div className="col-md-6">
                    <div className="img-upload">
                      <div className="img">
                        {visitingCardUrl && (
                          <img src={visitingCardUrl} alt="visiting_card_pic" />
                        )}
                        {!visitingCardUrl && (
                          <img src={upload} alt="visiting_card_pic" />
                        )}
                      </div>
                      <div className="upload-button">
                        <input
                          ref={visitingCardRef}
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          name="visiting_card_pic"
                          label="Upload Image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <button
                          type="button"
                          className="btn btn-attach"
                          onClick={handleVisitingCard}
                        >
                          <i className="fa fa-cloud-upload"></i> <br />
                          Upload Image
                        </button>
                      </div>
                    </div>
                    {errors && (
                      <p className="text-danger">{errors.visiting_card_pic}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ display: "none" }}>
                <Input
                  name="signup_step"
                  label="Signup_step"
                  value={signup_step}
                  onChange={handleChange}
                  disabled="true"
                />
              </div>
            </div>

            {!params.token && (
              <>
                <div className="checkout-button">
                  <button
                    type="button"
                    className="primaryButton"
                    style={{ display: currentStep === 0 ? "none" : "" }}
                    disabled={currentStep === 0}
                    onClick={() => updateStep(currentStep - 1)}
                  >
                    <i class="fa fa-hand-o-left" aria-hidden="true"></i>
                    Previous Step
                  </button>
                  <button
                    type="button"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "" : "none",
                    }}
                    disabled={currentStep === FormData.length - 1 || validate()}
                    onClick={() => updateStep(currentStep + 1)}
                  >
                    Next Step
                    <i class="fa fa-hand-o-right " aria-hidden="true"></i>
                  </button>

                  <button
                    type="submit"
                    className="primaryButton"
                    style={{
                      display:
                        currentStep === FormData.length - 1 ? "none" : "",
                    }}
                    onClick={
                      !validate() ? () => updateStep(currentStep + 1) : null
                    }
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {params.token && (
              <>
                <div className="checkout-button" style={{ bottom: "15px" }}>
                  <button className="primaryButton">Update</button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
};

export default ImagesSeller;
