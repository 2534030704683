import React from "react";

// Imported Images ==========>
import logo from "../../../assets/images/logo.png";

// imported Icons ===========>
import { IoMdSpeedometer } from "react-icons/io";
import { MdDeliveryDining } from "react-icons/md";
import { MdOutlineExplore } from "react-icons/md";
import { BsTrophy } from "react-icons/bs";
import { BsCreditCard2Front } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { SidebarData } from "./../../../data/SidebarData";
import { BuyerFormData } from "./../../../data/BuyerFormData";
import { MdDashboard } from "react-icons/md";

const Sidebar = ({ myAccount }) => {
  return (
    <div className="sideBar grid">
      <Link to="/">
        <div className="logoDiv flex">
          <img src={logo} alt="Image Name" />
          <h2>Onclq</h2>
        </div>
      </Link>

      <div className="menuDiv">
        <h3 className="divTitle">QUICK MENU</h3>
        <ul className="menuLists grid">
          <li className="listItem">
            <Link to="/buyerdashboard" className="menuLink flex">
              <MdDashboard className="icon" />
              <span className="smallText">Dashboard</span>
            </Link>
          </li>

          {SidebarData.map((item, index) => (
            <>
              <li className="listItem">
                <Link to={item.name} className="menuLink flex">
                  {item.ReactIcons}
                  <span className="smallText">{item.name}</span>
                </Link>
              </li>
            </>
          ))}
        </ul>
      </div>

      {/* <div className="settingsDiv">
        <h3 className="divTitle">SETTINGS</h3>
        <ul className="menuLists grid">
          {BuyerFormData.map((item, index) => (
            <>
              <li className="listItem">
                <Link
                  to={`${item.name}/${myAccount.token}`}
                  className="menuLink flex"
                >
                  {item.ReactIcons}
                  <span className="smallText">{item.name}</span>
                </Link>
              </li>
            </>
          ))}

          <li className="listItem">
            <Link
              to={`Buyer Plans/${myAccount.token}`}
              className="menuLink flex"
            >
              <BsCreditCard2Front className="icon" />
              <span className="smallText">Billing</span>
            </Link>
          </li>
        </ul>
      </div> */}

      <div className="sideBarCard">
        <BsQuestionCircle className="icon" />
        <div className="cardContent">
          <div className="circle1"></div>
          <div className="circle2"></div>

          <h3>Help Center</h3>
          <p>
            Having trouble in Onclq, please contact us from for more questions.
          </p>

          <button className="btn">Go to help center</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
