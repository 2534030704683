import React from "react";
import Top from "./Top Section/Top";
import Graphs from "./Graph Section/Graphs";

const SellerBody = ({ myAccount }) => {
  console.log("myAccount body order", myAccount);

  return (
    <div className="mainContent">
      <Top />
      <Graphs myAccount={myAccount} />
    </div>
  );
};

export default SellerBody;
